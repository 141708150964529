import axios from 'axios';
import Swal from 'sweetalert2';
import { api } from '../../domainConfig';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const domain = api.clientMasterDataDomain;
const domain2 = api.financeDomain;
const domain3 = api.masterDataDomain;
const domain4 = api.financeDomain
const domain5 = api.userManagementDomain
const domain6 = api.stockManagementDomain

const state = {
  status: false,
  clients: [],
  currentPage: 1,
  pageSize: 15,
  totalItems: 0,
  totalPages: 0,
  totalItemsEvents: 0,
  totalPagesEvents: 0,
  totalItemsTransaction: 0,
  totalPagesTransaction: 0,
  client: {},
  firstNames: [],
  measurmentsById: {},
  clientCounter: 0,
  client_compare: [],
  clientBirthdays: [],
  characteristicsByClientId: [],
  eventsReporting: [],
  bankAccount: [],
  bankDetails: [],
  accountDetails: [],
  transactionListing: [],
  transactionDetailsPDF: [],
  costRevenue: [],
  calculateCosg: [],
  percentageExpens: [],
  expensesMain: [],
  budgetType: [],
  budgetSubType: [],
  accountDropDown: [],
  budgetTypeSelection: [],
  budgetSubTypeBudgetId: [],
  calculateCostGoodsSold: [],
  totalPagesCalculateCogs: [],
  totalItemsCalculateCogs: [],
  incomeExpenseCost: [],
  incomeExpenseByTransactionId: [],
  detailsByTransactionId: [],
  employeeOnVacationCounter: [],
  reportGroupAccount: [],
  otherSuppliers: [],
  incomeExpenseFileUrl: [],
  cardBySupplier: [],
  cardBySupplierPDF: [],
  totalItemsSuppliers: 0,
  budgetByTranstactionType: [],
  orderDetailsByOrderId: [],
};

const getters = {
  getClients: (state) => {
    return state.clients;
  },
  getClient: (state) => {
    // console.log('getClient', state)
    return state.client
  },
  getExpenses: (state) => {
    return state.percentageExpens
  },
  getSuppliersItems: (state) => {
    return state.totalItemsSuppliers
  },
  getExpensesMain: (state) => {
    return state.expensesMain
  },
  getCharacteristicsByClientId: (state) => {
    return state.characteristicsByClientId
  },
  getEventsReporting: (state) => {
    return state.eventsReporting
  },
  getFirstNames: (state) => {
    return state.firstNames
  },
  getClientBirthdays: (state) => {
    return state.clientBirthdays
  },
  getCurrentPageForClients: (state) => {
    return state.currentPage;
  },
  getTotalItemsForClients: (state) => {
    return state.totalItems;
  },
  getTotalItemsForTransactions: (state) => {
    return state.totalItemsTransaction;
  },
  getTotalPagesForTransactions: (state) => {
    return state.totalPagesTransaction;
  },
  getTotalPagesForClients: (state) => {
    return state.totalPages;
  },
  getTotalItemsForEvents: (state) => {
    return state.totalItemsEvents;
  },
  getTotalPagesForEvents: (state) => {
    return state.totalPagesEvents;
  },
  getMeasermentsById: (state) => {
    return state.measurmentsById
  },
  getClientCounter: (state) => {
    return state.clientCounter
  },
  getClientCompare: (state) => {
    return state.client_compare
  },
  getBankAccount: (state) => {
    return state.bankAccount
  },
  getBankDetails: (state) => {
    return state.bankDetails
  },
  getAccountDetails: (state) => {
    return state.accountDetails
  },
  getTransactionDetails: (state) => {
    return state.transactionListing
  },
  getTransactionDetailsPDF: (state) => {
    return state.transactionDetailsPDF
  },
  getCostRevenue: (state) => {
    return state.costRevenue
  },
  getCalculationCosg: (state) => {
    return state.calculateCosg
  },
  getExpenseTypes: (state) => {
    return state.expenseType
  },
  getBudgetType: (state) => {
    return state.budgetType
  },
  getBudgetSubType: (state) => {
    return state.budgetSubType
  },
  getAccountDropDown: (state) => {
    return state.accountDropDown
  },
  getBudgetTypeSelection: (state) => {
    return state.budgetTypeSelection
  },
  getBudgetSubTypeByBudgetId: (state) => {
    return state.budgetSubTypeBudgetId
  },
  getEmployeesOnVacationCounter: (state) => {
    return state.employeeOnVacationCounter
  },
  getCalculateCostGoodsSold: (state) => {
    return state.calculateCostGoodsSold
  },
  getTotalItemsCalculateCogs: (state) => {
    return state.totalItemsCalculateCogs
  },
  getTotalPagesCalculateCogs: (state) => {
    return state.totalPagesCalculateCogs
  },
  getIncomeExpenseCost: (state) => {
    return state.incomeExpenseCost
  },
  getIncomeExpenseByTransactionId: (state) => {
    return state.incomeExpenseByTransactionId
  },
  getDetailsByTransactionId: (state) => {
    return state.detailsByTransactionId
  },
  getReportGroupAccount: (state) => {
    return state.reportGroupAccount
  },
  getOtherSuppliers: (state) => {
    return state.otherSuppliers
  },
  getIncomeExpenseFileUrl: (state) => {
    return state.incomeExpenseFileUrl
  },
  getCardBySupplier: (state) => {
    return state.cardBySupplier
  },
  getCardBySupplierPDF: (state) => {
    return state.cardBySupplierPDF
  },
  getBudgetByTranscation: (state) => {
    return state.budgetByTranstactionType
  },
  getOrderDetailsByOrderId: (state) => {
    return state.orderDetailsByOrderId
  },
};

const mutations = {
  resetMeasurmentsById(state) {
    state.measurmentsById = {
      thorax: '',
      aroundBust: '',
      aroundUnderBust: '',
      waist: '',
      aroundHips: '',
      aroundLeg: '',
      aroundKnee: '',
      ankle: '',
      breastReduction: '',
      interBust: '',
      frontBody: '',
      chestDistance: '',
      length1: '',
      length2: '',
      length3: '',
      length4: '',
      aroundSleeve: '',
      elbow: '',
      wrist: '',
      aroundNeck: '',
      backBody: '',
      shoulders: '',
      back: '',
      declineOfTheHips: '',
      sleeveLength: '',
    };
  },
  SET_STATUS(state, payload) {
    state.status = payload;
  },
  SET_EVENTS_REPORTING(state, latest) {
    state.eventsReporting = latest
  },
  SET_CLIENT(state, payload) {
    state.client = payload
  },
  SET_FIRST_NAMES(state, payload) {
    state.firstNames = payload
  },
  SET_TOTAL_SUPITEMS(state, payload) {
    state.totalItemsSuppliers = payload
  },
  SET_EXPENSES(state, latest) {
    state.percentageExpens = latest
  },
  SET_CLIENTS(state, latest) {
    state.clients = latest;
  },
  SET_CURRENTPAGE(state, payload) {
    state.currentPage = payload;
  },
  SET_EXPENSES_MAIN(state, latest) {
    state.expensesMain = latest
  },
  SET_CHARACTERISTICS_BY_CLIENTID(state, latest) {
    state.characteristicsByClientId = latest
  },
  SET_TOTALITEMS_CLIENTS(state, latest) {
    state.totalItems = latest;
  },
  SET_TOTALITEMS_TRANSACTION(state, latest) {
    state.totalItemsTransaction = latest;
  },
  SET_TOTALPAGES_TRANSACTION(state, latest) {
    state.totalPagesTransaction = latest;
  },
  SET_TOTALITEMS_EVENTS(state, latest) {
    state.totalItemsEvents = latest;
  },
  SET_TOTALPAGES_CLIENTS(state, latest) {
    state.totalPages = latest;
  },
  SET_TOTALPAGES_EVENTS(state, latest) {
    state.totalPagesEvents = latest;
  },
  SET_MEASERMENTS_BY_ID(state, latest) {
    state.measurmentsById = latest
  },
  SET_CLIENT_BIRTHDAYS(state, latest) {
    state.clientBirthdays = latest
  },
  SET_CLIENT_COUNTER(state, latest) {
    state.clientCounter = latest
  },
  SET_BANK_ACCOUNT(state, latest) {
    state.bankAccount = latest
  },
  SET_BANK_DETAILS(state, latest) {
    state.bankDetails = latest
  },
  SET_ACCOUNT_DETAILS(state, latest) {
    state.accountDetails = latest
  },
  SET_TRANSACTION_DETAILS(state, latest) {
    state.transactionListing = latest
  },
  SET_TRANSACTION_DETAILS_PDF(state, latest) {
    state.transactionDetailsPDF = latest
  },
  SET_COST_REVENUE(state, latest) {
    state.costRevenue = latest
  },
  SET_CALCULATE_COSG(state, latest) {
    state.calculateCosg = latest
  },
  SET_EXPENSE_TYPE(state, latest) {
    state.expenseType = latest
  },
  SET_BUDGET_TYPE(state, latest) {
    state.budgetType = latest
  },
  SET_BUDGET_SUB_TYPE(state, latest) {
    state.budgetSubType = latest
  },
  SET_ACCOUNT_DROP_DOWN(state, latest) {
    state.accountDropDown = latest
  },
  SET_BUDGET_TYPE_SELECTION(state, latest) {
    state.budgetTypeSelection = latest
  },
  SET_BUDGET_TYPES_BY_BUDGET_ID(state, latest) {
    state.budgetSubTypeBudgetId = latest
  },
  SET_EMPLOYEE_VACATION_COUNTER(state, latest) {
    state.employeeOnVacationCounter = latest
  },
  SET_CALCULATE_COST_GOODS_SOLD(state, latest) {
    state.calculateCostGoodsSold = latest
  },
  SET_TOTAL_ITEMS_COSG(state, latest) {
    state.totalItemsCalculateCogs = latest
  },
  SET_TOTAL_PAGES_COSG(state, latest) {
    state.totalPagesCalculateCogs = latest
  },
  SET_INCOME_EXPENSE_COST(state, latest) {
    state.incomeExpenseCost = latest
  },
  SET_INCOME_EXPENSE_BY_TRANSACTION_ID(state, latest) {
    state.incomeExpenseByTransactionId = latest
  },
  SET_DETAILS_BY_TRANSACTIONID(state, latest) {
    state.detailsByTransactionId = latest
  },
  SET_REPORT_GROUP_BY_ACCOUNT(state, latest) {
    state.reportGroupAccount = latest
  },
  SET_OTHER_SUPPLIERS(state, latest) {
    state.otherSuppliers = latest
  },
  SET_INCOME_EXPENSE_FILE_URL(state, latest) {
    state.incomeExpenseFileUrl = latest
  },
  SET_COST_CARD_BY_SUPPLIER(state, latest) {
    state.cardBySupplier = latest
  },
  SET_COST_CARD_BY_SUPPLIER_PDF(state, latest) {
    state.cardBySupplierPDF = latest
  },
  SET_BUDGET_BY_TRANSCATION(state, latest) {
    state.budgetByTranstactionType = latest
  },
  SET_ORDER_DETAILS_BY_ORDERID(state, latest) {
    state.orderDetailsByOrderId = latest
  },
  SET_CLIENT_COMPARE(state, latest) {
    state.client_compare = latest.map((client) => {
      return {
        ...client,
        discount: 0, // You can set an empty string or any default value here
      };
    });
  },
  // SET_CLIENT_COMPARE(state, latest) {
  //   state.client_compare = latest
  // },

};
const actions = {
  clearCustomMeasurements({ commit }) {
    commit('resetMeasurmentsById');
  },
  async addOtherSupplier({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain2}/add_supplier`, object, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Supplier added succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid supplier data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async editOtherSupplier({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain2}/edit_supplier`, object, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Supplier edited succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid supplier data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async editOtherSupplierStatus({ commit }, { supplierId, newStatus, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain2}/edit_supplier_status?supplierId=${supplierId}&newStatus=${newStatus}`, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Supplier status edited succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid supplier status data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async addClient({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.post(`${domain}/addClient`, object, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true);
      successCallback(response);
      Swal.fire({
        icon: 'success',
        title: 'Client added succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid client data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async resetClientId({ commit }) {
    commit('SET_CLIENT', {});
  },
  async addFinanceAccount({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain2}/add_bank_account`, object)
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Bank account added succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid bank account data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async addBankAccount({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain2}/add_bank`, object)
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Bank added succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid bank data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async editBankAccount({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain2}/edit_bank`, object)
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Bank edited succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid bank data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async addCostRevenue({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain2}/add_costRevenue`, object)
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Cost/revenue added succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid cost/revenue data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async addCostToOpenRevenue({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain2}/add_cost_to_open_revenue`, object)
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Cost added succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid cost data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async detailsByTransactionIds({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.post(`${domain2}/details_by_transactionIds`, object);
      commit('SET_DETAILS_BY_TRANSACTIONID', response.data);
      successCallback()
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async editFinanceAccount({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain2}/edit_bank_account`, object)
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Bank account edited succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid bank account data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async accountActivation({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain2}/edit_bank_account_activation`, object)
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Bank account status changed succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid bank account status data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async editBankAccountStatus({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain2}/edit_bank_account_status`, object)
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Bank account status changed succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid bank account status data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async editBudgetType({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain4}/edit_budget_type`, object)
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Budget type changed succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid budget type data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async editBudgetSubType({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain4}/edit_budget_sub_type`, object)
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Budget sub type changed succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid budget sub type data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async employeesVacationCounter({ commit }, {
    date,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain5}/employees_counter_on_vacation_by_date`, {
        params: {
          date,
        },
      });
      commit('SET_EMPLOYEE_VACATION_COUNTER', response.data);
      console.log('responmse', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async incomeExpenseFileUrls({ commit }, {
    incomeExpenseCostId,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/income_expense_cost_file_urls`, {
        params: {
          incomeExpenseCostId,
        },
      });
      commit('SET_INCOME_EXPENSE_FILE_URL', response.data);
      console.log('responmse', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async reportGroupByAccount({ commit }, {
    from, to,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/report_group_by_account`, {
        params: {
          from, to,
        },
      });
      commit('SET_REPORT_GROUP_BY_ACCOUNT', response.data);
      console.log('responmse', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async suppliersOther({ commit }, {
    isActive, pageNumber, pageSize, name,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/suppliers`, {
        params: {
          isActive, pageNumber, pageSize, name,
        },
      });
      commit('SET_OTHER_SUPPLIERS', response.data.items);
      commit('SET_TOTAL_SUPITEMS', response.data.totalItems);
      console.log('responmse', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async calculateCostCardBySupplier({ commit }, {
    from, to, supplierId,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain3}/calculate_cost_card_by_supplier`, {
        params: {
          from, to, supplierId,
        },
      });
      commit('SET_COST_CARD_BY_SUPPLIER', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async calculateCostCardBySupplierPDF({ commit }, {
    from, to, supplierId,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain3}/calculate_cost_card_by_supplier`, {
        params: {
          from, to, supplierId,
        },
      });
      commit('SET_COST_CARD_BY_SUPPLIER_PDF', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async calculateCostOfGoodsSold({ commit }, {
    from, to, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain3}/calculate_cost_sold_goods`, {
        params: {
          from, to, pageNumber, pageSize,
        },
      });
      commit('SET_CALCULATE_COST_GOODS_SOLD', response.data.items);
      commit('SET_TOTAL_PAGES_COSG', response.data.totalPages);
      commit('SET_TOTAL_ITEMS_COSG', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async incomeExpensesCost({ commit }, {
    compeletedPayment, from, to, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/income_expense_cost`, {
        params: {
          compeletedPayment, from, to, pageNumber, pageSize,
        },
      });
      commit('SET_INCOME_EXPENSE_COST', response.data.items);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async clearIncome({ commit }) {
    commit('SET_INCOME_EXPENSE_COST', []);
  },
  async incomeExpenseCostByTransactionId({ commit }, {
    transactionId,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/income_expense_cost`, {
        params: {
          transactionId,
        },
      });
      commit('SET_INCOME_EXPENSE_BY_TRANSACTION_ID', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async bankAccounts({ commit }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/bank`, {

      });
      commit('SET_BANK_ACCOUNT', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async accountsDropDown({ commit }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain4}/accounts_drop_down`, {

      });
      commit('SET_ACCOUNT_DROP_DOWN', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async budgetTypesSelection({ commit }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain4}/budget_types_selection`, {

      });
      commit('SET_BUDGET_TYPE_SELECTION', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async budgetSubTypeByBudgetId({ commit }, {
    budgetTypeId,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain4}/budget_sub_types_by_budget_id`, {
        params: {
          budgetTypeId,
        },
      });
      commit('SET_BUDGET_TYPES_BY_BUDGET_ID', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async budgetTypes({ commit }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain4}/budget_types`, {

      });
      commit('SET_BUDGET_TYPE', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async budgetSubTypes({ commit }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain4}/budget_sub_types`, {

      });
      commit('SET_BUDGET_SUB_TYPE', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async expenseTypes({ commit }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain4}/expense_types`, {
      });
      commit('SET_EXPENSE_TYPE', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async percentageExpenses({ commit }, { from, to }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain4}/report_percentage_expenses_main`, {
        params: {
          from,
          to,
        },
      });
      commit('SET_EXPENSES', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async showExpenesMain({ commit }, { expenseMainTypeID, from, to }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain4}/report_expenses_sub_by_mainid`, {
        params: {
          expenseMainTypeID,
          from,
          to,
        },
      });
      commit('SET_EXPENSES_MAIN', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async bankDetails({ commit }, {
    isActive,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/bank_details`, {
        params: {
          isActive,
        },
      });
      commit('SET_BANK_DETAILS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async showCostRevenue({ commit }, {
    from, to,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/report_cost_revenue`, {
        params: {
          from, to,
        },
      });
      commit('SET_COST_REVENUE', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async calculationOfCosg({ commit }, {
    from, to,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain3}/calculate_cmsh`, {
        params: {
          from, to,
        },
      });
      commit('SET_CALCULATE_COSG', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async addExpenseType({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain4}/add_expense_type`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Expense type added succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid expense data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async accountDetails({ commit }, {
    isActive, bankId,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/accounts_details`, {
        params: {
          isActive,
          bankId,
        },
      });
      commit('SET_ACCOUNT_DETAILS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async addCharacteristic({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/addCharacteristic`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Characteristics added succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid characteristics data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async addBudgetType({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain4}/add_budget_type`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Budget type added succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid budget type data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async addBudgetSubType({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain4}/add_budget_sub_type`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Budget sub-type added succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid budget sub-type data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async transactionByAccount({ commit }, {
    accountId, transaction, year, month, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/transactions_by_account`, {
        params: {
          accountId,
          transaction,
          year,
          month,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_TRANSACTION_DETAILS', response.data.items);
      commit('SET_TOTALPAGES_TRANSACTION', response.data.totalPages);
      commit('SET_TOTALITEMS_TRANSACTION', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async budgetadedTranscation({ commit }, {
    TransactionType,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain4}/budget_based_transaction_type`, {
        params: {
          TransactionType,
        },
      });
      commit('SET_BUDGET_BY_TRANSCATION', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async orderDetailsOrderId({ commit }, {
    orderId,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain6}/minimal_order_details_by_orderId`, {
        params: {
          orderId,
        },
      });
      commit('SET_ORDER_DETAILS_BY_ORDERID', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  resetDetailsOrderId({ commit }) {
    commit('SET_ORDER_DETAILS_BY_ORDERID', []);
  },
  resetCreatedOrdersByOrderNumberWindow({ commit }) {
    commit('SET_ORDER_DETAILS_BY_ORDERID', [])
  },
  async transactionPDF({ commit }, {
    accountId,
    transaction,
    year,
    month,
    pageNumber,
    pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain2}/transactions_by_account`, {
        params: {
          accountId,
          transaction,
          year,
          month,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_TRANSACTION_DETAILS_PDF', response.data);
      commit('SET_TOTALPAGES_TRANSACTION', response.data.totalPages);
      commit('SET_TOTALITEMS_TRANSACTION', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    // console.log('aa', response.data.items)
  },
  async addTransaction({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain2}/add_transaction`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Transaction added succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid transaction data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async cancelTransaction({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain2}/cancel_transaction`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Transaction canceled succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid transaction data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async addNewEvent({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/addEvent`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Event added succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
    // Handle error response
    // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid Event data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async getClientsPagination({ commit }, {
    clientId, isLead, pageNumber, pageSize, clientName,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/clientReportingPagination`, {
        params: {
          clientId, isLead, pageNumber, pageSize, clientName,
        },
      });
      commit('SET_CLIENTS', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_CLIENTS', response.data.totalItems);
      commit('SET_TOTALPAGES_CLIENTS', response.data.totalPages);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async characteristicsByClientId({ commit }, {
    clientId, clientName,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/characteristic_by_clientId`, {
        params: {
          clientId, clientName,
        },
      });
      commit('SET_CHARACTERISTICS_BY_CLIENTID', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async eventsReporting({ commit }, {
    clientId, clientName, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/eventsReportingPagination`, {
        params: {
          clientId, clientName, pageNumber, pageSize,
        },
      });
      commit('SET_EVENTS_REPORTING', response.data.items);
      commit('SET_CURRENTPAGE', response.data.currentPage);
      commit('SET_TOTALITEMS_EVENTS', response.data.totalItems);
      commit('SET_TOTALPAGES_EVENTS', response.data.totalPages);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  resetCLients({ commit }) {
    commit('SET_CLIENTS', [])
  },
  async loadClientCompare({ commit }, {
    aroundBust, waist, aroundHips, length1, clientName,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/clientCompare`, {
        params: {
          aroundBust, waist, aroundHips, length1, clientName,
        },
      });
      commit('SET_CLIENT_COMPARE', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  resetCLientsCompare({ commit }) {
    commit('SET_CLIENT_COMPARE', [])
  },
  // async loadClientCompare({ commit }, {
  //   aroundBust, waist, aroundHips, length1, clientName,
  // }) {
  //   commit('SET_IS_LOADING', false);
  //   try {
  //     const response = await axios.get(`${domain}/clientCompare`, {
  //       params: {
  //         aroundBust, waist, aroundHips, length1, clientName,
  //       },
  //     });
  //     commit('SET_CLIENT_COMPARE', response.data);
  //   } catch (error) {
  //     commit('SET_IS_LOADING', true)
  //   }
  //   commit('SET_IS_LOADING', true);
  // },
  // resetCLientsCompare({ commit }) {
  //   commit('SET_CLIENT_COMPARE', [])
  // },
  async clientBirthdays({ commit }, { startDate, endDate, clientName }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/upcomingClientsBirthday`, {
        params: {
          startDate,
          endDate,
          clientName,
        },
      });
      commit('SET_CLIENT_BIRTHDAYS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true)
  },
  async loadClientCounter({ commit }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/clientCounter`);
      commit('SET_CLIENT_COUNTER', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async getClientById({ commit }, { clientId, successCallback }) {
    commit('SET_IS_LOADING', false);
    const response = await axios.get(`${domain}/clientByClientId/${clientId}`);
    commit('SET_CLIENT', response.data);
    commit('SET_IS_LOADING', true);
    successCallback();
    return response.data;
  },
  async firstNames({ commit }, firstName) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/clientNamesByFirstNameLike/${firstName}`);
      commit('SET_FIRST_NAMES', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    // return response.data
  },
  resetFirstNames({ commit }) {
    commit('SET_FIRST_NAMES', []);
  },
  async editClient({ commit }, { clientId, object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/editClientById/${clientId}`, object);
      // console.log('objektiii', object)
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  async editCharacteristics({ commit }, { clientId, object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/edit_characteristic_By_clientId/${clientId}`, object);
      commit('SET_STATUS', true);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
    successCallback();
  },
  resetSearchClients({ commit }) {
    commit('firstNames', [])
  },
  async loadMeasermentsById({ commit }, clientId) {
    commit('SET_IS_LOADING', false);
    const response = await axios.get(`${domain}/bodyMeasurementsByClientId/${clientId}`);
    commit('SET_MEASERMENTS_BY_ID', response.data);
    commit('SET_IS_LOADING', true);
    // console.log('SET_MEASERMENTS_BY_ID', response);
    return response.data
  },
  // async loadMeasermentsById({ commit }) {
  //   const countryid = 'def4c486-f485-ed11-aba9-12c583908fd7';

  //   const response = await axios.get(`${domain}/bodyMeasurementsByClientId/${countryid}`);
  //   commit('SET_MEASERMENTS_BY_ID', response);
  //   console.log('SET_MEASERMENTS_BY_ID', response);
  // },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
