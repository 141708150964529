<template>
  <div>
    <div class="filtering">
      <div class="search-toggle">
        <p>{{ $t('SearchBy') }}</p>
        <div>
          <p
            :class="{ active2: toggleSearch === 'DeliveryNumber' }"
            @click="callToggle('DeliveryNumber')"
          >
            {{ $t('DeliveryNumber') }}
          </p>
          <p
            :class="{ active2: toggleSearch === 'InvoiceNumber' }"
            @click="callToggle('InvoiceNumber')"
          >
            {{ $t('InvoiceNumber') }}
          </p>
        </div>
      </div>
      <div
        class="searchfilter"
        style="display: flex;justify-content: space-between;"
      >
        <div
          v-if="toggleSearch === 'DeliveryNumber'"
          style="display: flex; align-items: center"
        >
          <div>
            <div class="black">
              <b-icon-search
                v-if="deliveryNumber == ''"
                class="search1"
                style="position: absolute; left: 5px; top: 12px"
              />
              <b-icon-x-circle
                v-else-if="deliveryNumber != ''"
                class="search1"
                style="position: absolute; right: 20px; top: 12px; cursor: pointer"
                @click="deliveryNumber = ''"
              />
              <input
                v-model="deliveryNumber"
                type="text"
                autocomplete="off"
                name="search-box"
                :placeholder="$t('DeliveryNumber')"
                :style="getFilterDeliveryNumber.length > 0? 'border-radius: 8px 8px 0px 0px' : ''"
                @keyup="inputChangedDelivery"
                @keydown.down="onArrowDownDelivery"
                @keydown.up="onArrowUpDelivery"
              >
            </div>
            <div
              v-if="getFilterDeliveryNumber.length > 0"
              ref="scrollContainer"
              class="dropdrop"
              :style="getFilterDeliveryNumber.length > 7 ? 'height: 210px' : 'height: auto'"
            >
              <div
                v-for="(result, index) in getFilterDeliveryNumber"
                :key="index"
                ref="options"
                style="cursor: pointer"
                class="search-item"
                :class="{ 'is-active': index === arrowCounterArticle }"
                @click="searchByDelivery(result);"
              >
                <p style="margin: 0">
                  {{ result.number }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="toggleSearch === 'InvoiceNumber'"
          style="display: flex; align-items: center"
        >
          <div>
            <div class="black">
              <b-icon-search
                v-if="searchOrder == ''"
                class="search1"
                style="position: absolute; left: 5px; top: 12px"
              />
              <b-icon-x-circle
                v-else-if="searchOrder != ''"
                class="search1"
                style="position: absolute; right: 20px; top: 12px; cursor: pointer"
                @click="searchOrder = ''"
              />
              <input
                v-model="searchOrder"
                type="text"
                autocomplete="off"
                name="search-box"
                :placeholder="$t('InvoiceNumber')"
                :style="getInvoiceNumberFilter.length > 0? 'border-radius: 8px 8px 0px 0px' : ''"
                @keyup="inputChangedArticles"
                @keydown.down="onArrowDownArticles"
                @keydown.up="onArrowUpArticles"
              >
            </div>
            <div
              v-if="getInvoiceNumberFilter.length > 0"
              ref="scrollContainer"
              class="dropdrop"
              :style="getInvoiceNumberFilter.length > 7 ? 'height: 210px' : 'height: auto'"
            >
              <div
                v-for="(result, index) in getInvoiceNumberFilter"
                :key="index"
                ref="options"
                style="cursor: pointer"
                class="search-item"
                :class="{ 'is-active': index === arrowCounterArticle }"
                @click="searchByOnArticles(result);"
              >
                <p style="margin: 0">
                  {{ result.number }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="display:flex;justify-content: space-between;margin-top: 10px">
      <button
        v-b-modal.buy-directly
        class="button"
        @click="callSuppliers"
      >
        {{ $t('Buy') }}
      </button>
    </div>
    <div style="display:flex;gap:10px;">
      <b-form-group
        :label="$t('StartDate')"
      >
        <date-picker
          v-model="startFilterCreated"
          format="YYYY-MM-DD"
          value-type="format"
          :placeholder="$t('SelectD')"
          style="width:100%"
        />
      </b-form-group>
      <b-form-group
        :label="$t('EndDate')"
      >
        <date-picker
          v-model="endFilterCreated"
          format="YYYY-MM-DD"
          value-type="format"
          :placeholder="$t('SelectD')"
          style="width:100%"
        />
      </b-form-group>
      <b-form-group
        :label="$t('SupplierName')"
        style="width:15%"
      >
        <vue-select
          v-model="supplierName"
          :options="getSupplierDropDown"
          label="supplierName"
          :reduce="(e) => e.supplierId"
          :placeholder="$t('Select')"
          style="width:100%"
        />
      </b-form-group>
    </div>
    <div>
      <div
        class="tt"
        style="padding-top: 5px"
      >
        <table
          class="team_table"
        >
          <thead>
            <tr>
              <th>
                {{ $t('deliveryNumber') }}
              </th>
              <th>
                {{ $t('SupplierName') }}
              </th>
              <th>
                {{ $t('receiverLocationName') }}
              </th>
              <th>
                {{ $t('InvoiceNumber') }}
              </th>
              <th>
                {{ $t('TotalPaid') }}
              </th>
              <th>
                {{ $t('TotalPrice') }}
              </th>
              <th>
                {{ $t('fileUrls') }}
              </th>
              <th>
                {{ $t('AccountName') }}
              </th>
              <th>
                {{ $t('invoiceDate') }}
              </th>
              <th>
                {{ $t('created') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for=" (orders ,index) in filteredOrders"
              :key="index"
              v-b-toggle.order-tracking-buy
              :title="$t('Tracking')"
              @click="callOrder(orders)"
            >
              <td>
                {{ orders.deliveryNumber }}
              </td>
              <td>
                {{ orders.supplierName }}
              </td>
              <td>
                {{ orders.receiverLocationName }}
              </td>
              <td>
                {{ orders.invoiceNumber }}
              </td>
              <td>
                {{ orders.totalPaid || 0 }}
              </td>
              <td>
                {{ orders.totalPrice || 0 }}
              </td>
              <td
                v-if="orders.fileUrls.length > 0"
                style="display: flex; flex-wrap: wrap; gap: 3px;"
              >
                <template v-for="(photo, photoIndex) in orders.fileUrls">
                  <img
                    v-if="photo.endsWith('.pdf') || photo.endsWith('.docx') || photo.endsWith('.xlsm') || photo.endsWith('.xlsx') || photo.endsWith('.plain')"
                    :key="'doc-' + photoIndex"
                    v-b-tooltip="$t('Document')"
                    style="width:30px;height:30px"
                    src="../../assets/images/documentLogo.svg"
                  >
                  <button
                    v-if="photo.endsWith('.pdf') || photo.endsWith('.docx') || photo.endsWith('.xlsm') || photo.endsWith('.xlsx') || photo.endsWith('.plain')"
                    :key="'btn-' + photoIndex"
                    v-b-tooltip="$t('Download')"
                    style="border: 1px solid #FF274F; width: 35px; height: 35px; background: white; border-radius: 5px; margin-right: 10px;"
                    @click.stop="downloadFile(photo)"
                  >
                    <b-icon-download style="color: #FF274F;" />
                  </button>

                  <img
                    v-if="!photo.endsWith('.pdf') && !photo.endsWith('.docx') && !photo.endsWith('.xlsm') && !photo.endsWith('.xlsx') && !photo.endsWith('.plain')"
                    :key="'img-' + photoIndex"
                    v-b-tooltip="$t('ClickToViewPhoto')"
                    style="width:30px;height:30px; border-radius: 3px;"
                    :src="photo"
                    alt="No image"
                  >
                  <button
                    v-if="!photo.endsWith('.pdf') && !photo.endsWith('.docx') && !photo.endsWith('.xlsm') && !photo.endsWith('.xlsx') && !photo.endsWith('.plain')"
                    :key="'img-btn-' + photoIndex"
                    v-b-tooltip="$t('Download')"
                    style="border: 1px solid #FF274F; width: 35px; height: 35px; background: white; border-radius: 5px; margin-right: 10px;"
                    @click.stop="downloadFile(photo)"
                  >
                    <b-icon-download style="color: #FF274F;" />
                  </button>
                </template>
              </td>
              <td v-else>
                <img
                  v-b-tooltip="$t('NoImageOrDocument')"
                  style="width:30px;height:30px"
                  src="../../assets/images/noImageAvailable.svg"
                >
              </td>
              <td>
                {{ orders.accountName }}
              </td>
              <td>
                {{ dateFormat(orders.invoiceDate) }}
              </td>
              <td>
                {{ dateFormat(orders.created) }}
              </td>
            </tr>
          </tbody>
        </table>

        <div>
          <b-pagination
            v-if="getTotalItemsForMaterialOrders > 15"
            v-model="page"
            :total-rows="getTotalItemsForMaterialOrders"
            :per-page="pageSize"
            first-number
            last-number
            align="fill"
            size="md"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
          >
            <template #prev-text>
              <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
            </template>
            <template #next-text>
              <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
            </template>
          </b-pagination>
        </div>
      </div>
    </div>
    <div
      v-if="isSidebarOpen"
      class="backdrop"
    />
    <TrackingByOrder
      @onCancelToggle="onCancelToggle"
    />
    <BuyDirectModal
      @receiveArticles="receiveAllArticles"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
// import jsPDF from 'jspdf';
import { client } from '@/domainConfig'
import BuyDirectModal from './BuyDirectModal.vue';
import TrackingByOrder from './TrackingByOrder.vue'
import 'vue2-datepicker/index.css';
import 'jspdf-autotable';


export default {
  components: {
    BuyDirectModal,
    DatePicker,
    TrackingByOrder,
  },
  props: [],
  data() {
    return {
      isSidebarOpen: false,
      toggleSearch: 'DeliveryNumber',
      client: client.clientName,
      page: 1,
      pageSize: 15,
      searchOrder: '',
      deliveryNumber: '',
      arrowCounterArticle: 0,
      startFilterCreated: null,
      endFilterCreated: null,
      supplierName: null,
      deliveryNumberId: null,
    }
  },
  computed: {
    ...mapGetters(['getLanguage', 'getSupplierDropDown', 'getInvoiceNumberFilter', 'getReportingDelivers', 'getReportingDeliversItems', 'getFilterDeliveryNumber', 'getInvoiceNumberFilterFilter', 'getInvoiceNumberFilter', 'getOrderItems', 'getImagesVariantOrder', 'getOrderedMaterials', 'getTotalItemsForMaterialOrders']),
    filteredOrders() {
      return this.getReportingDelivers
    },
  },
  watch: {
    page(value) {
      this.loadReportingDelivers({
        deliveryManagementId: this.deliveryNumberId,
        invoiceNumber: this.searchOrder,
        startFilterCreated: this.startFilterCreated,
        endFilterCreated: this.endFilterCreated,
        supplierId: this.supplierName,
        pageNumber: value,
        pageSize: this.pageSize,
      })
    },
    startFilterCreated(value) {
      this.loadReportingDelivers({
        deliveryManagementId: this.deliveryNumberId,
        invoiceNumber: this.searchOrder,
        startFilterCreated: value,
        endFilterCreated: this.endFilterCreated,
        supplierId: this.supplierName,
        pageNumber: this.page,
        pageSize: this.pageSize,
      })
    },
    endFilterCreated(value) {
      this.loadReportingDelivers({
        deliveryManagementId: this.deliveryNumberId,
        invoiceNumber: this.searchOrder,
        startFilterCreated: this.startFilterCreated,
        endFilterCreated: value,
        supplierId: this.supplierName,
        pageNumber: this.page,
        pageSize: this.pageSize,
      })
    },
    supplierName(value) {
      this.loadReportingDelivers({
        deliveryManagementId: this.deliveryNumberId,
        invoiceNumber: this.searchOrder,
        startFilterCreated: this.startFilterCreated,
        endFilterCreated: this.endFilterCreated,
        supplierId: value,
        pageNumber: this.page,
        pageSize: this.pageSize,
      })
    },
    searchOrder(value) {
      if (this.skipWatch) {
        this.skipWatch = false;
        return;
      }

      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value === '') {
          this.loadReportingDelivers({
            startFilterCreated: this.startFilterCreated,
            endFilterCreated: this.endFilterCreated,
            supplierId: this.supplierName,
            pageNumber: this.page,
            pageSize: this.pageSize,
          })
          this.removeLists();
        } else if (!this.enterPressed) {
          this.searchByInvoiceNumberFilter({ invoiceNumberLike: value });
        }
      }, 500);
    },
    deliveryNumber(value) {
      if (this.skipWatch) {
        this.skipWatch = false;
        return;
      }

      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value === '') {
          this.loadReportingDelivers({
            startFilterCreated: this.startFilterCreated,
            endFilterCreated: this.endFilterCreated,
            supplierId: this.supplierName,
            pageNumber: this.page,
            pageSize: this.pageSize,
          })
          this.removeLists();
        } else if (!this.enterPressed) {
          this.searchByDeliveryNumber({ deliveryNumberLike: value });
        }
      }, 500);
    },
  },
  mounted() {
    this.loadReportingDelivers({
      startFilterCreated: this.startFilterCreated,
      endFilterCreated: this.endFilterCreated,
      supplierId: this.supplierName,
      pageNumber: this.page,
      pageSize: this.pageSize,
    })
    this.suppliersDropDown({
      isActive: true,
    })
  },
  methods: {
    ...mapActions(['suppliersDropDown', 'loadTrackingByOrderArticle', 'searchByInvoiceNumberFilter', 'resetDeliveryNumber', 'resetInvoiceNumberLike', 'searchByDeliveryNumber', 'loadInvoiceNumberLike', 'loadReportingDelivers', 'receiveArticlesAndPurchase', 'movingLocations', 'accountDetails', 'accountDetails']),
    callOrder(value) {
      this.isSidebarOpen = true
      this.loadTrackingByOrderArticle({
        deliveryManagementId: value.deliveryManagementId,
      })
    },
    onCancelToggle() {
      this.isSidebarOpen = false
    },
    callToggle(value) {
      this.toggleSearch = value
      this.searchOrder = ''
      this.deliveryNumber = ''
      this.deliveryNumberId = null
    },
    downloadFile(fileUrl) {
    // This method will handle both download and preview of the file
      const fileExtension = fileUrl.split('.').pop();

      // If the file is already a PDF, no need to generate it
      if (fileExtension === 'pdf') {
        this.generateAndPreviewPdf(fileUrl);
      } else {
      // If the file is not a PDF, handle the conversion if needed
      // You can add specific logic for .docx, .xlsx, etc. if required
        console.log('Downloading non-PDF file:', fileUrl);

        // Handle the logic to download or preview non-PDF files here (optional)
        this.downloadNonPdfFile(fileUrl); // Custom method to handle this
      }
    },

    generateAndPreviewPdf(pdfUrl) {
    // Generate a Blob from the URL
      fetch(pdfUrl)
        .then((response) => { return response.blob() })
        .then((pdfBlob) => {
          const blobUrl = URL.createObjectURL(pdfBlob);
          // Save the Blob URL to sessionStorage
          sessionStorage.setItem('pdfBlobUrl', blobUrl);
          sessionStorage.setItem('pathName', this.$route.path);
          // Redirect to the PDF preview page
          this.$router.push({ name: 'Pdf Preview' }); // Ensure you have a route named 'PdfPreview'
        })
        .catch((error) => {
          console.error('Error generating PDF:', error);
        });
    },

    // getOrderedMaterialPagination

    downloadNonPdfFile(fileUrl) {
    // For non-PDF files, you can either download or display them differently
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = true; // Trigger download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async searchByOnArticles(value) {
      this.searchOrder = value.number;
      await this.loadReportingDelivers({
        invoiceNumber: value.number,
        startFilterCreated: this.startFilterCreated,
        endFilterCreated: this.endFilterCreated,
        supplierId: this.supplierName,
        pageNumber: this.page,
        pageSize: this.pageSize,
      })
    },
    async searchByDelivery(value) {
      this.deliveryNumber = value.number;
      this.deliveryNumberId = value.deliveryManagementId;
      await this.loadReportingDelivers({
        deliveryManagementId: value.deliveryManagementId,
        startFilterCreated: this.startFilterCreated,
        endFilterCreated: this.endFilterCreated,
        supplierId: this.supplierName,
        pageNumber: this.page,
        pageSize: this.pageSize,
      })
    },
    removeLists() {
      this.resetInvoiceNumberLike()
      this.resetDeliveryNumber()
    },
    inputChangedArticles(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOnArticles(this.getInvoiceNumberFilter[this.arrowCounterArticle])
        this.removeLists()
        this.arrowCounterArticle = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    onArrowDownArticles(ev) {
      ev.preventDefault()
      if (this.arrowCounterArticle < this.getInvoiceNumberFilter.length - 1) {
        this.arrowCounterArticle += 1;
        this.fixScrolling();
      }
    },
    onArrowUpArticles(ev) {
      ev.preventDefault()
      if (this.arrowCounterArticle > 0) {
        this.arrowCounterArticle -= 1;
        this.fixScrolling()
      }
    },
    inputChangedDelivery(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByDelivery(this.getFilterDeliveryNumber[this.arrowCounterArticle])
        this.removeLists()
        this.arrowCounterArticle = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    onArrowDownDelivery(ev) {
      ev.preventDefault()
      if (this.arrowCounterArticle < this.getFilterDeliveryNumber.length - 1) {
        this.arrowCounterArticle += 1;
        this.fixScrolling();
      }
    },
    onArrowUpDelivery(ev) {
      ev.preventDefault()
      if (this.arrowCounterArticle > 0) {
        this.arrowCounterArticle -= 1;
        this.fixScrolling()
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounterArticle].clientHeight;
      console.log(liH)
      if (this.arrowCounterArticle <= 16) {
        this.$refs.scrollContainer.scrollTop = 34 * this.arrowCounterArticle;
      } else {
        this.$refs.scrollContainer.scrollTop = 58 * this.arrowCounterArticle;
      }
    },
    dateFormat(value) {
      if (value) {
        return moment(value).format('YYYY-MM-DD');
      }
      return 'No Date'
    },
    receiveAllArticles(value) {
      this.receiveArticlesAndPurchase({
        object: value,
        successCallback: () => {
          this.loadReportingDelivers({
            deliveryManagementId: this.deliveryNumberId,
            invoiceNumber: this.searchOrder,
            startFilterCreated: this.startFilterCreated,
            endFilterCreated: this.endFilterCreated,
            supplierId: this.supplierName,
            pageNumber: this.page,
            pageSize: this.pageSize,
          })
        },
      })
    },
    callSuppliers() {
      this.suppliersDropDown({
        isActive: true,
      })
      this.accountDetails({
        isActive: true,
      })
      this.movingLocations()
    },
  },
}
</script>

  <style scoped lang="scss">

  search-item {
    padding: 5px;
    max-width: 500px;
    width: 100%;
    font-weight: 400;
    color: #82868c;
    font-size: 1rem;
    border-bottom: 1px solid #e8e8e8;
  }

  .search-item:last-child {
    border-bottom: none;
  }

  .search-item:hover {
    background: #82868c;
    color: white;
  }
  .form-control{
    width: 193px;
    border: none;
  }
  .search-item hr {
    color: lightgray;
    border-top: none;
    margin: -1.3rem 0 0.5rem 0;
  }

  .active2 {
    font-weight: bold;
    color: $base-color;
  }
  .team_table td {
    padding: 11px 9px !important;
  }

  .buttonOrdered {
    border: none;
  }

  .buttonOrdered:hover {
    background: white;
    color: #FF274F;
  }


  .team_table thead {
    border-bottom: 1px solid #dcdfe6;
    color: #606266;
    text-align: left;
  }

  .team_table tr {
    border-bottom: 1px solid #dcdfe6;
    color: #606266;
    cursor: pointer;
  }
  .hover-row:hover {
    background-color: rgba(255, 39, 79, 0.1);
  }
  .mats-listing__wrapper {
  width: 600px !important;

  .tt {
    .vgt-table {
      th.sortable button {
        display: none !important;
      }
    }
  }

  .mats-listing {
    margin-top: 0;
  }
  }

  .button {
  margin-bottom: 15px !important;
  width: 195px;
  }

  // .team_table .tr{
  //   border-radius: 10px 10px 10px 10px !important;
  // }
  tr:last-child {
  border-bottom: none !important;
  }

  @media screen and (max-width: 1723px) {
  .mats-listing__wrapper {
    width: 400px !important;

    div {
      button {
        width: auto;
        height: 36px;
      }

      input {
        width: 160px;
      }

    }

    .table__search-input {
      padding-left: 0px;
      margin-left: 0px !important;
    }
  }

  }

  @media screen and (max-width: 1400px) {
  .mats-listing__wrapper {
    width: 100% !important;
  }
  }


  .dropdrop::-webkit-scrollbar {
  display: none;

  }

  .dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: none;
  }

  input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
  }

  .black {
  position: relative;
  }



  .search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
  }

  .search-item:last-child {
  border-bottom: none;
  }

  .search-item:hover {
  background: #82868c;
  color: white;
  }

  .search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
  }
  .bigger-icon {
  font-size: 24px;
  align-self: center;
  }
  .photo{
    margin-left: 5px;
    height: 50px;
    border-radius: 8px;
  }
  .year {
  display: flex;
  justify-content: space-around;
  align-items: center;
  }
  .arrowButton {
    width: 36px !important;
    height: 36px;
    display: flex;
    margin-right: 5px;
    border-radius: 6px !important;
    justify-content: center;
    border: #c3d0dd 1px solid;
    background-color: #e9ecef;
    align-items: center;
  }
  .hovered {
  /* Additional styles for the hovered photo */
  transform: scale(7.2);
  border-radius: 0px;
  }
  .second-photo{
  margin-left: 10px;
  }
  .third-photo{
  margin-left: 10px;
  }

  .is-active {
  background-color: #dedede;
  }
  </style>
