<template>
  <div>
    <div style="display: flex; gap: 10px">
      <vue-select
        v-model="selectedOrderState"
        :options="stateOptionsFiltered"
        :clearable="false"
        :reduce="(e) => e.value"
        style="width: 35%;"
        label="text"
        :placeholder="$t('SelectState')"
        @input="changeState"
      />
      <vue-select
        id="input-5"
        v-model="year"
        required
        :options="yearsArrays"
        :placeholder="$t('Select')"
        aria-describedby="input-1-live-feedback"
        style="width: 15%"
        :clearable="false"
        @input="changeYear"
      />
      <div style="cursor: pointer;">
        <img
          v-b-tooltip.hover
          :title="$t('ClickForTransit')"
          src="../../assets/images/inMovement.png"
          style="width: 30px; margin-bottom: 10px;"
          @click="goToTab2"
        >
        <span class="cart-counter">{{ getItemsMovementCount ? getItemsMovementCount : 0 }}</span>
      </div>

    </div>
    <div style="width: 100%;padding-top: 16px; margin-top: 0px;">
      <table
        class="team_table"
      >
        <thead>
          <tr>
            <th>
              {{ $t('OrderID') }}
            </th>
            <th>
              {{ $t('OrderType') }}
            </th>
            <th>
              {{ $t('FullName') }}
            </th>
            <th>
              {{ $t('ShippingCountry') }}
            </th>
            <th>
              {{ $t('BillingCountry') }}
            </th>
            <th>
              {{ $t('Created') }}
            </th>
          </tr>
        </thead>
        <tbody v-if="getAllOrdersMoving.length > 0">
          <tr
            v-for="order in getAllOrdersMoving"
            :key="order.id"
            class="hover-row"
            :style="classNameCh === order.orderNumber ? 'background-color: rgba(255, 39, 79, 0.1)' : ''"
            style="cursor: pointer"
            @click="matProps(order)"
          >
            <td>
              {{ order.orderNumber }}
            </td>
            <td v-if="order.orderType == 'InStore'">
              {{ $t("InStore") }}
            </td>
            <td v-else-if="order.orderType == 'WholeSale'">
              {{ $t("WholeSale") }}
            </td>
            <td v-else>
              {{ order.orderType }}
            </td>
            <td>
              {{ order.customerName }}
            </td>
            <td>
              {{ $t(order.shippingAddressCountry) }}
            </td>
            <td>
              {{ $t(order.billingAddressCountry) }}
            </td>
            <td>
              {{ convertUTCDateToLoacalDate(order.created) }}
            </td>
          </tr></tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  components: {},
  data() {
    return {
      pageNumber: 1,
      pageSize: 15,
      selectedOrderState: 'Created',
      year: moment().year(),
      classNameCh: null,
    }
  },
  computed: {
    ...mapGetters(['getAllOrdersMoving', 'getOrderItemStates', 'getItemsMovementCount']),
    stateOptionsFiltered() {
      const optionsState = [
        'Created',
        'Assigned',
        'Confirmed',
        'ReadyToDeliver',
        'Delivered',
      ]
      const options = optionsState.map((state) => {
        return {
          value: state,
          text: this.$t(state),
        }
      });

      const filteredOptions = [...options];

      return filteredOptions;
    },
    yearsArrays() {
      const result = [];
      const baseYear = this.year || moment().year();

      for (let i = -3; i < 4; i++) {
        result.push(baseYear + i);
      }

      return result;
    },
  },
  mounted() {
    this.orderItemStates()
    this.itemsCount({
      state: 'Pending',
    })
    this.movingAllOrders({
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      orderState: 'Created',
      orderNumber: null,
      clientId: null,
      salesType: 'Material',
      year: this.year ? this.year : moment().year(),
    })
  },
  methods: {
    ...mapActions(['movingAllOrders', 'itemsCount', 'readOrderItems', 'orderItemStates']),
    convertUTCDateToLoacalDate(date) {
      const local = moment.utc(date).local().format('DD-MMM-YYYY h:mm A')
      return local
    },
    goToTab2() {
      this.$emit('tab2')
    },
    async changeState() {
      try {
        await this.movingAllOrders({
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          orderState: this.selectedOrderState,
          orderNumber: null,
          clientId: null,
          salesType: 'Material',
          year: this.year ? this.year : moment().year(),
        })
          .then((res) => {
            console.log(res);
          })
      } catch (e) {
        console.log(e);
      }
    },
    async changeYear() {
      try {
        await this.movingAllOrders({
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          orderState: this.selectedOrderState,
          orderNumber: null,
          clientId: null,
          salesType: 'Material',
          year: this.year,
        })
          .then((res) => {
            console.log(res);
          })
      } catch (e) {
        console.log(e);
      }
    },
    matProps(ordNId) {
      this.$emit('sendON', ordNId.orderNumber)
      this.classNameCh = ordNId.orderNumber
      this.$emit('sendOrderState', ordNId.orderState)
      this.readOrderItems({
        orderId: ordNId.orderId,
        orderItemState: this.selectedOrderState == null ? null : this.selectedOrderState,
      })
    },
  },
}
</script>

<style scoped>
.team_table td {
  padding: 16px 9px !important;
}
.hover-row:hover {
  background-color: rgba(255, 39, 79, 0.1);
}
.cart-counter {
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 0px 7px;
    font-size: 13px;
    position: relative;
    left: -29.5%;
    top: -39%;
}
</style>
