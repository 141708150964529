<template>
  <div>
    <b-modal
      id="price-paid-modal"
      ref="modal"
      header-class="headerModal"
      centered
      :title="`${$t('PaymentDetailsFor')} ${order}`"
      hide-footer
      size="xl"
      @close="onCancel"
    >
      <template>
        <b-form-group
          :label="$t('ArticlePrices')"
          style="margin: 10px 0px;"
        >
          <b-form-group
            :label="`${$t('Price')}:`"
            style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin-right: 10px; width: 18%; border: 1px solid #D0D5DD"
          >
            <div style="display: flex; justify-content: flex-start; align-items: center;">
              <b-input
                v-model="orderPriceAndPaidOverview.price"
                disabled
                type="number"
              />
            </div>
          </b-form-group>
          <b-form-group
            v-if="orderPriceAndPaidOverview.discount>0"
            :label="`${$t('Discount')}:`"
            style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin-right: 10px; width: 18%; border: 1px solid #D0D5DD"
          >
            <div style="display: flex; justify-content: flex-start; align-items: center;">
              <b-input
                v-model.number="orderPriceAndPaidOverview.discount"
                disabled
                type="number"
              />
            </div>
          </b-form-group>
          <b-form-group
            v-if="orderPriceAndPaidOverview.discount>0"
            :label="`${$t('PriceDiscount')}:`"
            style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin-right: 10px; width: 18%; border: 1px solid #D0D5DD"
          >
            <div style="display: flex; justify-content: flex-start; align-items: center;">
              <b-input
                v-model.number="orderPriceAndPaidOverview.priceWithDiscount"
                disabled
                type="number"
              />
            </div>
          </b-form-group>
          <b-form-group
            :label="`${$t('PaidPrice')}:`"
            style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin-right: 10px; width: 18%; border: 1px solid #D0D5DD"
          >
            <div style="display: flex; justify-content: flex-start; align-items: center;">
              <b-input
                v-model.number="orderPriceAndPaidOverview.paidAmount"
                disabled
                type="number"
              />
            </div>
          </b-form-group>
          <b-form-group
            :label="`${$t('RemainingAmount')}:`"
            style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin-right: 10px; width: 18%; border: 1px solid #D0D5DD"
          >
            <div style="display: flex; justify-content: flex-start; align-items: center;">
              <b-input
                v-model="orderPriceAndPaidOverview.remaining"
                disabled
                type="number"
              />
            </div>
            <p v-if="(orderPriceAndPaidOverview.priceWithDiscount || orderPriceAndPaidOverview.price) < orderPriceAndPaidOverview.paidAmount" style="color:red;margin-bottom:0px;">
              {{ -(orderPriceAndPaidOverview.remaining) }} {{ $t('PaidMore') }}
            </p>
          </b-form-group>
          <button
            v-b-modal.edit-order-price
            class="button"
            style="margin-top:10px"
            @click="sentPriceOrder"
          >
            {{ $t('EditPrice') }}
          </button>
        </b-form-group>
        <b-form-group
          :label="$t('ShippingCost')"
          style="margin-bottom: 10px"
        >
          <b-form-group
            :label="`${$t('ShippingCost')}:`"
            style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin-right: 10px;margin-top:10px; width: 18%; border: 1px solid #D0D5DD"
          >
            <div style="display: flex; justify-content: flex-start; align-items: center;">
              <b-input
                v-model="getShipmentDetailsByOrder.shipmentPrice"
                disabled
                type="number"
              />
            </div>
          </b-form-group>
          <b-form-group
            :label="`${$t('ShippingCostPaid')}:`"
            style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin-right: 10px;margin-top:10px;  border: 1px solid #D0D5DD"
          >
            <div style="display: flex; justify-content: flex-start; align-items: center;">
              <b-input
                v-model="getShipmentDetailsByOrder.paidAmount"
                disabled
                type="number"
              />
            </div>
          </b-form-group>
          <b-form-group
            :label="`${$t('ShippingCostRemaining')}:`"
            style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin-right: 10px;margin-top:10px;  border: 1px solid #D0D5DD"
          >
            <div style="display: flex; justify-content: flex-start; align-items: center;">
              <b-input
                v-model="shippingCostRemaining"
                disabled
                type="number"
              />
            </div>
          </b-form-group>
          <button
            v-b-modal.edit-shipment-price-total
            class="button"
            style="margin-top:10px;width:20%"
            @click="sentPricePerShipment"
          >
            {{ $t('EditShipment') }}
          </button>
        </b-form-group>
      </template>
      <template>
        <b-card-text>
          <label style="font-weight: 600; padding: 10px 0 10px 0px">{{ $t('ArticlesPayment') }}</label>
          <div style="display: flex; flex-wrap: wrap;gap: 7px">
            <div
              v-for="item in getOrderItemPaymentByOrderNumber?.paymentTracks"
              :key="item.id"
              style="width: calc(33.33% - 10px); border: 1px solid lightgray; padding: 20px; background: #FFFFFF 0% 0% no-repeat padding-box; box-shadow: none; border-radius: 6px; opacity: 1; margin: 0 5px 0px 0;"
            >
              <div style="display: flex; justify-content: space-between;align-items:center;gap:5px;">
                <button style="width: 100%; background: #FF274F; border: none; color: white; border-radius: 5px; border: none;">
                  {{ formatDate(item.created) }}
                </button>
                <b-icon-pencil
                  v-b-modal.edit-payment
                  style="cursor: pointer;"
                  @click="editPayments(item)"
                />
              </div>
              <hr style="color: rgb(146, 146, 146);">
              <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
                <b style="color: #262E6C;">{{ $t("ExecutorName") }}</b>
                <p style="margin-bottom: 2px;">
                  {{ item.fullName }}
                </p>
              </div>
              <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
                <b style="color: #262E6C;">{{ $t("DateOfPayment") }}</b>
                <p style="margin-bottom: 2px;">
                  {{ formatDate1(item.paymentDate) }}
                </p>
              </div>
              <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
                <b style="color: #262E6C;">{{ $t("Amount") }}</b>
                <p style="margin-bottom: 2px;">
                  {{ numbersWithcomma(item.amount) }} {{ item.currency }}
                </p>
              </div>
              <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
                <b style="color: #262E6C;">{{ $t('BankAccount') }}</b>
                <p style="margin-bottom: 2px;">
                  {{ item.accountName }}
                </p>
              </div>
              <div style="border: 2px solid lightgrey; padding: 5px; width: 100%;">
                <b>{{ $t("Notes") }}</b>
                <p>{{ item.note }}</p>
              </div>
            </div>
          </div>
        </b-card-text>
        <div style="display:flex;gap:10px">
          <div
            v-if="showTemplate"
            style="width: 30%;"
          >
            <div
              style="width:100%;border: 1px solid lightgray; padding: 20px; background: #FFFFFF 0% 0% no-repeat padding-box; box-shadow: none; border-radius: 3px; opacity: 1; margin:0 5px;"
            >
              <p style="text-align: center">
                {{ $t('AddPayment') }}
              </p>
              <div style="display: flex; justify-content: space-between;">
                <button style="width: 100%;background: #FF274F; border: none; color: white; border-radius: 5px; border: none;">
                  {{ realTime }}
                </button>
              </div>
              <hr style="color: rgb(146, 146, 146);">
              <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
                <b style="color: #475467; font-weight: 500;">{{ $t("Amount") }}</b>
              </div>
              <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
                <b-input
                  v-model.number="input1"
                  type="number"
                />
              </div>
              <div style="width: 100%">
                <b-form-group
                  id="input-group-8"
                  :label="$t('Account')"
                  style="width:100%"
                  label-for="input-25"
                >
                  <vue-select
                    id="input-1"
                    v-model="accountId"
                    :reduce="(e) => e.accountId"
                    label="name"
                    :placeholder="$t('Select')"
                    :options="getAccountDetails"
                  />
                  <p
                    v-if="accountId == null && buttonSubmited == true"
                    style="color:red"
                  >
                    {{ $t('SelectAccount') }}
                  </p>
                </b-form-group>
                <b-form-group
                  id="input-group-8"
                  :label="$t('Currency')"
                  style="width: 100%"
                  label-for="input-25"
                >
                  <vue-select
                    v-model="currency"
                    dropup
                    required
                    :options="currencies"
                    style="width: 100%"
                  />
                </b-form-group>
                <b-form-group :label="$t('SelectD')">
                  <date-picker
                    v-model="paymentDate"
                    value-type="format"
                    format="YYYY-MM-DD"
                    type="date"
                    :clearable="false"
                    :placeholder="$t('SelectD')"
                    style="width:100%"
                  />
                </b-form-group>
              </div>
              <div style="width: 40%; padding: 0px; width: 100%;">
                <b style="color: #475467; font-weight: 500;">{{ $t("Notes") }}</b>
                <b-form-textarea
                  id="textarea"
                  v-model="input2"
                  rows="3"
                  max-rows="6"
                />

              </div>
            </div>
            <div class="buttonsEverywhere">
              <b-button
                :disabled="input1 == null || input1 == ''"
                type="button"
                variant="none"
                class="buttonSubmit"
                style="margin-left: 5px; background: #FF274F !important; color: white;"

                @click="addNewDate(input1, input2)"
              >
                {{ $t('Submit') }}
              </b-button>
              <b-button
                type="button"
                variant="danger"
                class="buttonCancel"
                style="background: white; color: black; border-color: white;font-weight: 400;"

                @click="onCancel"
              >
                {{ $t('Cancel') }}
              </b-button>
            </div>
          </div>
          <button
            v-if="!showTemplate && !$route.path.includes('clients')"
            type="button"
            class="button"
            size="sm"
            style="margin: 10px 5px; border-radius: 6px;"
            @click="showInputTemplatePayment"
          >
            <p style="margin: 0">
              + {{ $t('AddPayment') }}
            </p>
          </button>
        </div>
        <label style="font-weight: 600; padding: 10px 0 10px 0px">{{ $t('ShippmentsPayment') }}</label>
        <div
          style="display:flex;flex-wrap: wrap"
        >
          <div
            v-for="(itemShipments, indexShipments) in getShipmentsDetailsByOrder"
            :key="indexShipments"
            style="width: calc(33.33% - 10px); border: 1px solid lightgray; padding: 20px; background: #FFFFFF 0% 0% no-repeat padding-box; box-shadow: none; border-radius: 6px; opacity: 1; margin: 0 5px 5px 0;"
          >
            <div style="display: flex; justify-content: space-between;align-items:center;gap:5px;">
              <button style="width: 100%; background: #FF274F; border: none; color: white; border-radius: 5px; border: none;">
                {{ formatDate(itemShipments.created) }}
              </button>
              <b-icon-pencil
                v-b-modal.edit-shipment
                style="cursor: pointer;"
                @click="editShipments(itemShipments)"
              />
            </div>
            <hr style="color: rgb(146, 146, 146);">
            <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
              <b style="color: #262E6C;">{{ $t("ExecutorName") }}</b>
              <p style="margin-bottom: 2px;">
                {{ itemShipments.executorName }}
              </p>
            </div>
            <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
              <b style="color: #262E6C;">{{ $t("DateOfPayment") }}</b>
              <p style="margin-bottom: 2px;">
                {{ formatDate1(itemShipments.paymentDate) }}
              </p>
            </div>
            <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
              <b style="color: #262E6C;">{{ $t("Amount") }}</b>
              <p style="margin-bottom: 2px;">
                {{ itemShipments.paidAmount != null ? numbersWithcomma(itemShipments.paidAmount) : 0 }}
              </p>
            </div>
            <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
              <b style="color: #262E6C;">{{ $t('BankAccount') }}</b>
              <p style="margin-bottom: 2px;">
                {{ itemShipments.accountName }}
              </p>
            </div>
            <div style="border: 2px solid lightgrey; padding: 5px; width: 100%;">
              <b>{{ $t("Notes") }}</b>
              <p>{{ itemShipments.notes }}</p>
            </div>
          </div>
        </div>
        <div
          v-if="showTemplateShipping"
          style="width: 30%; padding-top: 10px;"
        >
          <div
            style="width:100%;border: 1px solid lightgray; padding: 20px; background: #FFFFFF 0% 0% no-repeat padding-box; box-shadow: none; border-radius: 3px; opacity: 1; margin:0 5px;"
          >
            <p style="text-align: center;">
              {{ $t('AddShippment') }}
            </p>
            <div style="display: flex; justify-content: space-between;">
              <button style="width: 100%;background: #FF274F; border: none; color: white; border-radius: 5px; border: none;">
                {{ realTime }}
              </button>
            </div>
            <hr style="color: rgb(146, 146, 146);">
            <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
              <b style="color: #475467; font-weight: 500;">{{ $t("Amount") }}</b>
            </div>
            <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
              <b-input
                v-model.number="input1"
                type="number"
              />
            </div>
            <div style="width: 100%">
              <b-form-group
                id="input-group-8"
                :label="$t('Account')"
                style="width:100%"
                label-for="input-25"
              >
                <vue-select
                  id="input-1"
                  v-model="accountId"
                  :reduce="(e) => e.accountId"
                  label="name"
                  :placeholder="$t('Select')"
                  :options="getAccountDetails"
                />
                <p
                  v-if="accountId == null && buttonSubmited == true"
                  style="color:red"
                >
                  {{ $t('SelectAccount') }}
                </p>
              </b-form-group>
              <b-form-group
                id="input-group-8"
                :label="$t('Currency')"
                style="width: 100%"
                label-for="input-25"
              >
                <vue-select
                  v-model="currency"
                  dropup
                  required
                  :options="currencies"
                  style="width: 100%"
                />
              </b-form-group>
              <b-form-group :label="$t('SelectD')">
                <date-picker
                  v-model="paymentDate"
                  value-type="format"
                  format="YYYY-MM-DD"
                  type="date"
                  :clearable="false"
                  :placeholder="$t('SelectD')"
                  style="width:100%"
                />
              </b-form-group>
            </div>
            <div style="width: 40%; padding: 0px; width: 100%;">
              <b style="color: #475467; font-weight: 500;">{{ $t("Notes") }}</b>
              <b-form-textarea
                id="textarea"
                v-model="input2"
                rows="3"
                max-rows="6"
              />

            </div>
          </div>
          <div class="buttonsEverywhere">
            <b-button
              :disabled="input1 == null || input1 == ''"
              type="button"
              variant="none"
              class="buttonSubmit"
              style="margin-left: 5px; background: #FF274F !important; color: white;"

              @click="addNewDate(input1, input2)"
            >
              {{ $t('Submit') }}
            </b-button>
            <b-button
              type="button"
              variant="danger"
              class="buttonCancel"
              style="background: white; color: black; border-color: white;font-weight: 400;"

              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </div>
        <button
          v-if="!showTemplateShipping && !$route.path.includes('clients')"
          type="button"
          class="button"
          size="sm"
          style="margin: 10px 5px; border-radius: 6px;width:20%"
          @click="showInputTemplateShipping"
        >
          <p style="margin: 0">
            + {{ $t('AddShippment') }}
          </p>
        </button>
      </template>
      <EditPayment
        :edit-payment="editPayment"
        :all-total-paid="allTotalPaid"
        :price-order-id="priceOrderId"
        @editPaymentTotal="newEditPayment"
      />
      <EditShipment
        :edit-payment="editPayment"
        :all-total-paid="allTotalPaid"
        :price-order-id="priceOrderId"
        @editPaymentTotal="newEditShipment"
      />
      <EditPriceOrderItem
        :payment-price="paymentPrice"
        :price-order-id="priceOrderId"
        @updatePrice="editPaymentPerOrder"
      />
      <EditShipmentPerOrder
        :payment-price="paymentPrice"
        :price-order-id="priceOrderId"
        @updateShipment="editShipmentPerOrders"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import moment from 'moment'
import DatePicker from 'vue2-datepicker';
import {
} from 'vuelidate/lib/validators';
import 'vue2-datepicker/index.css';
import { client } from '../../../domainConfig'
import EditPayment from './EditPayment.vue'
import EditShipment from './EditShipment.vue'
import EditPriceOrderItem from './EditPriceOrderItem.vue'
import EditShipmentPerOrder from './EditShipmentPerOrder.vue';



export default {
  components: {
    DatePicker,
    EditPayment,
    EditShipment,
    EditPriceOrderItem,
    EditShipmentPerOrder,
  },
  mixins: [validationMixin],
  // eslint-disable-next-line vue/prop-name-casing, vue/require-prop-types
  props: ['order-item-id', 'order', 'year', 'priceOrderId'],
  data() {
    return {
      paymentPrice: null,
      editPayment: null,
      isShipment: false,
      shippingCostRemaining: 0,
      currencies: ['€', '$', 'CHF'],
      client: client.clientName,
      index: null,
      input1: 0,
      input2: null,
      accountId: null,
      currency: '€',
      paymentDate: moment().format('YYYY-MM-DD'),
      showTemplate: false,
      showTemplateShipping: false,
      realPrice: null,
      remaining: null,
      remainings: [],
      tabIndex: 1,
      form: [],
      buttonSubmited: false,
      allTotalPaid: null,
    };
  },
  validations: {
  },
  computed: {
    ...mapGetters([
      'getProbeDates',
      'getimagesOrder',
      'getPrices',
      'getTwentyPrinciples',
      'getMaterialVariantsOfOrder',
      'getDescriptionOfOrder',
      'getOrderItemSize',
      'getLoggedInUser',
      'getShipmentsDetailsByOrder',
      'getOrderItemLocatioon',
      'getOrderItemPaymentByOrderNumber',
      'getAccountDetails',
      'getShipmentDetailsByOrder',
    ]),
    realTime() {
      return moment().format('DD-MM-YYYY hh:mm')
    },
    orderPriceAndPaidOverview() {
      const overview = this.getOrderItemPaymentByOrderNumber?.orderPriceAndPaidOverview || {};

      return {
        price: overview.price || 0,
        discount: overview.discount || 0,
        priceWithDiscount: ((overview.price || 0) - (overview.discount || 0)),
        paidAmount: overview.paidAmount || 0,
        remaining: ((overview.price || 0) - (overview.discount || 0) - (overview.paidAmount || 0)),
      };
    },
  },
  watch: {
    getShipmentDetailsByOrder(value) {
      if (value.shipmentPrice == null) {
        this.getShipmentDetailsByOrder.shipmentPrice = 0
      }
      this.shippingCostRemaining = value.shipmentPrice - value.paidAmount
    },
    tabIndex() {
      this.input1 = 0;
      this.input2 = '';
      this.accountId = null;
      this.currency = '€';
      this.paymentDate = moment().format('YYYY-MM-DD')
      this.showTemplate = false
      this.showTemplateShipping = false;
      this.buttonSubmited = false
      this.isShipment = false
    },
    isShipment() {
      this.input1 = 0;
      this.input2 = '';
      this.accountId = null;
      this.currency = '€';
      this.paymentDate = moment().format('YYYY-MM-DD')
    },
    input1(value) {
      clearTimeout(this.inputTimeout);
      this.inputTimeout = setTimeout(() => {
        if (this.isShipment == false) {
          if (value > this.remainings[this.tabIndex].remainingAmount) {
            this.input1 = 0;
          } else if (value < 0) {
            this.input1 = 0;
          }
        } else if (value > this.shippingCostRemaining) {
          this.input1 = 0;
        } else if (value < 0) {
          this.input1 = 0;
        }
      }, 100);
    },
    getOrderItemPaymentByOrderNumber(value) {
      this.form = value;
      // for (let i = 0; i < value.length; i++) {
      //   value[i].realPrice = value[i].price;
      //   value[i].priceWithDiscount = value[i].price - value[i].discount;
      //   value[i].remaining = value[i].priceWithDiscount - value[i].paidAmount;
      //   this.remaining = value[i].remaining;
      // }
      // this.remainings = value.map((item) => {
      //   return {
      //     orderItemId: item.orderItemId,
      //     remainingAmount: item.remaining,
      //   };
      // });
    },
  },
  mounted() {
    this.accountDetails({ isActive: true, bankId: null })
  },
  methods: {
    ...mapActions(['accountDetails', 'editShipmentOrder', 'editOrderItemPayment', 'editPaymentByTrack', 'editShipmentPayment', 'addShippingOrderPayment', 'resetOrderItemTrack', 'getOrdersOverview', 'shipmentByOrders', 'shipmentsByOrders', 'loadOrderItemPaymentTrackByOrderNumber', 'addOrderItemPaymentTrack', 'loadCountryStates', 'editPaidAmount', 'editOrAddProbeDate', 'delete_probeDate', 'loadProbeDates', 'loadPrices']),
    editPaymentPerOrder(value) {
      this.editOrderItemPayment({
        object: value,
        successCallback: () => {
          this.loadOrderItemPaymentTrackByOrderNumber({ orderId: this.priceOrderId })
          this.getOrdersOverview({
            pageNumber: 1,
            pageSize: 15,
            orderNumber: null,
            clientId: null,
            pickUpDate: null,
            salesType: this.$route.path == '/materials-overview' ? 'Material' : 'Product',
            orderType: null,
            orderItemState: null,
            year: this.year,
          })
        },
      })
    },
    editShipmentPerOrders(value) {
      this.editShipmentOrder({
        object: value,
        successCallback: () => {
          this.shipmentsByOrders({
            orderId: this.priceOrderId,
          })
          this.shipmentByOrders({
            orderId: this.priceOrderId,
          })
          this.getOrdersOverview({
            pageNumber: 1,
            pageSize: 15,
            orderNumber: null,
            clientId: null,
            pickUpDate: null,
            salesType: this.$route.path == '/materials-overview' ? 'Material' : 'Product',
            orderType: null,
            orderItemState: null,
            year: this.year,
          })
        },
      })
    },
    sentPriceOrder() {
      this.paymentPrice = this.orderPriceAndPaidOverview
    },
    sentPricePerShipment() {
      this.paymentPrice = {
        ...this.getShipmentDetailsByOrder,
        remaining: this.shippingCostRemaining,
      }
    },
    newEditPayment(value) {
      this.editPaymentByTrack({
        object: value,
        successCallback: () => {
          this.loadOrderItemPaymentTrackByOrderNumber({ orderId: this.priceOrderId })
          this.getOrdersOverview({
            pageNumber: 1,
            pageSize: 15,
            orderNumber: null,
            clientId: null,
            pickUpDate: null,
            salesType: this.$route.path == '/materials-overview' ? 'Material' : 'Product',
            orderType: null,
            orderItemState: null,
            year: this.year,
          })
        },
      })
    },
    newEditShipment(value) {
      this.editShipmentPayment({
        object: value,
        successCallback: () => {
          this.shipmentsByOrders({
            orderId: this.priceOrderId,
          })
          this.shipmentByOrders({
            orderId: this.priceOrderId,
          })
          this.getOrdersOverview({
            pageNumber: 1,
            pageSize: 15,
            orderNumber: null,
            clientId: null,
            pickUpDate: null,
            salesType: this.$route.path == '/materials-overview' ? 'Material' : 'Product',
            orderType: null,
            orderItemState: null,
            year: this.year,
          })
        },
      })
    },
    editShipments(item) {
      this.allTotalPaid = this.getShipmentDetailsByOrder.paidAmount - this.getShipmentsDetailsByOrder
        .filter((payment) => { return payment.id !== item.id })
        .reduce((total, payment) => { return total + payment.shipmentPrice }, 0) + this.shippingCostRemaining;
      this.editPayment = {
        paymentDate: item.paymentDate,
        amount: item.paidAmount,
        accountId: item.accountId,
        accountName: item.accountName,
        id: item.shipmentId,
      }
      this.accountDetails({
        isActive: true,
      })
    },
    editPayments(item) {
      this.allTotalPaid = this.orderPriceAndPaidOverview.paidAmount
      this.editPayment = item
      this.accountDetails({
        isActive: true,
      })
    },
    showInputTemplatePayment() {
      this.showTemplate = true;
      this.isShipment = false
      this.showTemplateShipping = false;
    },
    showInputTemplateShipping() {
      this.showTemplateShipping = true;
      this.showTemplate = false;
      this.isShipment = true
    },
    addNewDate(input1, input2) {
      this.buttonSubmited = true
      try {
        if (this.accountId == null && this.buttonSubmited == true) {
          return;
        }
        if (this.isShipment == true) {
          this.addShippingOrderPayment({
            object: {
              orderId: this.priceOrderId,
              notes: this.getShipmentDetailsByOrder.notes,
              paidAmount: input1,
              paymentDate: this.paymentDate,
              taxRate: 0,
              accountId: this.accountId,
              currency: this.currency,
            },
            successCallback: () => {
              this.loadOrderItemPaymentTrackByOrderNumber({ orderNumber: this.order })
              this.getOrdersOverview({
                pageNumber: 1,
                pageSize: 15,
                orderNumber: null,
                clientId: null,
                pickUpDate: null,
                salesType: this.$route.path == '/materials-overview' ? 'Material' : 'Product',
                orderType: null,
                orderItemState: null,
                year: this.year,
              })
              this.shipmentByOrders({
                orderId: this.priceOrderId,
              })
              this.shipmentsByOrders({
                orderId: this.priceOrderId,
              })
            },
          })
          this.input1 = 0;
          this.input2 = '';
          this.accountId = null;
          this.currency = '€';
          this.showTemplate = false
          this.showTemplateShipping = false
          this.buttonSubmited = false
          this.isShipment = false
          this.paymentDate = moment().format('YYYY-MM-DD')
        } else {
          this.addOrderItemPaymentTrack({
            object: {
              orderId: this.priceOrderId,
              note: input2,
              amount: input1,
              accountId: this.accountId,
              currency: this.currency,
              paymentDate: this.paymentDate,
            },
            successCallback: () => {
              this.loadOrderItemPaymentTrackByOrderNumber({ orderId: this.priceOrderId })
              this.getOrdersOverview({
                pageNumber: 1,
                pageSize: 15,
                orderNumber: null,
                clientId: null,
                pickUpDate: null,
                salesType: this.$route.path == '/materials-overview' ? 'Material' : 'Product',
                orderType: null,
                orderItemState: null,
                year: this.year,
              })
            },
          })
          this.input1 = 0;
          this.input2 = '';
          this.accountId = null;
          this.currency = '€';
          this.paymentDate = moment().format('YYYY-MM-DD')
          this.showTemplate = false
          this.showTemplateShipping = false
          this.buttonSubmited = false
          this.isShipment = false
        }
      } catch (error) {
        console.log(error)
      }
    },
    numbersWithcomma(value) {
      const options = {
        style: 'decimal', // Other options: 'currency', 'percent', etc.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return value.toLocaleString('en-US', options);
    },
    onCancel() {
      this.showTemplate = false
      this.showTemplateShipping = false
      this.isShipment = false
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.onReset();
    },
    onReset() {
      this.location = ''
      this.buttonSubmited = false
      this.isShipment = false
      this.showTemplate = false
      this.showTemplateShipping = false
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY hh:mm')
    },
    formatDate1(value) {
      return moment(value).format('DD-MM-YYYY')
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
