<template>
  <div>
    <b-sidebar
      id="view-article-number-location-sidebar"
      ref="modal"
      header-class="headerModalToggle"
      right
      shadow
      no-close-on-esc
      width="60%"
    >
      <template #title>
        <strong style="color: #101828;">{{ `${$t('ArticleLocation')}` }}</strong>
      </template>
      <template
        v-slot:header-close
      >
        <button
          style="color: #98A2B3;padding-left: 0px; padding-right: 0px;height: 60px; "
          class="fa fa-close"
          @click="onCancelToggle"
        />
      </template>
      <div style="width: 100%;  padding-top: 16px; margin-top: 15px;">
        <table
          class="team_table"
          style="width: 100%; box-shadow: none; font-size: 13px;cursor:auto"
        >
          <thead>
            <tr>
              <th>
                {{ $t('ArticleNumber') }}
              </th>
              <th>
                {{ $t('Location Name') }}
              </th>
              <th>
                {{ $t('LocationType') }}
              </th>
              <th>
                {{ $t('State') }}
              </th>
              <th>
                {{ $t('Notes') }}
              </th>
              <th>
                {{ $t('Created') }}
              </th>
            </tr>
          </thead>
          <tbody
            v-for="item in getProductInStockLocationPagination"
            :key="item.id"
          >
            <tr>
              <td>
                {{ item.articleNumber }}
              </td>
              <td>
                {{ item.locationName }}
              </td>
              <td>
                {{ $t(item.locationType) }}
              </td>
              <td>
                {{ $t(item.currentState) }}
              </td>
              <td>
                {{ item.notes }}
              </td>
              <td>
                {{ dateModified(item.created) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
// import flatPickr from 'vue-flatpickr-component'
import moment from 'moment';

import 'vue2-datepicker/index.css';
import { mapGetters, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import {
  required, minLength, numeric, alpha, alphaNum, email, minValue,
} from 'vuelidate/lib/validators';
import AWS from 'aws-sdk';
import { AWSurl } from '../../../domainConfig';
// import AddFile from '../../../modals/AddFile.vue'


export default {

  components: {
    // AddFile,
  },
  mixins: [validationMixin],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    editUser: {
      type: Object,
    },
  },
  data() {
    return {
      bucketName: AWSurl.bucketName,
      showFileError: false,
      showFileError2: false,
      showFileError3: false,
      isPhoneNumberValid: null,
      loading: false,
      tabIndex: 0,
      loading2: false,
      loading3: false,
      s3: null,
      fileDataPassport: [],
      fileDataAufenthaltsbewilligung: [],
      fileDataAHV: [],
      formUser: {
        editUserRequestDto: {
          userId: '',
          firstName: '',
          lastName: '',
          salutation: '',
          gender: '',
          personRole: '',
          countryCode: '',
          phoneNumber: '',
          email: '',
          role: '',
          salary: '',
          street: '',
          doorNumber: '',
          postalCode: '',
          socialNumber: '',
          city: '',
          country: '',
          dateOfBirth: '',
          maritalStatus: '',
          residencePermit: '',
          nationality: '',
          employeeType: '',
          houseNumber: '',
        },
        editDocumentRequestDto: [],
      },
      resetPassword: false,
      imagePassport: [],
      imageSocialNumber: [],
      imageResidencePermit: [],
      selectedDate: null,
      config: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
      },
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
      roleOptions: ['CEO', 'Sewer', 'TeamLeader', 'Admin', 'Manager', 'PatternMaker', 'Trainee', 'HandSewer'],
      salutation: ['Mr.', 'Ms.'],
      genderOptions: [
        { value: 'Male', text: this.$t('Male') },
        { value: 'Female', text: this.$t('Female') },
      ],

    }
  },
  validations: {
    formUser: {
      editUserRequestDto: {
        firstName: {
          required,
          // minLength: minLength(3),
          // alpha,
        },
        lastName: {
          required,
          // minLength: minLength(3),
          // alpha,
        },
        salutation: {
          required,
          // minLength: minLength(3),
          // alpha,
        },
        gender: {
          required,
        },
        city: {
          required,
          minLength: minLength(3),
          alpha,
        },
        // dateOfBirth: {
        //   required,
        //   dateFormat,
        // },
        phoneNumber: {
          required,
          minLength: minLength(8),
          // numeric,
        },
        salary: {},
        postalCode: {
          required,
          minLength: minLength(3),
          alphaNum,
        },
        doorNumber: {
          required,
          minLength: minValue(1),
          numeric,
        },
        street: {
          required,
          minLength: minLength(3),
        },
        country: {
          required,
          minLength: minLength(3),
        },
        email: {
          required,
          email,
        },
        role: {
          required,
        },
      },
    },
  },
  computed: {
    ...mapGetters(['getUserRole', 'getFullUsersById', 'getProductInStockLocationPagination']),
    filteredOptions() {
      const options = this.getUserRole.map((role) => {
        return {
          value: role,
          text: this.$t(role),
        }
      });
      return options;
    },
    userById() {
      return this.$store.getters.getFullUsersById;
    },
  },
  watch: {
    visible(value) {
      if (value == false) {
        this.imagePassport = []
        this.imageResidencePermit = []
        this.imageSocialNumber = []
      }
    },
    userById: {
      handler(newVal) {
        console.log('hellooo', newVal)
        this.formUser.editUserRequestDto = {
          userId: newVal.userResponseDto.userId,
          firstName: newVal.userResponseDto.firstName,
          lastName: newVal.userResponseDto.lastName,
          salutation: newVal.userResponseDto.salutation,
          gender: newVal.userResponseDto.gender,
          personRole: newVal.userResponseDto.personRole,
          countryCode: newVal.userResponseDto.countryCode,
          phoneNumber: newVal.userResponseDto.phoneNumber,
          email: newVal.userResponseDto.email,
          role: newVal.userResponseDto.role,
          salary: newVal.userResponseDto.salary,
          street: newVal.userResponseDto.street,
          doorNumber: newVal.userResponseDto.doorNumber,
          postalCode: newVal.userResponseDto.postalCode,
          city: newVal.userResponseDto.city,
          country: newVal.userResponseDto.country,
          socialNumber: newVal.userResponseDto.socialNumber,
          maritalStatus: newVal.userResponseDto.maritalStatus,
          residencePermit: newVal.userResponseDto.residencePermit,
          nationality: newVal.userResponseDto.nationality,
          employeeType: newVal.userResponseDto.employeeType,
          dateOfBirth: moment(newVal.userResponseDto.dateOfBirth).format('DD-MM-YYYY'),
          houseNumber: newVal.userResponseDto.houseNumber,
          isActive: newVal.userResponseDto.isActive,
          totalDays: newVal.userResponseDto.totalDays,
          deactivatedTime: newVal.userResponseDto.deactivatedTime,
          remainingDays: newVal.userResponseDto.remainingDays,
          // created: moment(newVal.userResponseDto.created).format('DD-MM-YYYY'),
        }

        // create an object to store arrays based on documentType
        for (let i = 0; i < newVal.documentFullResponseDto.length; i++) {
          const document = newVal.documentFullResponseDto[i];
          const documentType = document.documentType;
          for (let j = 0; j < document.documentPhotosResponseDtos.length; j++) {
            const photo = document.documentPhotosResponseDtos[j];
            const image = {
              src: photo.url,
              title: photo.fileName,
              documentId: document.documentId,
              photoId: photo.photoId,
              documentType: document.documentType,
              expiryDate: document.expiryDate,
            };
            let imageArray;
            if (documentType === 'Passport') {
              imageArray = this.imagePassport;
            } else if (documentType === 'ResidencePermit') {
              imageArray = this.imageResidencePermit;
            } else if (documentType === 'SocialNumber') {
              imageArray = this.imageSocialNumber;
            }
            const index = imageArray.findIndex(
              (existingImage) => {
                return existingImage.photoId === photo.photoId
                 && existingImage.documentId === document.documentId
              },
            );
            if (index === -1) {
              imageArray.push(image);
            } else {
              // Replace the existing image object with the new one
              imageArray.splice(index, 1, image);
            }
          }
        }
        // access the arrays based on documentType
      },
    },
  },
  mounted() {
    // this.form = this.editUser
    // console.log('tttee', this.getUserRole)
  },
  created() {
    AWS.config.update({
      accessKeyId: AWSurl.accessKeyId,
      secretAccessKey: AWSurl.secretAccessKey,
      region: AWSurl.region,
    });

    this.s3 = new AWS.S3();
  },
  methods: {
    ...mapActions(['getUsersRole', 'deletePhoto']),
    validateState(name) {
      const { $dirty, $error } = this.$v.formUser.editUserRequestDto[name];
      return $dirty ? !$error : null;
    },
    onCancelToggle() {
      this.$emit('onCancelToggle');
    },
    dateModified(xdate) {
      return moment(xdate).format('DD/MM/YYYY - HH:mm');
    },
    handleDeleteClick(photoId) {
      console.log('photoId', photoId)
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this photo?', {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        cancelVariant: 'default',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then((value) => {
          if (value) {
            this.deletePhotoo(photoId);
          }
        })
        .catch((err) => {
          console.log('Error in msgBoxConfirm:', err);
        });
    },
    async deletePhotoo(value) {
      await this.deletePhoto({
        Id: value,
        successCallback: () => {
          this.imagePassport = [];
          this.imageSocialNumber = [];
          this.imageResidencePermit = [];
          this.$emit('getPhotos', this.formUser.editUserRequestDto.userId)
        },
      })
      console.log('photo', value)
    },
    zoomIn(index) {
      this.hoveredIndex = index;
    },
    zoomOut() {
      this.hoveredIndex = null;
    },
    showText() {
      this.isTextShown = true;
    },
    onChangePassport(event) {
      this.fileDataPassport = [];
      const files = event.target.files;
      if (files.length <= 3) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileContent = new FileReader();
          fileContent.readAsArrayBuffer(file);

          const promise = new Promise((resolve) => {
            fileContent.onload = (e) => {
              const fileData = {
                fileContent: Buffer.from(e.target.result),
                filetype: file.type.split('/')[1],
                fileName: file.name.split('.')[0],
              }
              resolve(fileData);
            };
          });

          promise.then((fileData) => {
            this.fileDataPassport.push(fileData);
          });

          // console.log(this.fileDataPassport, 'fasfa');
        }
      } else {
        console.error('You can only select a maximum of 3 files.')
      }
    },
    onChangeAufenthaltsbewilligung(event) {
      this.fileDataAufenthaltsbewilligung = []
      const files = event.target.files;
      if (files.length <= 3) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileContent = new FileReader();
          fileContent.readAsArrayBuffer(file);

          const promise = new Promise((resolve) => {
            fileContent.onload = (e) => {
              const fileData = {
                fileContent: Buffer.from(e.target.result),
                filetype: file.type.split('/')[1],
                fileName: file.name.split('.')[0],
              }
              resolve(fileData);
            };
          });

          promise.then((fileData) => {
            this.fileDataAufenthaltsbewilligung.push(fileData);
          });

          // console.log(this.fileData, 'fasfa');
        }
      } else {
        console.error('You can only select a maximum of 3 files.')
      }
    },
    onChangeAHV(event) {
      this.fileDataAHV = []
      const files = event.target.files;
      if (files.length <= 3) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileContent = new FileReader();
          fileContent.readAsArrayBuffer(file);

          const promise = new Promise((resolve) => {
            fileContent.onload = (e) => {
              const fileData = {
                fileContent: Buffer.from(e.target.result),
                filetype: file.type.split('/')[1],
                fileName: file.name.split('.')[0],
              }
              resolve(fileData);
            };
          });

          promise.then((fileData) => {
            this.fileDataAHV.push(fileData);
          });

          // console.log(this.fileData, 'fasfa');
        }
      } else {
        console.error('You can only select a maximum of 3 files.')
      }
    },
    uploadFileToS3Passport(fileData) {
      return new Promise((resolve, reject) => {
        const params = {
          Bucket: this.bucketName,
          Key: `${Date.now()}-${fileData.fileName}.${fileData.filetype}`,
          Body: fileData.fileContent,
          ContentType: fileData.filetype,
        }
        const passportDocumentKey = params.Key;
        const passportDocObject = this.formUser.editDocumentRequestDto.find((doc) => { return doc.documentType === 'Passport' });
        if (passportDocObject) {
          passportDocObject.key.push(passportDocumentKey); // add the new passport document key to the key property of the Passport object
        } else {
          const newDocumentObject = { // create a new object with the new passport document key
            documentType: 'Passport',
            expiryDate: '2023-12-12',
            key: [passportDocumentKey],
          };
          this.formUser.editDocumentRequestDto.push(newDocumentObject); // push the new object to the array
        }
        this.s3.putObject(params, (error) => {
          if (error) {
            console.log('File uploaded failed to S3')
            this.showFileError = true;
            reject(error);
          } else {
            console.log('File uploaded successfully to S3')
            resolve();
            this.showFileError = false;
          }
        })
      });
    },
    uploadFileToS3Aufenthaltsbewilligung(fileData) {
      return new Promise((resolve, reject) => {
        const params = {
          Bucket: this.bucketName,
          Key: `${Date.now()}-${fileData.fileName}.${fileData.filetype}`,
          Body: fileData.fileContent,
          ContentType: fileData.filetype,
        }
        const aufenthaltsbewilligungDocumentKey = params.Key;
        const aufenthaltsbewilligungDocObject = this.formUser.editDocumentRequestDto.find((doc) => { return doc.documentType === 'ResidencePermit' });
        if (aufenthaltsbewilligungDocObject) {
          aufenthaltsbewilligungDocObject.key.push(aufenthaltsbewilligungDocumentKey); // add the new passport document key to the key property of the Passport object
        } else {
          const newDocumentObject = { // create a new object with the new passport document key
            documentType: 'ResidencePermit',
            expiryDate: '2023-12-12',
            key: [aufenthaltsbewilligungDocumentKey],
          };
          this.formUser.editDocumentRequestDto.push(newDocumentObject); // push the new object to the array
        }
        this.s3.putObject(params, (error) => {
          if (error) {
            console.log('File uploaded failed to S3')
            this.showFileError2 = true;
            reject(error);
          } else {
            console.log('File uploaded successfully to S3')
            resolve();
            this.showFileError2 = false;
          }
        })
      });
    },
    uploadFileToS3AHV(fileData) {
      return new Promise((resolve, reject) => {
        const params = {
          Bucket: this.bucketName,
          Key: `${Date.now()}-${fileData.fileName}.${fileData.filetype}`,
          Body: fileData.fileContent,
          ContentType: fileData.filetype,
        }
        const ahvDocumentKey = params.Key;
        const ahvDocObject = this.formUser.editDocumentRequestDto.find((doc) => { return doc.documentType === 'SocialNumber' });
        if (ahvDocObject) {
          ahvDocObject.key.push(ahvDocumentKey); // add the new passport document key to the key property of the Passport object
        } else {
          const newDocumentObject = { // create a new object with the new passport document key
            documentType: 'SocialNumber',
            expiryDate: '2023-12-12',
            key: [ahvDocumentKey],
          };
          this.formUser.editDocumentRequestDto.push(newDocumentObject); // push the new object to the array
        }
        this.s3.putObject(params, (error) => {
          if (error) {
            console.log('File uploaded failed to S3')
            this.showFileError3 = true;
            reject(error);
          } else {
            console.log('File uploaded successfully to S3')
            resolve();
            this.showFileError3 = false;
          }
        })
      });
    },
    onSubmit() {
      this.$v.formUser.$touch();
      if (this.$v.formUser.$anyError) {
        return;
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const fileData1 of this.fileDataPassport) {
        // eslint-disable-next-line no-await-in-loop
        this.uploadFileToS3Passport(fileData1);
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const fileData2 of this.fileDataAufenthaltsbewilligung) {
        // eslint-disable-next-line no-await-in-loop
        this.uploadFileToS3Aufenthaltsbewilligung(fileData2);
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const fileData3 of this.fileDataAHV) {
        // eslint-disable-next-line no-await-in-loop
        this.uploadFileToS3AHV(fileData3);
      }
      const formatedDate = moment(this.formUser.editUserRequestDto.dateOfBirth, 'DD-MM-YYYY').format('YYYY-MM-DD');
      const payload = {
        ...this.formUser.editUserRequestDto,
        dateOfBirth: formatedDate,
        editDocumentRequestDto: this.formUser.editDocumentRequestDto,
        resetPassword: this.resetPassword,
      }
      this.$emit('edit', payload)
      console.log('paylodi', payload)
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.onReset()
    },
    getFormattedNumber(n) {
      if (n.isValid == true) {
        this.isPhoneNumberValid = true;
        this.formUser.editUserRequestDto.phoneNumber = n.nationalNumber;
        this.formUser.editUserRequestDto.countryCode = n.countryCode;
      } else {
        this.formUser.editUserRequestDto.countryCode = '';
        this.isPhoneNumberValid = false;
        return;
      }
      console.log();
    },
    onCancel() {
      this.$refs.modal.hide()
      this.imagePassport = []
      this.imageResidencePermit = []
      this.imageSocialNumber = []
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.onReset()
    },
    onReset() {
      // Reset our form values
      this.formUser.editUserRequestDto.firstName = ''
      this.formUser.editUserRequestDto.lastName = ''
      this.formUser.editUserRequestDto.salutation = ''
      this.formUser.editUserRequestDto.gender = ''
      this.formUser.editUserRequestDto.city = ''
      this.formUser.editUserRequestDto.street = ''
      this.formUser.editUserRequestDto.dateOfBirth = ''
      this.formUser.editUserRequestDto.postalCode = ''
      this.formUser.editUserRequestDto.doorNumber = ''
      this.formUser.editUserRequestDto.country = ''
      this.formUser.editUserRequestDto.countryCode = ''
      this.formUser.editUserRequestDto.phoneNumber = ''
      this.formUser.editUserRequestDto.email = ''
      this.formUser.editUserRequestDto.role = ''
      this.imagePassport = [];
      this.imageSocialNumber = [];
      this.imageResidencePermit = [];
      this.fileDataPassport = [];
      this.fileDataAufenthaltsbewilligung = [];
      this.formUser.editDocumentRequestDto = [];
      this.fileDataAHV = [];
      this.selectedDate = '';
      // Trick to reset/clear native browser form validation state
    },
  },
}
</script>

  <style lang="scss" scoped>

  .team_table td {
    padding: 16px 10px !important;
  }
   .photo{
      margin-left: 5px;
      height: 50px;
      border-radius: 8px;
    }
  .second-photo{
    margin-left: 5px;
  }
  .hovered {
    /* Additional styles for the hovered photo */
    transform: scale(7.2);
    border-radius: 0px;
    z-index: 10;
  }
  .third-photo{
    margin-left: 5px;
  }
    form {
    }
    .flex-form {
      display: flex;
      flex-direction: column;
    }

    .d-flex{
      width: 100%;
    }

    #input-group-1, #input-group-9, #input-group-2{
      width: 50%;
      margin-right: 8px;
    }

    #input-group-3, #input-group-5, #input-group-7{
      width: 50%;
    }

    #input-5{
      width: 100%;
    }

    #input-group-6, #input-group-3{
      margin-right: 8px;
    }

  </style>
