<template>
  <div>
    <section
      class="main1"
      style="gap:10px;"
    >
      <b-tabs>
        <b-tab>
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("BuyDirect") }}</strong>
          </template>
          <BuyDirect />
        </b-tab>
        <b-tab>
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("PurchaseHistory") }}</strong>
          </template>
          <MaterialVariantHistory />
        </b-tab>
        <b-tab>
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("ExpensesHistory") }}</strong>
          </template>
          <ExpensesMaterialVariantHistory />
        </b-tab>
      </b-tabs>
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import BuyDirect from '@/components/PurchaseMaterial/BuyDirect.vue'
import MaterialVariantHistory from '@/components/PurchaseMaterial/MaterialVariantHistory.vue'
import ExpensesMaterialVariantHistory from '@/components/PurchaseMaterial/ExpensesMaterialVariantHistory.vue'

export default {
  name: 'PurchaseDirect',
  components: {
    BuyDirect,
    MaterialVariantHistory,
    ExpensesMaterialVariantHistory,
  },
  data() {
    return {
    }
  },
  computed: {

  },
  watch: {

  },
  methods: {
    ...mapActions([]),
  },
}
</script>

<style>
.main1{
  padding: 75px 15px 15px 120px;
}

.tables {
  display:flex !important;
  gap:10px;
  padding-top: 15px;
}

@media screen and (max-width: 1200px) {
  .tables {
    display:flex !important;
    flex-direction: column;
    gap:10px;
    padding-top: 15px;
  }
  .main1{
    padding: 75px 15px 15px 20px;
  }
}

</style>
