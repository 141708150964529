<template>
  <div>
    <b-modal
      id="buy-directly"
      ref="modal"
      header-class="headerModal"
      centered
      size="xl"
      :title="$t('BuyDirect')"
      hide-footer
      @close="onCancel"
    >
      <b-tabs>
        <b-tab>
          <template #title>
            <strong
              style="font-weight: 500; color: black"
            >{{ $t('BuyDirect') }}</strong>
          </template>
          <div
            style="display:flex; gap: 20px; margin-bottom: 5px;margin-top:10px;justify-content:space-between"
          >
            <div style="display:flex;flex-direction: column;gap:5px">
              <div class="search-toggle">
                <p>{{ $t('SearchBy') }}</p>
                <div>
                  <p
                    :class="{ active2: toggleSearchArticles === 'ArticleNumber' }"
                    @click="toggleSearchArticles = 'ArticleNumber'"
                  >
                    {{ $t('ArticleNumber') }}
                  </p>
                  <p
                    :class="{ active2: toggleSearchArticles === 'Code' }"
                    @click="toggleSearchArticles = 'Code'"
                  >
                    {{ $t('Code') }}
                  </p>
                  <p
                    :class="{ active2: toggleSearchArticles === 'Category' }"
                    @click="toggleSearchArticles = 'Category'"
                  >
                    {{ $t('Category') }}
                  </p>
                </div>
              </div>
              <div v-if="toggleSearchArticles === 'ArticleNumber'">
                <div class="black">
                  <b-icon-search
                    v-if="searchArticle == ''"
                    class="search1"
                    style="position: absolute; left: 5px; top: 12px"
                  />
                  <b-icon-x-circle
                    v-else-if="searchArticle != ''"
                    class="search1"
                    style="position: absolute; right: 20px; top: 12px; cursor: pointer"
                    @click="searchArticle = ''"
                  />
                  <input
                    v-model="searchArticle"
                    type="text"
                    autocomplete="off"
                    name="search-box"
                    :placeholder="$t('ArticleNumber')"
                    :style="getArticleNameLike.length > 0 ? 'border-radius: 8px 8px 0px 0px' : ''"
                    @keyup="inputChangedArticles"
                    @keydown.down="onArrowDownArticles"
                    @keydown.up="onArrowUpArticles"
                  >
                </div>
                <div
                  v-if="getArticleNameLike.length > 0"
                  ref="scrollContainer"
                  class="dropdrop"
                  :style="getArticleNameLike.length > 7 ? 'height: 210px' : 'height: auto'"
                >
                  <div
                    v-for="(result, index) in getArticleNameLike"
                    :key="index"
                    ref="options"
                    style="cursor: pointer"
                    class="search-item"
                    :class="{ 'is-active': index === arrowCounter }"
                    @click="searchByOnArticles(result);"
                  >
                    <p style="margin: 0">
                      {{ result.articleNumber }}
                    </p>
                  </div>
                </div>
              </div>
              <div v-if="toggleSearchArticles === 'Code'">
                <div class="black">
                  <b-icon-search
                    v-if="searchCodeArticles == ''"
                    class="search1"
                    style="position: absolute; left: 5px; top: 12px"
                  />
                  <b-icon-x-circle
                    v-else-if="searchCodeArticles != ''"
                    class="search1"
                    style="position: absolute; right: 20px; top: 12px; cursor: pointer"
                    @click="searchCodeArticles = ''"
                  />
                  <input
                    v-model="searchCodeArticles"
                    type="text"
                    autocomplete="off"
                    name="search-box"
                    :placeholder="$t('Code')"
                    :style="getFilteredDresses.length > 0? 'border-radius: 8px 8px 0px 0px' : ''"
                    @keyup="inputChangedCodeArticles"
                    @keydown.down="onArrowDownCodeArticles"
                    @keydown.up="onArrowUpCodeArticles"
                  >
                </div>
                <div
                  v-if="getFilteredDresses.length > 0"
                  ref="scrollContainer"
                  class="dropdrop"
                  :style="getFilteredDresses.length > 7 ? 'height: 210px' : 'height: auto'"
                >
                  <div
                    v-for="(result, index) in getFilteredDresses"
                    :key="index"
                    ref="options"
                    style="cursor: pointer"
                    class="search-item"
                    :class="{ 'is-active': index === arrowCounter }"
                    @click="searchByCodeArticles(result);"
                  >
                    <p style="margin: 0">
                      {{ result.name + (result.code ? ' ' + result.code : '') }}
                    </p>
                  </div>
                </div>
              </div>
              <div v-if="toggleSearchArticles === 'Category'">
                <div class="black">
                  <b-icon-search
                    v-if="searchCategoryArticles == ''"
                    class="search1"
                    style="position: absolute; left: 5px; top: 12px"
                  />
                  <b-icon-x-circle
                    v-else-if="searchCategoryArticles != ''"
                    class="search1"
                    style="position: absolute; right: 20px; top: 12px; cursor: pointer"
                    @click="searchCategoryArticles = ''"
                  />
                  <input
                    v-model="searchCategoryArticles"
                    type="text"
                    autocomplete="off"
                    name="search-box"
                    :placeholder="$t('Category')"
                    :style="getFilteredDresses.length > 0? 'border-radius: 8px 8px 0px 0px' : ''"
                    @keyup="inputChangedCategory"
                    @keydown.down="onArrowDownCategory"
                    @keydown.up="onArrowUpCategory"
                  >
                </div>
                <div
                  v-if="getFilteredDresses.length > 0"
                  ref="scrollContainer"
                  class="dropdrop"
                  :style="getFilteredDresses.length > 7 ? 'height: 210px' : 'height: auto'"
                >
                  <div
                    v-for="(result, index) in getFilteredDresses"
                    :key="index"
                    ref="options"
                    style="cursor: pointer"
                    class="search-item"
                    :class="{ 'is-active': index === arrowCounter }"
                    @click="searchByCategoryArticles(result);"
                  >
                    <p style="margin: 0">
                      {{ result.name + (result.code ? ' ' + result.code : '') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div style="display:flex;flex-direction: row;;align-items: end;gap:5px;">
              <b-form-group :label="$t('Vat')">
                <b-form-radio-group
                  v-model="selectedVAT"
                  :options="vatOptions"
                  name="vat-options"
                  buttons
                  button-variant="primary"
                />
              </b-form-group>
            </div>
          </div>
          <p style="color:red;margin-top: 5px; margin-bottom: 0px;">
            {{ warningTextArticles }}
          </p>
          <div
            class="tt"
            style="padding-top: 16px"
          >
            <table
              class="team_table"
            >
              <thead>
                <tr>
                  <th>{{ $t('ArticleNumber') }}</th>
                  <th>{{ $t('Amount') }}</th>
                  <th>{{ $t('PriceNoVat') }}</th>
                  <th>{{ $t('Rabat') }}</th>
                  <th>{{ $t('Vat') }}</th>
                  <th>{{ $t('PriceWithVat') }}</th>
                  <th>{{ $t('AmountWithVat') }}</th>
                  <th>{{ $t('Remove') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(material, index) in selectedArticles"
                  :key="index"
                >
                  <td>{{ material.articleNumber }}</td>
                  <td>
                    <b-form-input
                      v-model.number="material.amount"
                      type="number"
                      :placeholder="$t('Amount')"
                      @input="calculateFromAmount(material)"
                    />
                    <p
                      v-if="material.validationamount == true && buttonSubmited == true"
                      style="color:lightcoral"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </td>
                  <td>
                    <b-form-input
                      v-model.number="material.priceWithoutVAT"
                      type="number"
                      :placeholder="$t('Value')"
                      :disabled="material.amount == 0 || material.amount == '' || material.amount == null"
                      @input="calculateFromPriceWithoutVAT(material)"
                    />
                    <p
                      v-if="material.validationPriceWithoutVAT == true && buttonSubmited == true"
                      style="color:lightcoral"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </td>
                  <td>
                    {{ material.rabaat }}
                  </td>
                  <td>
                    <b-form-radio-group
                      v-model="material.selectedVAT"
                      :options="vatOptions"
                      style="display:flex;gap: 5px;align-items: center;"
                      @input="calculateFromSelectedVAT(material)"
                    />
                  </td>
                  <td>
                    <b-form-input
                      v-model.number="material.priceWithVAT"
                      type="number"
                      :placeholder="$t('Value')"
                      :disabled="material.amount == 0 || material.amount == '' || material.amount == null"
                      @input="calculateFromPriceWithVAT(material)"
                    />
                    <p
                      v-if="material.validationPriceWithVAT == true && buttonSubmited == true"
                      style="color:lightcoral"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </td>
                  <td>
                    <b-form-input
                      v-model.number="material.valueWithVAT"
                      type="number"
                      :placeholder="$t('Value')"
                      :disabled="material.amount == 0 || material.amount == '' || material.amount == null"
                      @input="calculateFromValueWithVAT(material)"
                    />
                    <p
                      v-if="material.validationValueWithVAT == true && buttonSubmited == true"
                      style="color:lightcoral"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </td>
                  <td style="text-align: center">
                    <b-icon-dash-circle
                      style="cursor:pointer;font-size: 20px"
                      @click="removeArticle(index)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style="display:flex;gap:10px;padding-top: 10px">
            <div
              style="width:100%"
            >
              <b-form-group
                :label="$t('Supplier')"
                style="width:100%"
              >
                <vue-select
                  v-model="mainSupplier"
                  :options="getSupplierDropDown"
                  label="supplierName"
                  :reduce="(e) => e.supplierId"
                  :placeholder="$t('Select')"
                />
              </b-form-group>
              <p
                v-if="mainSupplier == null && buttonSubmited"
                style="color:red;padding-top: 10px"
              >
                {{ $t('Main') }}
              </p>
            </div>
            <div style="width:100%">
              <b-form-group
                :label="$t('Account')"
                style="width:100%"
              >
                <vue-select
                  v-model="accountId"
                  :options="getAccountDetails"
                  label="name"
                  :reduce="(e) => e.accountId"
                  :placeholder="$t('Select')"
                />
              </b-form-group>
            </div>

          </div>
          <div style="display:flex;gap:10px;padding-top: 10px">
            <b-form-group
              :label="$t('TotalPaid')"
              style="width:100%"
            >
              <b-form-input
                v-model.number="totalPaidPriceAmount"
                type="number"
                :placeholder="$t('TotalPaid')"
              />
            </b-form-group>
            <b-form-group
              :label="$t('InvoiceNumber')"
              style="width:100%"
            >
              <b-form-input
                v-model="invoiceNumber"
                :placeholder="$t('InvoiceNumber')"
              />
            </b-form-group>
          </div>
          <div style="display:flex;padding-top: 10px;gap:10px">
            <b-form-group
              :label="$t('Location')"
              style="width:100%"
            >
              <vue-select
                v-model="receiverLocationId"
                :options="getLocationsMoving"
                label="locationName"
                :reduce="(e) => e.locationId"
                :placeholder="$t('Select')"
              />
              <p
                v-if="receiverLocationId == null && buttonSubmited"
                style="color:red;padding-top: 10px"
              >
                {{ $t('SelectLocation') }}
              </p>
            </b-form-group>
            <b-form-group
              :label="$t('DateOfInvoice')"
              style="width:100%;padding-top: 5px;"
            >
              <date-picker
                v-model="invoiceDate"
                :placeholder="$t('SelectD')"
                format="YYYY-MM-DD"
                value-type="format"
                style="width:100%"
              />
            </b-form-group>
          </div>
          <p style="padding-top:5px;font-weight:700;width:100%;margin: 0px;padding-top: 10px">
            {{ $t('TotalValue') }} : {{ calculateMaterials }} €
          </p>
          <div style="padding-top: 10px">
            <AddFile
              ref="addFile1"
              :folder="'PurchaseInvoice'"
              :label-text="$t('UploadInvoice')"
              :file-data-type="'Contract'"
              :file-name="fileUrl"
            />
          </div>
          <div style="padding-top: 10px">
            <b-form-group :label="$t('Notes')">
              <b-form-textarea
                v-model="notes"
                :placeholder="$t('Write...')"
              />
            </b-form-group>
          </div>
          <div class="buttonsEverywhere">
            <b-button
              type="button"
              variant="none"
              class="buttonSubmit"
              @click="onSubmit"
            >
              {{ $t('Submit') }}
            </b-button>
            <b-button
              type="button"
              variant="danger"
              class="buttonCancel"
              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
import AddFile from '@/modals/AddFile.vue'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    AddFile,
    DatePicker,
  },
  props: [],
  data() {
    return {
      receiverLocationId: null,
      toggleSearchArticles: 'ArticleNumber',
      searchArticle: '',
      searchCodeArticles: '',
      searchCategoryArticles: '',
      pageNumberFilter: 1,
      pageSizeFilter: 5,
      buttonSubmited: false,
      notes: null,
      mainSupplier: null,
      accountId: null,
      totalPaidPriceAmount: 0,
      invoiceNumber: null,
      invoiceDate: null,
      fileUrl: [],
      arrowCounter: 0,
      enterPressed: false,
      skipWatch: false,
      selectedArticles: [],
      warningTextArticles: '',
      selectedVAT: 18,
      pageSize: 15,
      vatOptions: [
        { text: '18%', value: 18 },
        { text: '8%', value: 8 },
        { text: '0%', value: 0 },
      ],
    }
  },
  computed: {
    ...mapGetters(['getVariantsDropdownByName',
      'getMaterials',
      'getIsLoading',
      'getCurrentPageForMaterials',
      'getTotalItemsForMaterials',
      'getMatVarCurrentPage',
      'getTotalPriceByMaterialId',
      'getVariantsDropdownByCode',
      'getSupplierDropDown',
      'getAccountDetails',
      'getLocationsMoving',
      'getTotalPagesForAll',
      'getArticleNameLike',
      'getFilteredDresses',
    ]),
    calculateMaterials() {
      if (this.selectedArticles && this.selectedArticles.length > 0) {
        return this.selectedArticles.reduce((total, material) => {
          return total + material.valueWithVAT;
        }, 0).toFixed(2);
      }
      return 0;
    },
  },
  watch: {
    selectedArticles: {
      deep: true,
      handler(newMaterials) {
        newMaterials.forEach((material, index) => {
          this.updatePrices(index);
        });
      },
    },
    selectedVAT(newValue) {
      this.selectedArticles.forEach((material) => {
        material.selectedVAT = newValue;
      });
      this.selectedArticles.forEach((material) => {
        material.selectedVAT = newValue;
      });
    },
    searchArticle(value) {
      if (this.skipWatch) {
        this.skipWatch = false;
        return;
      }

      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value === '') {
          this.removeListsArticles();
        } else if (!this.enterPressed) {
          this.productInStock_articleNumberLike(value);
        }
      }, 500);
      this.warningTextArticles = ''
    },
    searchCodeArticles(value) {
      if (this.skipWatch) {
        this.skipWatch = false;
        return;
      }

      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value === '') {
          this.removeListsArticles();
        } else if (!this.enterPressed) {
          this.filteredDressesByName({
            code: value,
            name: null,
          });
        }
      }, 500);
      this.warningTextArticles = ''
    },
    searchCategoryArticles(value) {
      if (this.skipWatch) {
        this.skipWatch = false;
        return;
      }

      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value === '') {
          this.removeListsArticles();
        } else if (!this.enterPressed) {
          this.filteredDressesByName({
            code: null,
            name: value,
          });
        }
      }, 500);
      this.warningTextArticles = ''
    },
  },
  methods: {
    ...mapActions(['totalPriceByMaterialId',
      'resetMaterialVariant',
      'variantsByDropdownName',
      'addMaterial',
      'loadMaterials',
      'editMaterialReq',
      'loadMaterialVariant',
      'addMaterialVariant',
      'changeLoadingtoTrue',
      'resetVariantsName',
      'variantsByDropdownCode',
      'resetVariantsCode',
      'receiveVariantsAndPurchase',
      'loadSuppliers',
      'loadColors',
      'resetProductInStock',
      'resetFilteredDresses',
      'filteredDressesByName',
      'productInStock_articleNumberLike',
      'locationPagination']),
    inputChangedArticles(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getArticleNameLike = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOnArticles(this.getArticleNameLike[this.arrowCounter])
        this.removeListsArticles()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    onArrowDownArticles(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getArticleNameLike.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUpArticles(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    inputChangedCategory(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getArticleNameLike = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByCategoryArticles(this.getFilteredDresses[this.arrowCounter])
        this.removeListsArticles()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    onArrowDownCategory(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFilteredDresses.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUpCategory(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    inputChangedCodeArticles(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredDresses = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByCodeArticles(this.getFilteredDresses[this.arrowCounter])
        this.removeListsArticles()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    onArrowDownCodeArticles(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFilteredDresses.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUpCodeArticles(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    async searchByOnArticles(value) {
      const duplicate = this.selectedArticles.find((material) => { return material.inventoryId === value.inventoryId });

      if (duplicate) {
        this.warningTextArticles = 'There is already the same article.';
        this.resetProductInStock();
        this.skipWatch = true;
        this.searchArticle = '';
        return;
      }
      this.selectedArticles.push({
        articleNumber: value.articleNumber,
        inventoryId: value.inventoryId,
        valueWithVAT: 0,
        amount: 0,
        priceWithVAT: 0,
        rabaat: 0,
        priceWithoutVAT: 0,
        selectedVAT: this.selectedVAT,
        validationPriceWithoutVAT: true,
        validationamount: true,
        validationPriceWithVAT: true,
        validationValueWithVAT: true,
      });
      this.skipWatch = true;
      this.searchArticle = '';
      this.resetProductInStock();
    },
    async searchByCodeArticles(value) {
      const duplicate = this.selectedArticles.find((material) => { return material.inventoryId === value.mainDressCategoryId });

      if (duplicate) {
        this.warningTextArticles = 'There is already the same article.';
        this.resetFilteredDresses();
        this.skipWatch = true;
        this.searchCodeArticles = '';
        return;
      }
      this.selectedArticles.push({
        articleNumber: value.name,
        inventoryId: value.mainDressCategoryId,
        valueWithVAT: 0,
        amount: 0,
        priceWithVAT: 0,
        rabaat: 0,
        priceWithoutVAT: 0,
        selectedVAT: this.selectedVAT,
        validationPriceWithoutVAT: true,
        validationamount: true,
        validationPriceWithVAT: true,
        validationValueWithVAT: true,
      });
      this.skipWatch = true;
      this.searchCodeArticles = '';
      this.resetFilteredDresses();
    },
    async searchByCategoryArticles(value) {
      const duplicate = this.selectedArticles.find((material) => { return material.inventoryId === value.mainDressCategoryId });

      if (duplicate) {
        this.warningTextArticles = 'There is already the same article.';
        this.resetFilteredDresses();
        this.skipWatch = true;
        this.searchCategoryArticles = '';
        return;
      }
      this.selectedArticles.push({
        articleNumber: value.name,
        inventoryId: value.mainDressCategoryId,
        valueWithVAT: 0,
        amount: 0,
        priceWithVAT: 0,
        rabaat: 0,
        priceWithoutVAT: 0,
        selectedVAT: this.selectedVAT,
        validationPriceWithoutVAT: true,
        validationamount: true,
        validationPriceWithVAT: true,
        validationValueWithVAT: true,
      });
      this.skipWatch = true;
      this.searchCategoryArticles = '';
      this.resetFilteredDresses();
    },
    validateMaterials() {
      this.selectedArticles.forEach((material) => {
        if (material.valueWithVAT <= 0 || material.valueWithVAT === null || material.valueWithVAT === '') {
          material.validationValueWithVAT = true;
        } else {
          material.validationValueWithVAT = false;
        }

        if (material.amount <= 0 || material.amount === null || material.amount === '') {
          material.validationamount = true;
        } else {
          material.validationamount = false;
        }

        if (material.priceWithoutVAT <= 0 || material.priceWithoutVAT === null || material.priceWithoutVAT === '') {
          material.validationPriceWithoutVAT = true;
        } else {
          material.validationPriceWithoutVAT = false;
        }

        if (material.priceWithVAT <= 0 || material.priceWithVAT === null || material.priceWithVAT === '') {
          material.validationPriceWithVAT = true;
        } else {
          material.validationPriceWithVAT = false;
        }
      });
    },
    calculateFromAmount(material) {
      if (material.amount > 0) {
        material.priceWithVAT = parseFloat((material.priceWithoutVAT * (1 + material.selectedVAT / 100)).toFixed(2));
        material.valueWithVAT = parseFloat((material.priceWithVAT * material.amount).toFixed(2));
      }
      this.validateMaterials()
    },

    calculateFromPriceWithVAT(material) {
      if (material.amount > 0) {
        material.priceWithoutVAT = parseFloat((material.priceWithVAT / (1 + material.selectedVAT / 100)).toFixed(2));
        material.valueWithVAT = parseFloat((material.priceWithVAT * material.amount).toFixed(2));
      } else {
        material.priceWithoutVAT = parseFloat((material.priceWithVAT / (1 + material.selectedVAT / 100)).toFixed(2));
      }
      this.validateMaterials()
    },
    calculateFromSelectedVAT(material) {
      if (material.amount > 0) {
        material.priceWithVAT = parseFloat((material.priceWithoutVAT * (1 + material.selectedVAT / 100)).toFixed(2));
        material.valueWithVAT = parseFloat((material.priceWithVAT * material.amount).toFixed(2));
      } else if (material.priceWithoutVAT > 0) {
        material.priceWithVAT = parseFloat((material.priceWithoutVAT * (1 + material.selectedVAT / 100)).toFixed(2));
      }

      if (material.priceWithVAT > 0) {
        material.priceWithoutVAT = parseFloat((material.priceWithVAT / (1 + material.selectedVAT / 100)).toFixed(2));
      }
      this.validateMaterials()
    },
    calculateFromPriceWithoutVAT(material) {
      if (material.amount > 0) {
        material.priceWithVAT = parseFloat((material.priceWithoutVAT * (1 + material.selectedVAT / 100)).toFixed(2));
        material.valueWithVAT = parseFloat((material.priceWithVAT * material.amount).toFixed(2));
      } else {
        material.priceWithVAT = parseFloat((material.priceWithoutVAT * (1 + material.selectedVAT / 100)).toFixed(2));
      }
      this.validateMaterials()
    },
    calculateFromValueWithVAT(material) {
      if (material.amount > 0) {
        material.priceWithVAT = parseFloat((material.valueWithVAT / material.amount).toFixed(2));
        material.priceWithoutVAT = parseFloat((material.priceWithVAT / (1 + material.selectedVAT / 100)).toFixed(2));
      }
      this.validateMaterials()
    },
    removeArticle(index) {
      this.selectedArticles.splice(index, 1)
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    removeListsArticles() {
      this.resetProductInStock()
      this.resetFilteredDresses()
    },
    async onSubmit() {
      this.buttonSubmited = true;

      if (this.buttonSubmited) {
        this.selectedArticles.forEach((material) => {
          if (
            material.valueWithVAT <= 0
              || material.valueWithVAT === null
              || material.valueWithVAT === ''
          ) {
            material.validationValueWithVAT = true;
          } else {
            material.validationValueWithVAT = false;
          }

          if (
            material.amount <= 0
              || material.amount === null
              || material.amount === ''
          ) {
            material.validationamount = true;
          } else {
            material.validationamount = false;
          }

          if (
            material.priceWithoutVAT <= 0
              || material.priceWithoutVAT === null
              || material.priceWithoutVAT === ''
          ) {
            material.validationPriceWithoutVAT = true;
          } else {
            material.validationPriceWithoutVAT = false;
          }

          if (
            material.priceWithVAT <= 0
              || material.priceWithVAT === null
              || material.priceWithVAT === ''
          ) {
            material.validationPriceWithVAT = true;
          } else {
            material.validationPriceWithVAT = false;
          }
        });
      }

      const hasErrors = this.selectedArticles.some(
        (material) => {
          return material.validationValueWithVAT || material.validationamount || material.validationPriceWithoutVAT || material.validationPriceWithVAT
        },
      );

      if (this.selectedArticles.length === 0 || this.receiverLocationId == null || this.mainSupplier == null || hasErrors) {
        return
      }
      try {
        await this.$refs.addFile1.uploadFile();
        const purchaseDirectVariantItemRequestDtos = this.selectedArticles.map((item) => {
          return {
            inventoryProductId: item.inventoryId,
            articleNumber: item.articleNumber,
            quantity: item.amount,
            totalPrice: item.valueWithVAT,
            taxRate: item.selectedVAT,
          }
        })
        const objekti = {
          createProductInStockRequestDtos: purchaseDirectVariantItemRequestDtos,
          fileUrls: this.fileUrl,
          supplierId: this.mainSupplier,
          accountId: this.accountId,
          paidAmount: this.totalPaidPriceAmount,
          notes: this.notes,
          totalPrice: this.calculateMaterials,
          invoiceNumber: this.invoiceNumber,
          invoiceDate: this.invoiceDate,
          receiverLocationId: this.receiverLocationId,
        }
        this.$emit('receiveArticles', objekti)
        this.$refs.modal.hide()
        this.onReset()
      } catch (error) {
        console.error(error)
      }
    },
    onCancel() {
      this.$refs.modal.hide()
      this.onReset()
    },
    onReset() {
      this.toggleSearchArticles = 'ArticleNumber'
      this.searchArticle = ''
      this.searchCodeArticles = ''
      this.receiverLocationId = null
      this.searchCategoryArticles = ''
      this.pageNumberFilter = 1
      this.warningTextArticles = ''
      this.buttonSubmited = false
      this.arrowCounter = 0
      this.enterPressed = false
      this.skipWatch = false
      this.selectedArticles = []
      this.warningTextArticles = ''
      this.selectedVAT = 18
      this.mainSupplier = null
      this.accountId = null
      this.totalPaidPriceAmount = 0
      this.invoiceNumber = null
      this.invoiceDate = null
      this.notes = null
      this.fileUrl = []
    },
  },
}
</script>

  <style>
  .active2{
    color: #212529;
    font-weight: bold;
  }
  .btn-group > :not(.btn-check:first-child) + .btn, .btn-group > .btn-group:not(:first-child) {
      margin-left: calc(var(--bs-border-width)* -1);
      display: flex;
      align-items: center;
      gap: 5px;
  }

  .btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn.dropdown-toggle-split:first-child, .btn-group > .btn-group:not(:last-child) > .btn {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      display: flex;
      align-items: center;
      gap: 5px;
  }

  input[type=radio]:checked:after {
      width: 17px !important;
      height: 17px !important;
      border-radius: 15px;
      top: -3px;
      left: -1px;
      position: relative;
      background-color: #242F6E;
      content: "";
      display: inline-block;
      visibility: visible;
      border: 2px solid white;
  }
  .hover-row:hover {
      background-color: rgba(255, 39, 79, 0.1);
  }
  .dropdrop::-webkit-scrollbar {
    display: none;
  }

  .team_table td {
    padding: 9px 9px !important;
  }

  thead tr th {
      background: #f4f5f6 !important;
      color: #475467 !important;
      font-size: 14px !important;
      font-weight: 500 !important;
  }

  .dropdrop {
    display: inline-block;
    overflow: auto;
    position: absolute;
    background: white;
    width: 227px;
    box-shadow: none;
    z-index:3;
  }

  input[name="search-box"] {
    display: block;
    max-width: 500px;
    width: 95%;
    padding: 0.5rem 1rem;
    padding-left: 25px;
  }

  .search-item {
    padding: 5px;
    max-width: 500px;
    width: 100%;
    font-weight: 400;
    color: #82868c;
    font-size: 1rem;
    border-bottom: 1px solid #e8e8e8;
  }

  .search-item:last-child {
    border-bottom: none;
  }

  .search-item:hover {
    background: #82868c;
    color: white;
  }

  .search-item hr {
    color: lightgray;
    border-top: none;
    margin: -1.3rem 0 0.5rem 0;
  }

  .is-active {
    background-color: #dedede;
  }
  </style>
