<!-- eslint-disable vue/no-template-shadow -->

<template>
  <div>
    <b-sidebar
      id="edit-order-item"
      ref="modal"
      right
      shadow
      width="60%"
      header-class="headerModalToggle"
      centered
      size="xl"
      :title="$t('Edit')"
      hide-footer
      no-close-on-esc
      @close="onCancel"
    >
      <template #title>
        <strong style="color: #101828;">{{ `${$t('Edit')} - ${window.name}` }}</strong>
      </template>
      <template
        v-slot:header-close
      >
        <button
          style="color: #98A2B3;padding-left: 0px; padding-right: 0px;height: 60px; "
          class="fa fa-close"
          @click="onCancel"
        />
      </template>
      <div style="padding:20px">
        <div>
          <div style="display:flex;width:100%;margin-bottom:15px;gap:10px">
            <div style="display:flex;width: 100%">
              <b-form-group
                style="width:100%"
                :label="$t('Pattern')"
              >
                <b-form-checkbox v-model="window.orderOtherService.pattern" />
              </b-form-group>
              <b-form-group
                style="width:100%"
                :label="$t('Sewing')"
              >
                <b-form-checkbox v-model="window.orderOtherService.sewing" />
              </b-form-group>
              <b-form-group
                style="width:100%"
                :label="$t('Transport')"
              >
                <b-form-checkbox v-model="window.orderOtherService.transport" />
              </b-form-group>
              <b-form-group
                style="width:100%"
                :label="$t('Montage')"
              >
                <b-form-checkbox v-model="window.orderOtherService.montage" />
              </b-form-group>
            </div>
            <b-form-group
              :label="$t('HingType')"
              style="width:50%"
            >
              <vue-select
                v-model="window.hingType"
                :options="hingeTypes"
                label="name"
                :reduce="(e) => e.value"
                :placeholder="$t('Select')"
              />
            </b-form-group>
          </div>
          <div
            v-if="window.orderOtherService.montage == true"
            style="display:flex;gap:25px"
          >
            <b-form-group
              :label="$t('MontageStart')"
            >
              <date-picker
                v-model="window.from"
                type="datetime"
                :placeholder="$t('SelectD')"
              />
              <p
                v-if="window.orderOtherService.montage && window.from == null && buttonSubmitClicked"
                style="color: red;padding-top: 5px;
                    margin-bottom: 0px;"
              >
                {{ $t('SelectD') }}
              </p>
            </b-form-group>
            <div
              style="display: flex;gap:10px;"
            >
              <div>
                <label style="padding-left: 25px;;">{{ $t('DurationHour') }}</label>
                <div style="display: flex;flex-direction: row;align-items:center; gap: 8px;">
                  <b-icon-dash-circle
                    style="cursor:pointer"
                    @click="substractHour"
                  />
                  <b-form-input
                    v-model.number="durationTimeHour"
                    type="number"
                    :placeholder="$t('Hour')"
                  />
                  <b-icon-plus-circle
                    style="cursor:pointer"
                    @click="addHour"
                  />
                </div>
              </div>
              <div>
                <label style="padding-left: 25px;;">{{ $t('DurationMinutes') }}</label>
                <div style="display: flex;flex-direction: row;align-items:center; gap: 8px;">
                  <b-icon-dash-circle
                    style="cursor:pointer"
                    @click="substractMinutes"
                  />
                  <b-form-input
                    v-model.number="durationTimeMinutes"
                    type="number"
                    :placeholder="$t('Minutes')"
                  />
                  <b-icon-plus-circle
                    style="cursor:pointer"
                    @click="addMinutes"
                  />
                </div>
              </div>
            </div>
            <b-form-group
              :label="$t('MontageEnd')"
            >
              <date-picker
                v-model="window.to"
                type="datetime"
                :placeholder="$t('SelectD')"
              />
              <p
                v-if="window.orderOtherService.montage && window.to == null && buttonSubmitClicked"
                style="color: red;padding-top: 5px;
                    margin-bottom: 0px;"
              >
                {{ $t('SelectD') }}
              </p>
            </b-form-group>
          </div>
          <div style="display: flex;gap: 25px;margin-top: 10px;">
            <b-form-group
              :label="$t('Width')"
              style="width:100%"
            >
              <b-form-input
                v-model="window.width"
                :placeholder="$t('0')"
                @input="updateMaterialLengthOrQuantity(window)"
              />
              <p
                v-if="window.width < 0.0001 && buttonSubmitClicked"
                style="color: red;padding-top: 5px;
                    margin-bottom: 0px;"
              >
                {{ $t('TheValue') }}
              </p>
            </b-form-group>
            <b-form-group
              :label="$t('Height')"
              style="width:100%"
              :placeholder="$t('0')"
            >
              <b-form-input
                v-model="window.height"
              />
              <p
                v-if="window.height < 0.0001 && buttonSubmitClicked"
                style="color: red;padding-top: 5px;
                    margin-bottom: 0px;"
              >
                {{ $t('TheValue') }}
              </p>
            </b-form-group>
            <b-form-group
              :label="$t('SewingType')"
              style="width:100%"
            >
              <vue-select
                v-model="window.sewingType"
                :options="sewingTypes"
                label="name"
                :reduce="(e) => e.value"
                :placeholder="$t('Select')"
                @input="handleSewingMethod(window)"
              />
              <p
                v-if="window.sewingType == null && buttonSubmitClicked"
                style="color: red;padding-top: 5px;
                    margin-bottom: 0px;"
              >
                {{ $t('PleaseSelectST') }}
              </p>
            </b-form-group>
            <b-form-group
              v-if="window.orderOtherService.montage == false"
              :label="$t('PickupDate')"
              style="width:100%"
            >
              <date-picker
                v-model="window.pickUpDate"
                type="datetime"
                :placeholder="$t('SelectD')"
              />
              <p
                v-if="!window.orderOtherService.montage && window.pickUpDate == null && buttonSubmitClicked"
                style="color: red;padding-top: 5px;
                    margin-bottom: 0px;"
              >
                {{ $t('SelectD') }}
              </p>
            </b-form-group>
          </div>
        </div>
        <div
          class=""
          style="margin-top: 15px"
        >
          <b-button
            v-b-toggle="`lapse-${idx}`"
            class="m-1"
            variant="light"
          >
            <b-icon-arrows-expand />
          </b-button>
          <span style="font-weight: 500">{{ $t('ShowMaterialVariant') }}</span>


          <b-collapse
            :id="`lapse-${idx}`"
            visible
          >
            <p
              v-if="emptyList"
              style="color: red; margin: 0;"
            >
              {{ $t('EmptyMaterials') }}
            </p>
            <p
              v-if="duplicatedMaterials"
              style="color: red; margin: 0;"
            >
              {{ $t('DuplicatedMaterials') }}
            </p>
            <div
              v-for="(material, materialIndex) in form"
              :key="materialIndex"
              class="materialsClass"
            >
              <div style="display:flex;justify-content: space-between;padding-right: 80px">
                <label> {{ materialIndex + 1 }}.{{ $t('MeasurementType') }} <span style="font-weight:600"> {{ material.parameter ? `: ${material.parameter}`: null }} </span></label>
                <b-icon-trash
                  style="cursor:pointer;"
                  @click="removeMaterial(materialIndex)"
                />
              </div>
              <b-form-group>
                <b-form-radio-group
                  v-model="material.measurementType"
                  style="display:flex;gap: 10px;width:90%"
                  @change="resetMaterialName(material, window)"
                >
                  <b-form-radio
                    class="form-radio"
                    value="Meter"
                  >
                    <p>{{ $t('Meter') }} </p>
                  </b-form-radio>
                  <b-form-radio
                    class="form-radio"
                    value="Part"
                  >
                    <p>{{ $t('Part') }} </p>
                  </b-form-radio>
                  <b-form-radio
                    class="form-radio"
                    value="Verse"
                  >
                    <p>{{ $t('Verse') }} </p>
                  </b-form-radio>
                  <b-form-radio
                    class="form-radio"
                    value="Package"
                  >
                    <p>{{ $t('Package') }} </p>
                  </b-form-radio>
                  <b-form-radio
                    class="form-radio"
                    value="Pair"
                  >
                    <p>{{ $t('Pair') }} </p>
                  </b-form-radio>
                  <b-form-radio
                    class="form-radio"
                    value="Set"
                  >
                    <p>{{ $t('Set') }} </p>
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <div style="display:flex;gap:10px;width:100%">
                <b-form-group
                  :label="$t('MaterialVariant')"
                  style="width:25%"
                >
                  <vue-select
                    v-model="material.name"
                    :placeholder="$t('Description')"
                    label="materialVariantName"
                    :options="getMaterialOptions(material.measurementType)"
                    @search="searchMaterial(material.measurementType, $event)"
                    @input="updateMaterialVariantId(material.name, material)"
                  >
                    <li
                      slot="list-footer"
                      class="pagination"
                      style="display: flex; width: 100%; justify-content: center;"
                    >
                      <div
                        v-if="getMaterialVariantColorNameLike.length > 1"
                        style="display:flex;gap:5px; padding-top: 3px;width:98%"
                      >
                        <button
                          class="buttonCancel"
                          type="button"
                          :disabled="pageNumberFilter == 1"
                          @click="previousPage(material.measurementType, searchQuery)"
                        >
                          {{ $t('Previous') }}
                        </button>
                        <button
                          class="buttonSubmit"
                          type="button"
                          @click="nextPage(material.measurementType, searchQuery)"
                        >
                          {{ $t('Next') }}
                        </button>
                      </div>
                    </li>
                  </vue-select>
                  <p
                    v-if="(material.name == null || material.name == '' )&& buttonSubmitClicked "
                    class="validationClass"
                  >
                    {{ $t('Please Select a material variant') }}
                  </p>
                </b-form-group>
                <b-form-group
                  v-if="material.measurementType == 'Part' || material.measurementType == 'Parts'"
                  :label="$t(material.measurementType)"
                >
                  <b-input
                    v-model.number="material.quantity"
                    required
                    type="number"
                    @input="calculateTotalPerMeter(material.pricePerMeter, material.quantity)"
                  />
                  <p
                    v-if="material.quantity < 0.0001 && buttonSubmitClicked "
                    class="validationClass"
                  >
                    {{ $t('TheValue') }}
                  </p>
                </b-form-group>
                <b-form-group
                  v-else
                  :label="$t(material.measurementType)"
                >
                  <b-input
                    v-model.number="material.length"
                    required
                    type="number"
                    @input="calculateTotalPerMeter(material.pricePerMeter, material.length)"
                  />
                  <p
                    v-if="material.length < 0.0001 && buttonSubmitClicked "
                    class="validationClass"
                  >
                    {{ $t('TheValue') }}
                  </p>
                </b-form-group>
                <b-form-group
                  :label="$t('pricePerMeter')"
                >
                  <b-input
                    v-model.number="material.pricePerMeter"
                    required
                    disabled
                    type="number"
                    @input="calculateTotalPerMeter(material.pricePerMeter, material.length || material.quantity)"
                  />
                </b-form-group>
                <b-form-group
                  :label="$t('PriceDiscount')"
                >
                  <b-input
                    v-model.number="material.pricePerMeterWithDiscount"
                    required
                    type="number"
                    @input="calculateTotalPerMeter(material.pricePerMeterWithDiscount, material.length || material.quantity)"
                  />
                </b-form-group>
              </div>
            </div>
            <button
              class="buttonNotaSmall"
              type="button"
              style="margin-top: 10px;"
              @click="addMaterialObject()"
            >
              Shto Material
            </button>
          </b-collapse>

        </div>
      </div>
      <div class="buttonsEverywhereToggle">
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t('Submit') }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
      <vue-easy-lightbox
        :esc-disabled="false"
        :visible="image.visible"
        :imgs="image.imgs"
        :index="image.index"
        @hide="handleHide"
      />
    </b-sidebar>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueEasyLightbox from 'vue-easy-lightbox'
import DatePicker from 'vue2-datepicker';
import { client } from '../../../domainConfig'
import 'vue2-datepicker/index.css';


export default {
  components: {
    VueEasyLightbox,
    DatePicker,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['windowId', 'orderIds'],
  data() {
    return {
      durationTimeMinutes: 0,
      durationTimeHour: 0,
      hingeTypes: [
        { name: 'Decorative', value: 'Decorative' },
        { name: 'Closed', value: 'Closed' },
      ],
      sewingTypes: [
        {
          name: 'Sistemi Amerikan 3m', value: 'American_3m', meter: 3, materialVariantId: 'cd9e5ed2-df10-4ba4-a6db-08dd09677ee2', materialVariantName: 'Shiritat Amerikan',
        },
        {
          name: 'Sistemi Amerikan 2.8m', value: 'American_2_8m', meter: 2.8, materialVariantId: 'cd9e5ed2-df10-4ba4-a6db-08dd09677ee2', materialVariantName: 'Shiritat Amerikan',
        },
        {
          name: 'Fallta te rregullta 3m', value: 'FalltaReglar_3m', meter: 3, materialVariantId: 'dea85c63-6242-4010-a6ee-08dd09677ee2', materialVariantName: 'Shirita Fallta',
        },
        {
          name: 'Fallta te rregullta 2.5m', value: 'FalltaReglar_2_5m', meter: 2.5, materialVariantId: 'dea85c63-6242-4010-a6ee-08dd09677ee2', materialVariantName: 'Shirita Fallta',
        },
        {
          name: 'Fallta te rregullta 2m', value: 'FalltaReglar_2m', meter: 2, materialVariantId: 'dea85c63-6242-4010-a6ee-08dd09677ee2', materialVariantName: 'Shirita Fallta',
        },
        {
          name: 'Fallta Zvicrrane 3m', value: 'FalltaSwiss_3m', meter: 3, materialVariantId: '12f0c104-a95a-47c8-a6dc-08dd09677ee2', materialVariantName: 'Shiritat Zvicerran',
        },
        {
          name: 'Fallta Zvicrrane 2.7m', value: 'FalltaSwiss_2_7m', meter: 2.7, materialVariantId: '12f0c104-a95a-47c8-a6dc-08dd09677ee2', materialVariantName: 'Shiritat Zvicerran',
        },
        {
          name: 'Fallta Zvicrrane 2m', value: 'FalltaSwiss_2_5m', meter: 2.5, materialVariantId: '12f0c104-a95a-47c8-a6dc-08dd09677ee2', materialVariantName: 'Shiritat Zvicerran',
        },
        {
          name: 'Qepje me vega 2.5m', value: 'Veg_2_5m', meter: 2.5, materialVariantId: '70ff128e-c889-424a-a6ed-08dd09677ee2', materialVariantName: 'Shirita me vega',
        },
        {
          name: 'Qepje Fallta me vrime 3m', value: 'Holes_3m', meter: 3, materialVariantId: 'dea85c63-6242-4010-a6ee-08dd09677ee2', materialVariantName: 'Shirita Fallta',
        },
        {
          name: 'Qepje Fallta me vrime 2.5m', value: 'Holes_2_5m', meter: 2.5, materialVariantId: 'dea85c63-6242-4010-a6ee-08dd09677ee2', materialVariantName: 'Shirita Fallta',
        },
        {
          name: 'Qepje me rrudhje 4m', value: 'Wrinkle_4m', meter: 4, materialVariantId: 'f4ad44f0-55f5-476c-a6ef-08dd09677ee2', materialVariantName: 'Shirita me rrudha',
        },
        {
          name: 'Qepje me rrudhje 3m', value: 'Wrinkle_3m', meter: 3, materialVariantId: 'f4ad44f0-55f5-476c-a6ef-08dd09677ee2', materialVariantName: 'Shirita me rrudha',
        },
        {
          name: 'Qepje shirit drejt', value: 'StraightBand', meter: 1, materialVariantId: '0974f5ce-e07e-4ac0-a6f0-08dd09677ee2', materialVariantName: 'Shirita drejt',
        },
      ],
      image: {
        zoom: 1,
        imgs: [], // Img Url , string or Array of string
        visible: false,
        index: 0, //
      },
      window: {
        name: null,
        width: 0,
        height: 0,
        sewingType: null,
        hingType: null,
        orderOtherService: {
          pattern: false,
          sewing: false,
          transport: false,
          montage: false,
        },
      },
      toggleSearch: 'MaterialName',
      selectedMaterialVariantPackage: '',
      selectedMaterialVariant: '',
      selectedMaterialVariantPart: '',
      selectedMaterialVariantSet: '',
      selectedMaterialVariantPair: '',
      selectedMaterialVariantVerse: '',
      clickedMaterialVariantId: null,
      client: client.clientName,
      showLengthError: false,
      isUndefined: false,
      expand: false,
      index: 0,
      pageM: 1,
      pageP: 1,
      pagePack: 1,
      pageSet: 1,
      pagePair: 1,
      pageVerse: 1,
      pageSize: 15,
      radio: null,
      idx: 0,
      showBody: true,
      form: [],
      duplicatedMaterials: false,
      emptyList: false,
      buttonSubmitClicked: false,
    }
  },
  computed: {
    ...mapGetters(['getImagesProduct', 'getWindowDetails', 'getSavedStock', 'getMaterialsVByPackage', 'getMaterialsVBySet', 'getMaterialsVByVerse', 'getMaterialsVByPair', 'getTotalItemsPages', 'getMaterialVariantColorNameLike', 'getEditOrder', 'getWindowDetails', 'getCurrentPageForMVP', 'getMaterialsVByMeter',
      'getMaterialsVByPart', 'getFilteredMV', 'getTotalPagesForMVP',
      'getTotalPagesForMVM', 'getCurrentPageForMVM', 'getCurrentPageForMVP',
      'getMeasermentsById', 'getFilterVariantsByCodeLike']),
    getButtonStyle() {
      if (this.selectedMaterialVariant !== '') {
        return this.pageM == this.getTotalItemsPages
          ? { width: '50%', border: 'none' }
          : {
            width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
          };
      }
      return this.pageM == this.getTotalPagesForMVM
        ? { width: '50%', border: 'none' }
        : {
          width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
        };
    },
    getButtonStylePart() {
      if (this.selectedMaterialVariantPart !== '') {
        return this.pageM == this.getTotalItemsPages
          ? { width: '50%', border: 'none' }
          : {
            width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
          };
      }
      return this.pageM == this.getTotalPagesForMVP
        ? { width: '50%', border: 'none' }
        : {
          width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
        };
    },
  },
  watch: {
    form: {
      handler(newValue) {
        const materialVariantIds = newValue.map((item) => { return item.materialVariantId });
        const uniqueIds = new Set(materialVariantIds);
        this.duplicatedMaterials = materialVariantIds.length !== uniqueIds.size;
        this.emptyList = newValue.length === 0;
      },
      deep: true,
    },
    getWindowDetails() {
      this.fillForm()
      this.handleSewingMethod(this.window)
    },
    'window.orderOtherService.montage': function (newValue) {
      if (newValue == false) {
        this.window.from = null
        this.window.to = null
        this.durationTimeHour = 0
        this.durationTimeMinutes = 0
      } else {
        this.window.pickUpDate = null
      }
    },
    'window.from': function (newValue) {
      if (newValue && this.window.to) {
        this.calculateDurationFromTo();
      }
    },
    'window.to': function (newValue) {
      if (newValue && this.window.from) {
        this.calculateDurationFromTo();
      }
    },
    durationTimeHour(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value < 0) {
          this.durationTimeHour = 0;
        } else if (value === '') {
          this.durationTimeHour = 0;
        } else {
          const fullHours = Math.floor(value);
          const fractionalPart = value - fullHours;

          this.durationTimeHour = fullHours;
          this.durationTimeMinutes += Math.round(fractionalPart * 60);
        }
        this.calculateMontageEnd()
      }, 0);
    },
    durationTimeMinutes(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value < 0) {
          this.durationTimeMinutes = 0
        }
        if (value == '') {
          this.durationTimeMinutes = 0;
        }
        if (value >= 60) {
          const totalHours = Math.floor(value / 60);
          this.durationTimeHour += totalHours;

          const fractionalPart = (value / 60) - totalHours;
          const remainingMinutes = Math.round(fractionalPart * 60);

          this.durationTimeMinutes = remainingMinutes;
        }
        if (value < 0 && this.durationTimeHour > 0) {
          this.durationTimeHour -= 1
          this.durationTimeMinutes = 45
        }
        this.calculateMontageEnd()
      }, 0);
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions(['getImagesByMaterialVariantId', 'currentStockByVariant',
      'resetAllFiltered', 'filterVariantByColor', 'getMaterialVariantsToMDCPagination',
      'edit_OrderItem', 'getMaterialVariantsToMDCPagination', 'testResetMV',
      'loadMaterialVaraintsCategoryNameLike', 'filterVariantByCode']),
    getMaterialOptions(measurementType) {
      let measurement
      if (this.getMaterialVariantColorNameLike.length > 0) {
        measurement = this.getMaterialVariantColorNameLike
      } else if (measurementType === 'Meter') {
        measurement = this.getMaterialsVByMeter;
      } else if (measurementType === 'Package') {
        measurement = this.getMaterialsVByPackage;
      } else if (measurementType === 'Set') {
        measurement = this.getMaterialsVBySet;
      } else if (measurementType === 'Pair') {
        measurement = this.getMaterialsVByPair;
      } else if (measurementType === 'Verse') {
        measurement = this.getMaterialsVByVerse;
      } else {
        measurement = this.getMaterialsVByPart;
      }


      return measurement;
    },
    calculateDurationFromTo() {
      if (this.window.from && this.window.to) {
        const fromDate = new Date(this.window.from);
        const toDate = new Date(this.window.to);

        const diffInMinutes = (toDate - fromDate) / 60000;

        this.durationTimeHour = Math.floor(diffInMinutes / 60);
        this.durationTimeMinutes = diffInMinutes % 60;
      }
    },
    calculateMontageEnd() {
      if (this.window.from && this.durationTimeHour != null && this.durationTimeMinutes != null) {
        const startDate = new Date(this.window.from);

        startDate.setHours(startDate.getHours() + this.durationTimeHour);
        startDate.setMinutes(startDate.getMinutes() + this.durationTimeMinutes);

        startDate.setSeconds(0);

        this.window.to = startDate;

        console.log('Montage End:', this.window.to);
      }
    },
    addHour() {
      this.durationTimeHour++;
      this.calculateMontageEnd();
    },
    substractHour() {
      this.durationTimeHour--;
      this.calculateMontageEnd();
    },
    addMinutes() {
      this.durationTimeMinutes += 15
      this.calculateMontageEnd();
    },
    substractMinutes() {
      this.durationTimeMinutes -= 15
      this.calculateMontageEnd();
    },
    addMaterialObject() {
      this.form.push({
        materialVariantId: null,
        name: null,
        measurementType: 'Meter',
        quantity: 0,
        length: 0,
        pricePerMeter: 0,
        pricePerMeterWithDiscount: 0,
      });
    },
    handleSewingMethod(window) {
      this.updateMaterialLengthOrQuantity(window)
      this.updateMaterialAmerican(window)
    },
    updateMaterialAmerican(window) {
      if (window.sewingType) {
        const selectedSewingType = this.sewingTypes.find(
          (type) => { return type.value === window.sewingType },
        );
        const existingItemIndex = this.form.findIndex(
          (item) => { return item.parameter === 'Shirit' },
        );
        const matchedItem = this.getWindowDetails.windowItemMaterialVariantResponseDto.find(
          (item) => { return item.materialVariantId === selectedSewingType.materialVariantId },
        );

        const windowMaterialVariantId = matchedItem ? matchedItem.windowMaterialVariantId : null;
        console.log('windowMaterialVariantId', windowMaterialVariantId)
        if (existingItemIndex !== -1) {
          this.form[existingItemIndex] = {
            materialVariantId: selectedSewingType.materialVariantId,
            name: selectedSewingType.materialVariantName,
            measurementType: 'Meter',
            quantity: 0,
            length: (selectedSewingType.meter * (window.width || 0) + 0.1)?.toFixed(2),
            pricePerMeter: 0,
            pricePerMeterWithDiscount: 0,
            parameter: 'Shirit',
            windowMaterialVariantId: windowMaterialVariantId || null,
          };
        } else {
          this.form.unshift({
            materialVariantId: selectedSewingType.materialVariantId,
            name: selectedSewingType.materialVariantName,
            measurementType: 'Meter',
            quantity: 0,
            length: (selectedSewingType.meter * (window.width || 0) + 0.1)?.toFixed(2),
            pricePerMeter: 0,
            pricePerMeterWithDiscount: 0,
            parameter: 'Shirit',
            windowMaterialVariantId: windowMaterialVariantId || null,
          });
        }
      } else {
        this.form = this.form.filter(
          (item) => { return item.parameter !== 'Shirit' },
        );
      }
    },
    updateMaterialLengthOrQuantity(window) {
      if (!window.sewingType || window.width == null || window.width === '') {
        this.form.forEach((material) => {
          if (material.measurementType === 'Part') {
            material.quantity = 0;
          } else {
            material.length = 0;
          }
        });
      }
      const selectedSewingType = this.sewingTypes.find(
        (type) => { return type.value === window.sewingType },
      );
      if (selectedSewingType) {
        const meter = selectedSewingType.meter ?? 0;
        const width = window.width ?? 0;
        const calculatedValue = meter * width;
        this.form.forEach((material) => {
          if (material.measurementType === 'Part') {
            material.quantity = (calculatedValue + 0.1)?.toFixed(2);
            material.length = 0;
          } else {
            material.length = (calculatedValue + 0.1)?.toFixed(2);
            material.quantity = 0;
          }
        });
      }
    },
    updateMaterialVariantId(value, material) {
      if (value != null) {
        material.materialVariantId = value.materialVariantId
        material.pricePerMeter = value.sellPrice || 0
        material.name = value.materialVariantName
        material.pricePerMeterWithDiscount = value.sellPrice || 0
      } else {
        material.materialVariantId = null
        material.pricePerMeter = 0
        material.pricePerMeterWithDiscount = 0
        material.name = null
      }
    },
    nextPage(measurement, query) {
      this.pageNumberFilter += 1
      this.filterVariantByColor({
        name: query,
        measurementType: measurement,
        pageNumber: this.pageNumberFilter,
        pageSize: 15,
      });
    },
    previousPage(measurement, query) {
      this.pageNumberFilter -= 1
      this.filterVariantByColor({
        name: query,
        measurementType: measurement,
        pageNumber: this.pageNumberFilter,
        pageSize: 15,
      });
    },
    searchMaterial(measurement, query) {
      this.searchQuery = query
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.filterVariantByColor({
          name: query,
          measurementType: measurement,
          pageNumber: 1,
          pageSize: 15,
        });
      }, 500);
    },
    calculateTotalPerMeter(price, length) {
      this.totalPerMeter = price * length
    },
    currentStock(value) {
      this.clickedMaterialVariantId = value
      this.currentStockByVariant(value)
    },
    show() {
      this.image.visible = true
    },
    handleHide() {
      this.image.visible = false
      this.image.imgs.length = []
    },
    fillForm() {
      this.form = this.getWindowDetails.windowItemMaterialVariantResponseDto.map((item) => {
        const isSewingTypeMatch = this.sewingTypes.some((sewingType) => { return sewingType.materialVariantId === item.materialVariantId });
        if (item.measurementType === 'Part') {
          if (isSewingTypeMatch) {
            return {
              windowMaterialVariantId: item.windowMaterialVariantId,
              ...item,
              length: 0,
              quantity: item.quantity || 0,
              parameter: 'Shirit',
            };
          }
          return {
            windowMaterialVariantId: item.windowMaterialVariantId,
            ...item,
            length: 0,
            quantity: item.quantity || 0,
          };
        }
        if (isSewingTypeMatch) {
          return {
            windowMaterialVariantId: item.windowMaterialVariantId,
            ...item,
            length: item.quantity || item.length || 0,
            quantity: 0,
            parameter: 'Shirit',
          };
        }
        return {
          windowMaterialVariantId: item.windowMaterialVariantId,
          ...item,
          length: item.quantity || item.length || 0,
          quantity: 0,
        };
      });

      if (this.getWindowDetails) {
        const { materialVariantInStoreDtos, ...rest } = this.getWindowDetails;
        rest.from = rest.appointmentDatesDto.from ? new Date(rest.appointmentDatesDto.from) : null;
        rest.to = rest.appointmentDatesDto.to ? new Date(rest.appointmentDatesDto.to) : null;
        rest.pickUpDate = rest.pickUpDate ? new Date(rest.pickUpDate) : null;
        this.window = rest;
      } else {
        this.window = {
          name: null,
          width: 0,
          height: 0,
          sewingType: null,
          hingType: null,
          orderOtherService: {
            pattern: false,
            sewing: false,
            transport: false,
            montage: false,
          },
        };
      }
    },
    removeMaterial(index) {
      this.form.splice(index, 1);
    },
    resetMaterialName(material) {
      material.name = null;
      material.pricePerMeter = 0
      material.pricePerMeterWithDiscount = 0
      this.resetAllFiltered()
    },
    async onSubmit() {
      this.buttonSubmitClicked = true
      if (this.duplicatedMaterials) {
        return;
      }
      if (this.emptyList) {
        return;
      }
      if (this.window.width == null || this.window.width == '' || this.window.height == null || this.window.height == '') {
        return;
      }
      if (this.window.orderOtherService.montage && this.window.from == null && this.window.to == null && this.buttonSubmitClicked) {
        return;
      }
      if (!this.window.orderOtherService.montage && this.window.pickUpDate == null && this.buttonSubmitClicked) {
        return;
      }
      if (this.window.sewingType == null && this.buttonSubmitClicked) {
        return;
      }
      try {
        const objekti = {
          windowItemMaterialVariantResponseDto: this.form.map((item) => {
            return {
              windowMaterialVariantId: item.windowMaterialVariantId,
              quantity: item.length || item.quantity,
              name: item.name,
              materialVariantId: item.materialVariantId,
              measurementType: item.measurementType,
              pricePerMeter: item.pricePerMeter,
              pricePerMeterWithDiscount: item.pricePerMeterWithDiscount,
            }
          }),
          montageStart: this.window.from,
          montageEnd: this.window.to,
          pickUpDate: this.window.pickUpDate,
          name: this.window.name,
          width: this.window.width,
          height: this.window.height,
          orderOtherService: this.window.orderOtherService,
          windowId: this.windowId,
          orderId: this.orderIds,
          sewingType: this.window.sewingType,
        };
        this.$emit('editObject', objekti);
        this.onReset()
      } catch (error) {
        console.log(error);
      }
    },
    onCancel() {
      this.onReset()
    },
    onReset() {
      this.$emit('onCancelToggle');
      this.$refs.modal.hide();
      this.form = []
      this.buttonSubmitClicked = false
    },
  },

};
</script>

<style lang="scss" scoped>
 .validationClass{
    color: red;
    padding-top: 5px;
    margin-bottom: 0px;
  }
  .materialsClass{
    display:flex;
    flex-direction: column;
    gap:10px;
    margin-top: 10px;
  }
  .allMaterialsDetails{
    display:flex;
    flex-direction: column;
    gap:15px;
    margin-top: 15px;
  }
  .allClases {
  display:flex;
  gap:15px;
  flex-wrap:wrap;
  }
  .form-radio{
    display:flex;
    align-items: center;
    gap:5px;
    width:100%;
  }
  .form-radio p {
    margin-bottom: 0px;
    font-size: 20px;
  }
  .orderOtherServiceClass{
    display:flex;
    gap:3px;
    width:100%
  }
  .nameFloor{
      height: 100%;
      display: flex;
      flex-direction: column;
  }

  .iconsFloor{
      width:100%;
      display:flex;
      justify-content: space-between;
  }

.decorativeWindow{
  display:flex;
  flex-direction:column;
  gap:10px;
  border:1px solid lightgrey;
  border-radius:12px;
  padding:10px;
}

  .sectionClass{
      border: 1px solid lightgrey;
      padding: 10px;
      border-radius: 12px;
  }

  .hrClass{
      color:gray;
  }

  .iconsClass{
      display:flex;
      gap:10px;
      align-items:center;
  }
.ordersService{
  display:flex;
      gap:10px;
      align-items:center;
      width:100%
}
  .homeEdit{
      display:flex;
      gap:5px;
      justify-content: space-between;
  }

  .homeName{
      display:flex;
      gap:5px;
      width:15%;
      align-items:center;
  }

  .floorWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.objectsClass, .selectedFloor, .buttonNota {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 12px;
  padding: 15px;
  width: 140px;
  min-height: 140px;
  height: 100%;
}

.objectsClass {
  border: 1px solid lightgrey;
  color: black;
}

.selectedFloor {
  border: 1px solid red;
}

  .collapse-button {
    padding: 0;
  }
  .houseDetail{
    display:flex;
    flex-direction: column;
    gap:10px;
  }
  .buttonsCollapsed{
    display:flex;
    justify-content:flex-end;
    align-items:flex-start;
  }
  .buttonAndCollapse{
    display:flex;
    justify-content: space-between
  }
  .buttonOnly{
    display: flex;
    justify-content: space-between
  }
.active2 {
  font-weight: bold;
  color: $base-color;
}
.search-toggle {
  margin: 5px 0px;
}
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.hello{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.headline {
  padding: 5px 10px;
  background-color: $base-color;
  color: $digit-white;
  border-radius: 8px;
}

.custom-modal__wrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px 0px;

  >div {
    flex: 1;
    margin: 0px 15px;
  }
}

.flex-form {
  display: flex;
  flex-direction: column;
}

.fixed-height-modal {
  height: calc(100vh - 160px);
  overflow-y: auto;
  padding-right: 1rem;
}

.customDress-container {
  border: 3px solid lightgray;
  border-radius: 10px;
  padding: 15px;
  margin-top: 20px;
}

.customDress-container:last-child {
  margin-bottom: 20px;
}

.inside-actions {
  margin-left: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  box-shadow: none;
  padding: 2px 4px;
  border-radius: 7px;
}

.inside-actions:hover {
  background-color: #242F6E;
  color: #fff;
}

.f {
  border-color: red !important;
}
.front-body {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 50px;
  padding-bottom: 20px;
  img {
    width: 100%;
  }
  .betweenCol{
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .input{
    max-width: 100%;
    height: 51px;
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  .toraks {
    display: flex;
    position: absolute;
    top: 106px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .rrethiGjoksit {
    display: flex;
    position: absolute;
    top: 135px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .rrethiNenGjoksit {
    display: flex;
    position: absolute;
    top: 165px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .beli {
    display: flex;
    position: absolute;
    top: 200px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .vithet {
    display: flex;
    position: absolute;
    top: 263px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .rrethiKembes {
    display: flex;
    position: absolute;
    bottom: 222px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .rrethiGjurit {
    display: flex;
    position: absolute;
    bottom: 148px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .zoguKembeve {
    display: flex;
    position: absolute;
    bottom: 38px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .reniaGjoksit {
    display: flex;
    position: absolute;
    top: 85px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .nderGjoks {
    display: flex;
    position: absolute;
    top: 158px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .trupiPara {
    display: flex;
    position: absolute;
    top: 200px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .distancaGjoksit {
    display: flex;
    position: absolute;
    top: 128px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .gjatesia1 {
    display: flex;
    position: absolute;
    bottom: 222px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .gjatesia2 {
    display: flex;
    position: absolute;
    bottom: 127px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .gjatesia3 {
    display: flex;
    position: absolute;
    bottom: 68px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .gjatesia4 {
    display: flex;
    position: absolute;
    bottom: 8px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
}
.btn-con {
  display: flex;
}
.back-body {
  position: relative;
  img {
    width: 100%;
  }
  .rrethiMenges {
    display: flex;
    position: absolute;
    top: 126px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .brryli {
    display: flex;
    position: absolute;
    top: 156px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .kyçi {
    display: flex;
    position: absolute;
    top: 192px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .rrethiQafes {
    display: flex;
    position: absolute;
    top: 60px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .trupiPrapa {
    display: flex;
    position: absolute;
    top: 202px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .supet {
    display: flex;
    position: absolute;
    top: 97px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .shpina {
    display: flex;
    position: absolute;
    top: 135px;
    left: 100px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .reniaVitheve {
    display: flex;
    position: absolute;
    bottom: 253px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
  .gjatesiaMenges {
    display: flex;
    position: absolute;
    top: 95px;
    right: 110px;
    max-width: 180px;
    width: 100%;
    height: 25px;
  }
}
@media only screen and (max-width: 1200px){
  .front-body {
  position: relative;
  .toraks {
    top: 65px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .rrethiGjoksit {
    position: absolute;
    top: 95px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .rrethiNenGjoksit {
    position: absolute;
    top: 125px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .beli {
    position: absolute;
    top: 155px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .vithet {
    position: absolute;
    top:249px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .rrethiKembes {
    position: absolute;
    bottom: 65px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .rrethiGjurit {
    position: absolute;
    bottom: 35px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .zoguKembeve {
    position: absolute;
    bottom: 5px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .reniaGjoksit {
    position: absolute;
    top: 65px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .nderGjoks {
    position: absolute;
    top: 125px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .trupiPara {
    position: absolute;
    top: 155px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .distancaGjoksit {
    position: absolute;
    top: 95px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .gjatesia1 {
    position: absolute;
    bottom: 95px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .gjatesia2 {
    position: absolute;
    bottom: 65px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .gjatesia3 {
    position: absolute;
    bottom: 35px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .gjatesia4 {
    position: absolute;
    bottom: 5px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
}
.btn-con {
  display: flex;
  button {
    border: none;
    background-color: #6c757d;;
    color: $digit-white;
    border-radius: 0.25rem;
    padding: 3px 12px;
  }
}
.back-body {
  position: relative;
  img {
    width: 100%;
  }
  .rrethiMenges {
    position: absolute;
    top: 126px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .brryli {
    position: absolute;
    top: 156px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .kyçi {
    position: absolute;
    top: 192px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .rrethiQafes {
    position: absolute;
    top: 70px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .trupiPrapa {
    position: absolute;
    top: 215px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .supet {
    position: absolute;
    top: 97px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .shpina {
    position: absolute;
    top: 70px;
    left: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .reniaVitheve {
    position: absolute;
    bottom: 100px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
  .gjatesiaMenges {
    position: absolute;
    top: 98px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
  }
}
}
input[type='radio']:checked:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -3px;
        left: -1px;
        position: relative;
        background-color: $base-color ;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
    }
@media only screen and (max-width: 800px) {
  .front-body {
    .vithet {
      position: absolute;
    top: 195px;
    right: 1px;
    max-width: 150px;
    width: 100%;
    height: 25px;
    }
  }
}

</style>
