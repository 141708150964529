<template>
  <section class="CalendarSection">
    <div style="border: 1px solid #DADCE0 ;">
      <div class="types_container">
        <div
          class="types"
        >
          <div>
            <button
              v-b-modal.create-appointment
              class="button"
              style="margin-right: 10px"
              @click="callUsers"
            >
              {{ $t('CreateAppointment') }}
            </button>
          </div>
          <div
            :class="{ 'selected-filter': selectedFilters.includes('Measurement') }"
            style="
            font-weight: bold;
            background-color: #BFC6FF;
            color: #131C6D;
            text-align: center;
            flex: 1;
            margin-right: 10px;
            display: flex;
            border-radius: 5px;
            align-items: center;
            justify-content: center;
            cursor: pointer;

          "
            @click="setFilter('Measurement')"
          >
            {{ $t("Measurements") }}
          </div>
          <div
            :class="{ 'selected-filter': selectedFilters.includes('Montage') }"
            style="
            font-weight: bold;
            background-color: #FF6666;
            color: white;
            text-align: center;
            flex: 1;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          "
            @click="setFilter('Montage')"
          >
            {{ $t("Montage") }}
          </div>
        </div>
        <div style="display:flex; align-items: center; margin-right: 10px; padding-top: 20px;">
          <vue-select
            id="input-5"
            v-model="selectedAvailability"
            :options="availability"
            :reduce="(e) => e.value"
            :clearable="true"
            label="label"
            :placeholder="$t('SelectState')"
            style="margin-left: 10px; width: 200px"
          />
          <vue-select
            id="input-5"
            v-model="storeId"
            :options="getAllStoresDropdown"
            :reduce="(e) => e.storeId"
            :clearable="true"
            label="storeName"
            :placeholder="$t('SelectStore')"
            style="margin-left: 10px; width: 200px"
          />
        </div>
      </div>
      <div
        class="datepickerInternal"
        style="display: flex; margin: 15px;border: 1px solid #DADCE0; border-radius: 6px;"
      >

        <div class="datesInfo">
          <b-form-group class="today">
            <template #label>
              <strong><img src="../assets/images/calendarii.svg"><span style="color: #333333;padding-left: 5px;">{{ $t("Today") }}</span></strong>
            </template>
            <b-form-group
              v-for="employee in today"
              :key="employee"
              style="width:100%;padding-top: 10px;font-weight: bold;"
            >
              <p
                :style="{ color: getRandomColor() }"
                style="font-size: 12px;margin-bottom: 0px;"
              >
                <span
                  class="circle-before"
                  :style="{ background: getRandomColorSpan(),borderRadius: '50%' }"
                />
                <span>
                  {{ employee.userUsernameDto.map(user => user.name).join(', ') }}
                </span> , <span style="color:black;">{{ employee.appointmentType }},</span>
                {{ modify(employee.from) }} to {{ modify(employee.to) }}
              </p>
            </b-form-group>
            <p v-if="today.length == 0">
              {{ $t('NoEmployeeOnVacation') }}
            </p>
          </b-form-group>
          <!-- <b-form-group
            v-if="nextDayClicked"
            class="today"
          >
            <template #label>
              <strong><img src="../assets/images/calendarii.svg"><span style="color: #333333;padding-left: 5px;">{{ $t("Week") }}</span></strong>
            </template>
            <b-form-group
              v-for="employee in week"
              :key="employee"
              style="width:100%;padding-top: 10px;font-weight: bold;"
            >
              <p
                :style="{ color: getRandomColor() }"
                style="font-size: 12px;margin-bottom: 0px;"
              >


                <span
                  class="circle-before"
                  :style="{ background: getRandomColorSpan(),borderRadius: '50%' }"
                />
                <span>
                  {{ employee.userUsernameDto.map(user => user.name).join(', ') }}
                </span> ,<span style="color:black;">{{ employee.appointmentType }}</span>, from {{ modify(employee.from) }} to {{ modify(employee.to) }}
              </p>
            </b-form-group>
            <p v-if="week.length == 0">
              {{ $t('NoVacationThisWeek') }}
            </p>
          </b-form-group>
          <b-form-group
            v-if="nextMonthClicked"
            class="today"
          >
            <template #label>
              <strong><img src="../assets/images/calendarii.svg"><span style="color: #333333;padding-left: 5px;">{{ $t("Month") }}</span></strong>
            </template>
            <b-form-group
              v-for="employee in month"
              :key="employee"
              style="width:100%;padding-top: 10px;font-weight: bold;"
            >
              <p
                :style="{ color: getRandomColor() }"
                style="font-size: 12px;margin-bottom: 0px;"
              >


                <span
                  class="circle-before"
                  :style="{ background: getRandomColorSpan(),borderRadius: '50%' }"
                />
                <span>
                  {{ employee.userUsernameDto.map(user => user.name).join(', ') }}
                </span> ,<span style="color:black;">{{ employee.appointmentType }}</span>,  from {{ modify(employee.from) }} to {{ modify(employee.to) }}
              </p>
            </b-form-group>
            <p v-if="month.length == 0">
              {{ $t('NoVacationThisMonth') }}
            </p>
          </b-form-group>
          <b-form-group
            v-if="nextYearClicked"
            class="today"
          >
            <template #label>
              <strong><img src="../assets/images/calendarii.svg"><span style="color: #333333;padding-left: 5px;">{{ $t("Year") }}</span></strong>
            </template>
            <b-form-group
              v-for="employee in allVacations"
              :key="employee"
              style="width:100%;padding-top: 10px;font-weight: bold;"
            >
              <p
                :style="{ color: getRandomColor() }"
                style="font-size: 12px;margin-bottom: 0px;"
              >


                <span
                  class="circle-before"
                  :style="{ background: getRandomColorSpan(),borderRadius: '50%' }"
                />
                <span>
                  {{ employee.userUsernameDto.map(user => user.name).join(', ') }}
                </span> ,<span style="color:black;">{{ employee.appointmentType }}</span>, from {{ modify(employee.from) }} to {{ modify(employee.to) }}
              </p>
            </b-form-group>
            <p v-if="allVacations.length == 0">
              {{ $t('NoVacationThisYear') }}
            </p>
          </b-form-group> -->
          <b-form-group
            class="today"
          >
            <template #label>
              <strong><img src="../assets/images/calendarii.svg"><span style="color: #333333;padding-left: 5px;">{{ selectedAvailability == 'Created' ? $t('Active') : selectedAvailability == 'Cancelled' ? $t('Cancelled') : $t('All') }}</span></strong>
            </template>
            <b-form-group
              v-for="employee in filteredMontageRequest"
              :key="employee.appointmentId"
              style="width:100%;padding-top: 10px;font-weight: bold;"
            >
              <!-- v-b-modal.create-appointment-montage -->
              <p
                :style="{ color: getRandomColor() }"
                style="font-size: 12px;margin-bottom: 0px;"
                @click="callClient(employee.clientId, employee)"
              >


                <span
                  class="circle-before"
                  :style="{ background: getRandomColorSpan(),borderRadius: '50%' }"
                /> {{ employee.clientFullNameDto?.fullName }} from {{ modify(employee.montageAT) }}
              </p>
            </b-form-group>
          </b-form-group>
        </div>
        <div
          class="custom-calendar"
          style="width: 100%;"
        >
          <FullCalendar :options="calendarOptions" />
        </div>
      </div>
    </div>
    <CreateAppointmentClient
      :next-year-clicked="nextYearClicked"
      :next-month-clicked="nextMonthClicked"
      :next-day-clicked="nextDayClicked"
      :title-of-calendar="titleOfCalendar"
    />
    <CreateMontageAppointment
      :client-id="clientId"
      :object-client="objectClient"
      :next-year-clicked="nextYearClicked"
      :next-month-clicked="nextMonthClicked"
      :next-day-clicked="nextDayClicked"
      :title-of-calendar="titleOfCalendar"
    />
    <EditAppointmentClient
      :client-id="selectedEvent?.clientId"
      :object-client="selectedEvent"
      :next-year-clicked="nextYearClicked"
      :next-month-clicked="nextMonthClicked"
      :next-day-clicked="nextDayClicked"
      :title-of-calendar="titleOfCalendar"
      :appointment-id-client="appointmentIdClient"
      @updateEventss="eventUpdate"
    />
    <CancelAppointment
      :appointment-id-client="appointmentIdClient"
      @cancelApp="cancelAppointment"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
// import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import CreateAppointmentClient from '../modals/CreateAppointmentClient.vue'
import CreateMontageAppointment from '../modals/CreateMontageAppointment.vue'
import EditAppointmentClient from '../modals/EditAppointmentClient.vue'
import CancelAppointment from '../modals/CancelAppointment.vue'
import { client } from '../domainConfig';


export default {
  components: {
    FullCalendar,
    CreateAppointmentClient,
    CreateMontageAppointment,
    EditAppointmentClient,
    CancelAppointment,
    // DatePicker,
  },
  data() {
    return {
      appointmentIdClient: null,
      clientId: null,
      objectClient: null,
      selectedDate: null,
      titleOfCalendar: moment().format('YYYY-MM-DD'),
      nextDayClicked: false,
      nextMonthClicked: true,
      nextYearClicked: false,
      button: false,
      currentYear: moment().format('YYYY-MM-DD'),
      selectedFilters: [],
      currentFilter: 'All',
      checkStatus: false,
      isSingleDayEvent: false,
      saturdayWorkStatus: false,
      showModal: false,
      client: client.clientName,
      textOfCalendar: '',
      storeId: null,
      selectedAvailability: null,
      availability: [
        { value: 'Created', label: this.$t('Active') },
        { value: 'Cancelled', label: this.$t('Canceled') },
      ],
      calendarOptions: {
        firstDay: 1,
        eventContent(info) {
          console.log('info.event.extendedProps', info.event)
          const isCanceled = info.event.extendedProps.clientData && info.event.extendedProps.clientData.state === 'Cancelled';
          const color = info.event.backgroundColor;
          return {
            html: `
              <div style="width:100%;">
          <div class="tooltip-container" style="width:100%;background-color: ${color} !important;padding:10px;">
            <div class="tooltip-trigger">
              <div class="span-button">
                <span>Client: ${info.event.extendedProps.title1} </span>
                ${!isCanceled ? `<button
                  onClick="openCancelModal(event, '${info.event.extendedProps.clientData.appointmentId}')"
                  style="background: white; border: none; color: red; margin-left: 15px; border-radius: 5px">
                  X
                </button>` : ''}
              </div>
                <span>Store: ${info.event.extendedProps.clientData.storeName}</span>
              <hr style="margin: 0px; margin-top:3px; margin-bottom:3px;"> <span>Employees : ${info.event.title}, ${info.event.extendedProps.description}</span></div>
            <div class="tooltip">${info.event.extendedProps.description}</div>
          </div>
        </div>
        <style>
        .tooltip-container {
            position: relative;
            display: inline-block;
          }
          .tooltip-trigger {
            color: white;
            font-size: 15px;
            font-weight: bold;
            white-space: normal;
            cursor: pointer;
          }
          .span-button {
            display: flex;
            justify-content: space-between;
          }
          .tooltip {
            visibility: hidden;
            width: 100px;
            background-color: #333;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 5px;
            position: absolute;
            z-index: 9999;
            bottom: 125%;
            left: 70%;
            transform: translateX(-50%);
            opacity: 0;
            transition: opacity 0.3s;
          }
          .tooltip-container:hover .tooltip {
            visibility: visible;
            opacity: 1;
          }
        </style>
      `,
          };
        },
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        views: {
          week: {
            buttonText: `${this.$t('Week')}`,
            type: 'dayGridWeek',
          },
          day: {
            buttonText: `${this.$t('Daily')}`,
            type: 'dayGridDay',
          },
          month: {
            buttonText: `${this.$t('Month')}`,
            type: 'dayGridMonth',
          },
          year: {
            buttonText: `${this.$t('Year')}`,
            type: 'dayGridYear',
          },
        },
        headerToolbar: {
          left: 'prev,next',
          right: 'week month year',
          center: 'title',
        },
        locale: 'en', // Set the locale to German (de)
        events: [], // Initialize with an empty array
        eventClick: (info) => {
        // Prevent the default behavior
          info.jsEvent.preventDefault();
          this.loadRegularEmployeesId({
            storeId: info.event._def.extendedProps.clientData.storeId.storeId,
          });
          this.loadAllStoresByDropdown({
            amountOfRecords: 100,
          })
          this.$bvModal.show('edit-appointment');
          this.selectedEvent = info.event._def.extendedProps.clientData;
          this.appointmentIdClient = info.event._def.extendedProps.clientData.appointmentId
        },
      },
      selectedEvent: null,
    };
  },
  computed: {
    ...mapGetters(['getMontageAppointment', 'getAppointmentsWithoutId', 'getIsLoading', 'getSaturdayWork', 'getAllStoresDropdown']),
    today() {
      const today = moment().format('YYYY-MM-DD');
      return this.getMontageAppointment.filter((vacation) => { return moment(vacation.from).format('YYYY-MM-DD') === today });
    },
    month() {
      return this.getMontageAppointment.filter((vacation) => { return moment(vacation.from).format('YYYY-MM') === moment(this.titleOfCalendar).format('YYYY-MM') });
    },
    allVacations() {
      return this.getMontageAppointment;
    },
    montageRequest() {
      return this.getAppointmentsWithoutId;
    },
    filteredMontageRequest() {
      if (!this.selectedAvailability) {
        return this.getMontageAppointment;
      }
      return this.getMontageAppointment.filter(
        (appointment) => { return appointment.state === this.selectedAvailability },
      );
    },
    week() {
      const startOfWeek = moment(this.titleOfCalendar).startOf('day');
      const endOfWeek = moment(startOfWeek).add(7, 'days').endOf('day');

      return this.getMontageAppointment.filter((vacation) => {
        const vacationDate = moment(vacation.from);
        return vacationDate.isBetween(startOfWeek, endOfWeek, null, '[)');
      });
    },
  },
  watch: {
    selectedAvailability() {
      this.updateEvents();
    },
    storeId(value) {
      this.loadMontageAppointments({
        start: moment(this.currentYear).startOf('month').format('YYYY-MM-DD'),
        end: moment(this.currentYear).endOf('month').format('YYYY-MM-DD'),
        storeId: value,
      }).then(() => {
        this.updateEvents();
      });
    },
    getMontageAppointment: {
      handler() {
        this.updateEvents();
      },
      deep: true,
    },
  },
  mounted() {
    this.selectedDate = this.currentYear
    this.loadMontageAppointmentsWithoutId()
    this.loadMontageAppointments({
      start: moment(this.currentYear).startOf('month').format('YYYY-MM-DD'),
      end: moment(this.currentYear).endOf('month').format('YYYY-MM-DD'),
      storeId: this.storeId,
    }).then(() => {
      this.updateEvents();
    });

    const prevButton = document.querySelector('.fc-prev-button');
    const nextButton = document.querySelector('.fc-next-button');
    const nextDay = document.querySelector('.fc-week-button');
    const nextMonth = document.querySelector('.fc-month-button');
    const nextYear = document.querySelector('.fc-year-button');


    if (prevButton) {
      prevButton.addEventListener('click', () => {
        if (this.nextDayClicked) {
          this.handlePrevClickDay();
        } else if (this.nextMonthClicked) {
          this.handlePrevClickMonth();
        } else if (this.nextYearClicked) {
          this.handlePrevClickYear();
        } else {
          this.handlePrevClick();
        }
      });
    }

    if (nextButton) {
      nextButton.addEventListener('click', () => {
        if (this.nextDayClicked) {
          this.handleNextClickDay();
        } else if (this.nextMonthClicked) {
          this.handleNextClickMonth();
        } else if (this.nextYearClicked) {
          this.handleNextClickYear();
        } else {
          this.handleNextClick();
        }
      });
    }

    if (nextDay) {
      nextDay.addEventListener('click', () => {
        const calendarTitleElement = document.querySelector('.fc-toolbar-title');
        this.nextDayClicked = true;
        this.nextMonthClicked = false;
        this.nextYearClicked = false
        if (calendarTitleElement) {
          this.titleOfCalendar = calendarTitleElement.textContent.trim();
          const formattedDate = moment(this.titleOfCalendar, 'MMM D, YYYY').format('YYYY-MM-DD');
          this.titleOfCalendar = formattedDate;
          this.loadMontageAppointments({
            start: moment(this.titleOfCalendar).startOf('day').format('YYYY-MM-DD'),
            end: moment(this.titleOfCalendar).add(7, 'days').format('YYYY-MM-DD'),
            storeId: this.storeId,
          })
        }
      });
    }
    if (nextMonth) {
      nextMonth.addEventListener('click', () => {
        this.nextMonthClicked = true;
        this.nextDayClicked = false;
        this.nextYearClicked = false
        const calendarTitleElement = document.querySelector('.fc-toolbar-title');

        if (calendarTitleElement) {
          this.titleOfCalendar = calendarTitleElement.textContent.trim();
          const formattedDate = moment(this.titleOfCalendar, 'MMM D, YYYY').format('YYYY-MM-01');
          this.titleOfCalendar = formattedDate;
          this.loadMontageAppointments({
            start: moment(this.titleOfCalendar).startOf('month').format('YYYY-MM-DD'),
            end: moment(this.titleOfCalendar).endOf('month').format('YYYY-MM-DD'),
            storeId: this.storeId,
          })
        }
      });
    }
    if (nextYear) {
      nextYear.addEventListener('click', () => {
        this.nextYearClicked = true;
        this.nextDayClicked = false;
        this.nextMonthClicked = false;
        const calendarTitleElement = document.querySelector('.fc-toolbar-title');

        if (calendarTitleElement) {
          this.titleOfCalendar = calendarTitleElement.textContent.trim();
          const formattedDate = moment(this.titleOfCalendar, 'YYYY').format('YYYY-01-01');
          this.titleOfCalendar = formattedDate;
          this.loadMontageAppointments({
            start: moment(this.titleOfCalendar).startOf('year').format('YYYY-MM-DD'),
            end: moment(this.titleOfCalendar).add(1, 'year').format('YYYY-MM-DD'),
            storeId: this.storeId,
          })
        }
      });
    }
    window.openCancelModal = (event, appointmentId) => {
      this.openCancelModal(event, appointmentId);
    };
  },
  methods: {
    ...mapActions([
      'loadCalculation',
      'changeLoadingtoTrue',
      'loadSaturdayWork',
      'editSaturdayWork',
      'addSaturdayWork',
      'loadMontageAppointments',
      'activeInactiveUsers',
      'loadAllStoresByDropdown',
      'getLoggedInUser',
      'getClientById',
      'loadMontageAppointmentsWithoutId',
      'cancelAppointments',
      'loadRegularEmployeesId',
    ]),
    eventUpdate() {
      this.updateEvents()
    },
    openCancelModal(event, appointmentId) {
      if (event) {
        event.stopPropagation();
      }
      this.appointmentIdClient = appointmentId;
      this.$bvModal.show('cancel-appointment');
    },
    cancelAppointment(value) {
      console.log('appointmentId', value)
      this.cancelAppointments({
        appointmentId: value,
        successCallback: () => {
          this.loadMontageAppointments({
            start: moment(this.currentYear).startOf('month').format('YYYY-MM-DD'),
            end: moment(this.currentYear).endOf('month').format('YYYY-MM-DD'),
            storeId: this.storeId,
          });
        },
      })
    },
    callClient(value, object) {
      this.clientId = value
      this.objectClient = object
      this.activeInactiveUsers({
        pageNumber: 1,
        pageSize: 50,
        status: true,
        userId: null,
      });
    },
    callUsers() {
      this.loadRegularEmployeesId({ storeId: this.getLoggedInUser.storeId })
      // this.activeInactiveUsers({
      //   pageNumber: 1,
      //   pageSize: 50,
      //   status: true,
      //   userId: null,
      // });
      this.loadAllStoresByDropdown({
        amountOfRecords: 100,
      })
    },
    modify(value) {
      return moment(value).format('YYYY-MM-DD HH:mm:ss')
    },
    getRandomColor() {
      const background = 'grey';
      return background;
    },
    getRandomColorSpan() {
      const letters = '0123456789ABCDEF';
      let background = '#';
      for (let i = 0; i < 6; i++) {
        background += letters[Math.floor(Math.random() * 16)];
      }
      return background;
    },
    handlePrevClickDay() {
      const previousDay = moment(this.titleOfCalendar).subtract(7, 'days').format('YYYY-MM-DD');
      const previousDay1 = moment(previousDay).add(7, 'days').format('YYYY-MM-DD');

      this.loadMontageAppointments({
        start: previousDay,
        end: previousDay1,
        storeId: this.storeId,
      }).then(() => {
      });
      this.titleOfCalendar = moment(this.titleOfCalendar).subtract(7, 'days').format('YYYY-MM-DD');
    },
    handlePrevClickMonth() {
      const previousMonthStart = moment(this.titleOfCalendar).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
      const previousMonthEnd = moment(this.titleOfCalendar).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');

      this.loadMontageAppointments({
        start: previousMonthStart,
        end: previousMonthEnd,
        storeId: this.storeId,
      })

      this.titleOfCalendar = moment(this.titleOfCalendar).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
    },

    handlePrevClickYear() {
      const previousYear = moment(this.titleOfCalendar).subtract(1, 'year').format('YYYY-01-01');
      const previousYear1 = moment(this.titleOfCalendar).subtract(1, 'year').format('YYYY-12-31');

      this.loadMontageAppointments({
        start: previousYear,
        end: previousYear1,
        storeId: this.storeId,
      }).then(() => {
      });
      this.titleOfCalendar = moment(this.titleOfCalendar).subtract(1, 'year').format('YYYY-01-01');
    },
    handleNextClickYear() {
      const previousYear = moment(this.titleOfCalendar).add(1, 'year').format('YYYY-01-01');
      const previousYear1 = moment(this.titleOfCalendar).add(1, 'year').format('YYYY-12-01');

      this.loadMontageAppointments({
        start: previousYear,
        end: previousYear1,
        storeId: this.storeId,
      }).then(() => {
      });
      this.titleOfCalendar = moment(this.titleOfCalendar).add(1, 'year').format('YYYY-01-01');
    },
    handleNextClickMonth() {
      const previousYear = moment(this.titleOfCalendar).add(1, 'months').startOf('month').format('YYYY-MM-DD');
      const previousYear1 = moment(this.titleOfCalendar).add(1, 'months').endOf('month').format('YYYY-MM-DD');

      this.loadMontageAppointments({
        start: previousYear,
        end: previousYear1,
        storeId: this.storeId,
      }).then(() => {
      });
      this.titleOfCalendar = moment(this.titleOfCalendar).add(1, 'months').format('YYYY-MM-01');
    },
    handlePrevClick() {
      const previousYear = moment(this.currentYear).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
      const previousYear1 = moment(this.currentYear).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');

      this.loadMontageAppointments({
        start: previousYear,
        end: previousYear1,
        storeId: this.storeId,
      }).then(() => {
      });
      this.currentYear = moment(this.currentYear).subtract(1, 'months').format('YYYY-MM-01');
    },
    handleNextClick() {
      const previousYear = moment(this.currentYear).add(1, 'months').startOf('month').format('YYYY-MM-DD');
      const previousYear1 = moment(this.currentYear).add(1, 'months').endOf('month').format('YYYY-MM-DD');

      this.loadMontageAppointments({
        start: previousYear,
        end: previousYear1,
        storeId: this.storeId,
      }).then(() => {
      });
      this.currentYear = moment(this.currentYear).add(1, 'months').format('YYYY-MM-01');
    },
    handleNextClickDay() {
      const previousYear = moment(this.titleOfCalendar).add(7, 'days').format('YYYY-MM-DD');
      const previousYear1 = moment(previousYear).add(7, 'days').format('YYYY-MM-DD');

      this.loadMontageAppointments({
        start: previousYear,
        end: previousYear1,
        storeId: this.storeId,
      }).then(() => {
      });
      this.titleOfCalendar = moment(this.titleOfCalendar).add(7, 'days').format('YYYY-MM-DD');
    },
    setFilter(filter) {
      if (this.selectedFilters.includes(filter)) {
        const index = this.selectedFilters.indexOf(filter);
        this.selectedFilters.splice(index, 1);
      } else {
        this.selectedFilters.push(filter);
      }
      this.updateEvents();
    },
    openModal() {
      this.showModal = true;
    },
    onCancel() {
      this.showModal = false;
    },
    updateEvents() {
      const vacationData = this.getMontageAppointment;
      let events = [];

      const filteredData = vacationData.filter((vacation) => {
        const matchesAvailability = this.selectedAvailability
          ? vacation.state === this.selectedAvailability
          : true;

        const matchesFilters = this.selectedFilters.length === 0 || !this.selectedFilters.includes(vacation.appointmentType);

        const matchesStore = this.storeId
          ? vacation.storeId.toLowerCase() === this.storeId.toLowerCase()
          : true;

        return matchesAvailability && matchesFilters && matchesStore;
      });

      events = filteredData.map((vacation) => {
        const fromDate = new Date(vacation.from);
        const toDate = new Date(vacation.to);
        const formattedFrom = {
          hour: String(fromDate.getHours()).padStart(2, '0'),
          minute: String(fromDate.getMinutes()).padStart(2, '0'),
          second: String(fromDate.getSeconds()).padStart(2, '0'),
        };

        const formattedTo = {
          hour: String(toDate.getHours()).padStart(2, '0'),
          minute: String(toDate.getMinutes()).padStart(2, '0'),
          second: String(toDate.getSeconds()).padStart(2, '0'),
        };

        let appointmentType = '';
        let eventBackgroundColor = '';
        const isCanceled = vacation.state === 'Cancelled';

        if (vacation.appointmentType === 'Montage') {
          appointmentType = `${this.$t('Montage')}, ${formattedFrom.hour}:${formattedFrom.minute} - ${formattedTo.hour}:${formattedTo.minute}`;
          eventBackgroundColor = 'rgb(255, 102, 102)';
        } else if (vacation.appointmentType === 'Measurement') {
          appointmentType = `${this.$t('Measurement')}, ${formattedFrom.hour}:${formattedFrom.minute} - ${formattedTo.hour}:${formattedTo.minute}`;
          eventBackgroundColor = 'rgb(191, 198, 255)';
        }

        const eventClass = isCanceled ? 'cancelled-event' : '';

        const description = appointmentType;
        const userNames = vacation.userUsernameDto.map((user) => { return user.name }).join(', ');
        const title = `${userNames}`;
        const clientNames = vacation.clientFullNameDto?.fullName;
        const title1 = `${clientNames}`;
        const clientData = {
          clientId: vacation.clientFullNameDto?.clientId,
          from: vacation.from,
          to: vacation.to,
          state: vacation.state,
          storeName: vacation.storeName,
          userIds: vacation.userUsernameDto.map((user) => { return user.userId }),
          appointmentType: vacation.appointmentType,
          appointmentId: vacation.appointmentId,
          storeId: {
            storeId: vacation?.storeId,
            storeName: vacation?.storeName,
          },
        };

        return {
          description,
          title,
          title1,
          start: vacation.from,
          end: vacation.to,
          color: eventBackgroundColor,
          classNames: [eventClass],
          clientData,
        };
      });

      this.calendarOptions.events = events;
    },
  },
};
</script>

<style>
.mx-icon-left{
  padding: 20px;
}
.mx-icon-right{
  padding: 20px;
}
.mx-btn-icon-double-left{
  display: none !important;
}
.mx-btn-icon-double-right{
  display: none !important;
}
.cancelled-event {
  opacity: 0.5 !important;
}
.today {
  padding-top: 20px;
  padding-left: 10px;
}
.fc .fc-view-harness-active > .fc-view {
    inset: 0px;
    position: absolute;
    z-index: 0;
}
.custom-calendar .fc-daygrid-day {
    background-color: #fff;
    border: 0px solid #eee !important;
    width: calc(100% / 7);
    height: 80px;
}
.fc .fc-daygrid-day-frame {
    min-height: 100%;
    position: relative;
    border-right: 1px solid #DADCE0 !important ;
    border-bottom: 1px solid #DADCE0 !important ;
}
.fc-prev-button{
  background: white!important;
  color:#FF274F !important;
  border:1px solid #FF274F !important;

}
.fc-day-today{
  background: white !important;
}
.fc .fc-daygrid-day.fc-day-today {
    background-color: var(--fc-today-bg-color);
    border: 1px solid #FF274F !important;
}
.fc-next-button{
  background: white!important;
  color:#FF274F !important;
  border:1px solid #FF274F !important;

}
.fc-week-button{
  background: white!important;
  color:#FF274F !important;
  border:1px solid #FF274F !important;

}
.fc-month-button{
  background: white!important;
  color:#FF274F !important;
  border:1px solid #FF274F !important;
}
.fc-year-button{
  background: white!important;
  color:#FF274F !important;
  border:1px solid #FF274F !important;
}
.fc-col-header-cell{
  background: white !important;
  color: white !important;
}
.fc-col-header-cell-cushion{
  color: black !important;
}
.circle-before::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  background-color: inherit;
}
.mx-calendar {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 255px;
    padding: 6px 12px;
}
.mx-table{
  /* pointer-events: none; */
}
/* .datepickerInternal .datesInfo .mx-datepicker-popup {
  position: absolute !important;
    margin-top: 1px;
    margin-bottom: 1px;
    box-shadow: none !important;
    z-index: 0 !important;
    top: 260px !important;
    left: 175px !important;
    border: 0px solid black !important;
} */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 23px;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    margin-right: 2px;
    padding: 5px;
    color:white;
}
.mx-calendar-content .cell {
    cursor: pointer;
    color: black;
}
.mx-btn-text {
    border: 0;
    padding: 0 4px;
    text-align: left;
    line-height: inherit;
    color: black;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(191, 203, 217);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #242f6e;
}

input:focus + .slider {
  box-shadow: none;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.custom-calendar {
  /* max-width: 1300px; */
  /* Custom styles for the FullCalendar component */
  /* background-color: #f2f2f2; */
  border: 0px solid #ccc;
  box-shadow: none;
  padding: 20px;
  margin: 0 18px;
  margin-top: 0px;
  margin-left: 0px;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-start, .fc-direction-rtl .fc-daygrid-event.fc-event-end {
    margin-left: 2px;
    padding: 5px;
}
a {
  color: black;
  text-decoration: none;
}
.fc-day-header {
  text-decoration: none; /* Remove underline from day of the week headers */
}

.fc-day-number {
  text-decoration: none; /* Remove underline from numbers */
}

.fc-daygrid-day-events .fc-event-main {
  /* Set event box background color to light grey */
  padding: 0px !important;
  font-size: 14px !important
}

.CalendarSection {
    padding: 54px 10px 15px 102px;
    padding-top: 100px;
    background-color: transparent;
}

.custom-calendar .fc-daygrid-day {
  background-color: #fff;
  border: 1px solid #eee;
  width: calc(100% / 7); /* Divide the width evenly between 7 days */
  height: 80px;
}

.custom-calendar .fc-daygrid-day-number {
  color: #333;
  font-weight: bold;
  font-size: 14px;
}

  .custom-calendar .fc-event-main {
    /* background-color: lightgrey; */
    color: #fff;
    border-radius: 3px;
    font-size: 12px;
  }
  .types_container {
    display: flex;
    justify-content: space-between;
  }
  .types{
    display: flex;
    flex-direction: row;
    width: 40%;
    text-align: center;
    justify-content: center !important;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .form-controll {
    width: 90%;
    border: 0px solid #242f6e;
    margin-top: 23px;
    margin-left: 12px;
}
/* .custom-calendar .fc-event-main:hover {
    background-color: transparent;
    cursor: pointer;
  } */
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: 2em;
  position: relative;
  margin-top: 35px;
}

.filter {
  font-weight: 400;
  color: black;
  text-align: center;
  flex: 1;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.selected-filter {
  position: relative;
}

.selected-filter::before {
  content: '';
  position: absolute;
  bottom: 50%;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: white;
}

.datesInfo {
  width: 20%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #DADCE0;
  height: 100vh;
  overflow-y:overlay;
}

@media screen and (min-width: 1500px) and (max-width: 1750px) {
  .datesInfo {
      width: 20%;
    }
    .mx-calendar {
        box-sizing: border-box;
        width: 200px;
        padding: 0px 0px;
    }
    .datepickerInternal .datesInfo .mx-datepicker-popup {
      top: 280px !important;
      box-shadow: none !important;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .CalendarSection{
      padding: 80px 25px 15px 100px;
    }
    .datesInfo {
      width: 23%;
    }
    .datepickerInternal .datesInfo .mx-datepicker-popup {
      position: absolute !important;
      margin-top: 1px;
      margin-bottom: 1px;
      box-shadow: none !important;
      z-index: 0 !important;
      top: 255px !important;
      left: 118px !important;
      border: 0px solid black !important;
    }
    .mx-calendar {
        box-sizing: border-box;
        width: 189px;
        padding: 0px 0px;
    }
    .fc-view-harness {
      height: 840px !important;
    }
}

@media screen and (max-width: 1041px) {
  .CalendarSection {
    padding: 100px 30px 15px 30px;
  }
}

  @media screen and (max-width: 1200px){
    .CalendarSection{
      padding: 60px 30px 15px 30px;
    }

    .fc-view-harness {
      height: 840px !important;
    }

    .datesInfo {
      width: 23%;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #DADCE0;
    }

    /* .today {
      padding-top: 40px;
      padding-left: 10px;
    } */

    .types{
      width: 100%;
    }
    .datepickerInternal .datesInfo .mx-datepicker-popup {
      position: absolute !important;
      margin-top: 1px;
      margin-bottom: 1px;
      box-shadow: none !important;
      z-index: 0 !important;
      top: 245px !important;
      left: 55px !important;
      border: 0px solid black !important;
    }
    .mx-calendar {
        box-sizing: border-box;
        width: 152px;
        padding: 0px 0px;
    }
  }

  @media screen and (min-width: 1300px) and (max-width:1400px){
    .types{
      width: 82%;
    }
  }

  @media screen and (min-width:1400px) and (max-width: 1735px){
    .types{
      width: 84%;
    }
  }
  </style>
