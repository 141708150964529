<template>
  <div>
    <b-modal
      id="create-appointment-montage"
      ref="modal"
      header-class="headerModal"
      centered
      size="xl"
      :title="$t('CreateOrderMod')"

      title-class="red"
      modal-class="dd"
      hide-footer
      @close="onCancel"
    >
      <b-form
        class="fixed-height-modal"
        @reset="onCancel"
      >
        <section
          v-if="client == true || clientId != '00000000-0000-0000-0000-000000000000'"
          class="custom-modal__wrapper"
          style="border-width: 3px"
        >
          <b-tabs>
            <b-tab
              :title="$t('PersonalInfo')"
              active
            >
              <template #title>
                <strong style="font-weight: 500; color: black">{{ $t("PersonalInfo") }}</strong>
              </template>
              <b-collapse
                id="collapse-345"
                visible
              >
                <div
                  class="hello"
                  style="padding-top: 20px"
                >
                  <b-form-group :label="$t('Salutation')">
                    <vue-select
                      v-model="$v.staticForm.ordererDto.salutation.$model"
                      :options="salutation"
                      required
                      :placeholder="$t('SelectSalutation')"
                      :state="validateState('salutation')"
                      aria-describedby="input-1-live-feedback"
                    />
                    <p
                      v-if="staticForm.ordererDto.salutation == null && isButtonSubmited == true"
                      style="color: red"
                    >
                      {{ $t('This_is_a_required_field') }}
                    </p>
                  </b-form-group>

                  <b-form-group :label="`${$t('FirstName')}`">
                    <b-form-input
                      v-model="$v.staticForm.ordererDto.firstName.$model"
                      :state="validateState('firstName')"
                      placeholder="e.g. Michael"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('LastName')}`">
                    <b-form-input
                      v-model="$v.staticForm.ordererDto.lastName.$model"
                      :state="validateState('lastName')"
                      placeholder="e.g. Goodman"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </div>

                <div class="hello">

                  <b-form-group :label="`${$t('Gender')}`">
                    <vue-select
                      v-model="$v.staticForm.ordererDto.gender.$model"
                      :options="[$t('Male'), $t('Female')]"
                      :placeholder="$t('SelectGender')"
                      required
                      :state="validateState('gender')"
                      aria-describedby="input-5-live-feedback"
                    />
                    <p
                      v-if="staticForm.ordererDto.gender == null && isButtonSubmited == true"
                      style="color: red"
                    >
                      {{ $t('This_is_a_required_field') }}
                    </p>
                  </b-form-group>

                  <b-form-group :label="`${$t('Email')}`">
                    <b-form-input
                      v-model="$v.staticForm.ordererDto.email.$model"
                      :state="validateState('email')"
                      placeholder="e.g. example@digitbusiness.ch"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{
                        $t('Must_be_email_format')
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('PhoneNumber')}`">
                    <vue-phone-number-input
                      v-model="staticForm.ordererDto.telephoneNumber"
                      :default-country-code="staticForm.ordererDto.countryCode"
                      :preferred-countries="['CH', 'DE', 'AL']"
                      :error="isPhoneNumberValid == null ? false : isPhoneNumberValid == false ? true : false"
                      @update="getFormattedNumber"
                    />
                  </b-form-group>
                </div>
              </b-collapse>
            </b-tab>
            <b-tab
              :title="$t('BillingAddress')"
            >
              <template #title>
                <strong style="font-weight: 500; color: black">{{ $t("BillingAddress") }}</strong>
              </template>
              <b-collapse
                id="collapse-345"
                visible
              >
                <div
                  class="hello"
                  style="padding-top: 20px"
                >
                  <b-form-group :label="`${$t('Street')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.street"
                      placeholder="e.g. Rexhep Maja"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('HouseNumber')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.houseNumber"
                      type="number"
                      step="any"
                      min="0"
                      placeholder="e.g. 30"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback"> {{
                      $t('This_is_a_required_field_and_must_contains_numbers')
                    }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('DoorNumber')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.doorNumber"
                      type="number"
                      placeholder="e.g. 50"
                      step="any"
                      min="0"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_contains_numbers')
                    }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="hello">

                  <b-form-group :label="`${$t('PostalCode')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.postalCode"
                      min="0"
                      placeholder="e.g. 10000"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('City')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.city"
                      placeholder="e.g. Prishtinë"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('Country')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.country"
                      placeholder="e.g. Kosovë"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </b-collapse>
            </b-tab>
            <b-tab
              :title="$t('ShippingAddress')"
            >
              <template #title>
                <strong style="font-weight: 500; color: black">{{ $t("ShippingAddress") }}</strong>
              </template>
              <b-collapse
                id="collapse-345"
                visible
              >
                <div>
                  <div
                    class="hello"
                    style="padding-top: 20px"
                  >
                    <b-form-group :label="`${$t('Street')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.street"
                        :disabled="pickUpInStore"
                        placeholder="e.g. Rexhep Maja"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback>{{
                        $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                      }}</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group :label="`${$t('HouseNumber')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.houseNumber"
                        :disabled="pickUpInStore"
                        placeholder="e.g. 30"
                        type="number"
                        step="any"
                        min="0"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_contains_numbers')
                      }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group :label="`${$t('DoorNumber')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.doorNumber"
                        :disabled="pickUpInStore"
                        type="number"
                        placeholder="e.g. 50"
                        step="any"
                        min="0"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_contains_numbers')
                      }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <div class="hello">

                    <b-form-group :label="`${$t('PostalCode')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.postalCode"
                        :disabled="pickUpInStore"
                        placeholder="e.g. 10000"
                        min="0"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                      }}</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group :label="`${$t('City')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.city"
                        :disabled="pickUpInStore"
                        placeholder="e.g. Prishtinë"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                      }}</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group :label="`${$t('Country')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.country"
                        :disabled="pickUpInStore"
                        placeholder="e.g. Kosovë"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                      }}</b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <span
                    class="duplicate-material"
                    @click="duplicateBillingAddress(), resetPickUpInStore()"
                  >
                    {{ $t('SameAsBillingAddress') }}
                  </span>
                </div>
                <p>
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="pickUpInStore"
                    name="checkbox-1"
                    :value="true"
                    :unchecked-value="false"
                    @input="resetShippingAddress()"
                  >
                    {{ $t('GetInStore') }}
                  </b-form-checkbox>
                </p>
              </b-collapse>
            </b-tab>
          </b-tabs>
        </section>
        <section
          class="custom-modal__wrapper"
          style="border-width: 3px;margin-top: 15px"
        >
          <div style="display:flex;gap: 15px">
            <b-form-group
              :label="$t('From')"
              style="width: 22%"
            >
              <date-picker
                v-model="from"
                type="datetime"
                :placeholder="$t('SelectD')"
              />
              <p
                v-if="from == null && isButtonSubmited"
                style="color: red;margin-top: 3px"
              >
                {{ $t('This_is_a_required_field') }}
              </p>
            </b-form-group>
            <b-form-group
              :label="$t('To')"
              style="width: 22%"
            >
              <date-picker
                v-model="to"
                type="datetime"
                :placeholder="$t('SelectD')"
              />
              <p
                v-if="to == null && isButtonSubmited"
                style="color: red;margin-top: 3px"
              >
                {{ $t('This_is_a_required_field') }}
              </p>
            </b-form-group>
            <b-form-group
              :label="$t('Users')"
              style="width: 22%"
            >
              <vue-select
                v-model="userIds"
                :options="formatedUsers"
                :multiple="true"
                label="fullName"
                :reduce="(e) => e.userId"
                :placeholder="$t('Select')"
              />
              <p
                v-if="userIds.length == 0 && isButtonSubmited"
                style="color: red;margin-top: 3px"
              >
                {{ $t('This_is_a_required_field') }}
              </p>
            </b-form-group>
          </div>
        </section>

        <div class="buttonsEverywhere">
          <button
            type="button"
            variant="none"
            style="margin-top: 20px;"
            class="buttonSubmit"
            :disabled="loading"
            @click="onSubmit()"
          >
            <div
              v-if="loading"
              class="spinner-border spinner-border-sm"
            />
            {{ $t('Submit') }}
          </button>
          <b-button
            type="reset"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px; margin-top: 20px; background: white; color: black; border-color: white;font-weight: 400;"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import {
  required, minLength, email,
} from 'vuelidate/lib/validators';

import AWS from 'aws-sdk';
import moment from 'moment'
import DatePicker from 'vue2-datepicker';
import { AWSurl, client } from '../domainConfig';
import 'vue2-datepicker/index.css';

export default {
  components: {
    DatePicker,
  },
  mixins: [validationMixin],
  // eslint-disable-next-line vue/require-prop-types
  props: ['clientId', 'objectClient', 'nextYearClicked', 'nextMonthClicked', 'nextDayClicked', 'titleOfCalendar'],
  id: {
    type: Object,
  },
  data() {
    return {
      appointmentType: 'Montage',
      userIds: [],
      to: null,
      from: null,
      isButtonSubmited: false,
      clienti: client.clientName,
      client: false,
      loading: false,
      arrowCounter: 0,
      enterPressed: false,
      searchClients: null,
      noSearch: false,
      isPhoneNumberValid: null,
      config: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
      },
      pickUpInStore: false,
      salutation: ['Mr.', 'Ms.'],
      staticForm: {
        ordererDto: {
          salutation: null,
          firstName: '',
          lastName: '',
          email: '',
          gender: null,
          telephoneNumber: '',
          countryCode: '',
        },
        billingAddressDto: {
          street: '',
          houseNumber: '',
          doorNumber: '',
          postalCode: '',
          city: '',
          country: '',
        },
        shippingAddressDto: {
          street: '',
          houseNumber: '',
          doorNumber: '',
          postalCode: '',
          city: '',
          country: '',
        },
      },
      description: '',

    };
  },

  validations: {
    staticForm: {
      ordererDto: {
        salutation: {
          required,
        },
        firstName: {
          required,
          minLength: minLength(3),
        },
        lastName: {
          required,
          minLength: minLength(3),
        },
        email: {
          email,
        },
        gender: {
          required,
        },
      },
    },
  },
  computed: {
    ...mapGetters(['getClients',
      'getOrderTypes',
      'getMeasermentsById',
      'getFirstNames',
      'getTotalItemsForClients',
      'getClient',
      'getLanguage',
      'getAccountDetails',
      'getUsersMultipleRoles',
      'getUsersActiveInactive']),
    formatedUsers() {
      return this.getUsersActiveInactive.map((item) => {
        return {
          fullName: `${item.firstName} ${item.lastName}`,
          userId: item.userId,
        }
      })
    },
  },
  watch: {
    pickUpInStore(value) {
      if (value == true) {
        this.staticForm.shippingAddressDto.street = '';
        this.staticForm.shippingAddressDto.houseNumber = '';
        this.staticForm.shippingAddressDto.doorNumber = '';
        this.staticForm.shippingAddressDto.postalCode = '';
        this.staticForm.shippingAddressDto.city = '';
        this.staticForm.shippingAddressDto.country = '';
      }
    },
    objectClient(value) {
      if (value) {
        this.from = new Date(value.montageAt);
      }
    },
    async clientId(value) {
      if (value) {
        const clientData = await this.getClientById({
          clientId: value,
          successCallback: () => {
          },
        })
        this.searchClients = `${clientData.firstName} ${clientData.lastName}`
        this.staticForm.ordererDto.salutation = clientData.salutation
        this.staticForm.ordererDto.firstName = clientData.firstName
        this.staticForm.ordererDto.lastName = clientData.lastName
        this.staticForm.ordererDto.gender = clientData.gender
        this.staticForm.ordererDto.email = clientData.email
        this.staticForm.ordererDto.telephoneNumber = clientData.telephoneNumber
        this.staticForm.ordererDto.countryCode = clientData.countryCode
        this.staticForm.ordererDto.taxRate = clientData.taxRate
        this.staticForm.billingAddressDto.street = clientData.billingAddressDto.street
        this.staticForm.billingAddressDto.houseNumber = clientData.billingAddressDto.houseNumber
        this.staticForm.billingAddressDto.doorNumber = clientData.billingAddressDto.doorNumber
        this.staticForm.billingAddressDto.postalCode = clientData.billingAddressDto.postalCode
        this.staticForm.billingAddressDto.city = clientData.billingAddressDto.city
        this.staticForm.billingAddressDto.country = clientData.billingAddressDto.country
        this.staticForm.shippingAddressDto.street = clientData.shippingAddressDto.street
        this.staticForm.shippingAddressDto.houseNumber = clientData.shippingAddressDto.houseNumber
        this.staticForm.shippingAddressDto.doorNumber = clientData.shippingAddressDto.doorNumber
        this.staticForm.shippingAddressDto.postalCode = clientData.shippingAddressDto.postalCode
        this.staticForm.shippingAddressDto.city = clientData.shippingAddressDto.city
        this.staticForm.shippingAddressDto.country = clientData.shippingAddressDto.country
      }
    },
  },
  created() {
    AWS.config.update({
      accessKeyId: AWSurl.accessKeyId,
      secretAccessKey: AWSurl.secretAccessKey,
      region: AWSurl.region,
    });

    this.s3 = new AWS.S3();
  },
  mounted() {
  },

  methods: {
    ...mapActions([
      'usersByMultipleRoles', 'addUsersToAppointment', 'loadMontageAppointmentsWithoutId', 'loadMontageAppointments', 'createAppointment', 'resetCustomOrders', 'clearCustomMeasurements', 'loadOrderTypes', 'editClient', 'getClientById', 'getClientsPagination', 'getClientById', 'firstNames', 'resetSearchClients', 'loadMeasermentsById',
    ]),
    getFormattedNumber(n) {
      if (n.isValid == true) {
        this.isPhoneNumberValid = true
        this.staticForm.ordererDto.telephoneNumber = n.nationalNumber
        this.staticForm.ordererDto.countryCode = n.countryCode
      } else {
        this.staticForm.ordererDto.countryCode = ''
        this.isPhoneNumberValid = false
        return;
      }
      console.log()
    },
    removeLists() {
      this.noSearch = false;
      this.resetSearchClients();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.staticForm.ordererDto[name];
      return $dirty ? !$error : null;
    },
    resetPickUpInStore() {
      this.pickUpInStore = false
    },
    duplicateBillingAddress() {
      this.staticForm.shippingAddressDto.street = this.staticForm.billingAddressDto.street;
      this.staticForm.shippingAddressDto.houseNumber = this.staticForm.billingAddressDto.houseNumber;
      this.staticForm.shippingAddressDto.doorNumber = this.staticForm.billingAddressDto.doorNumber;
      this.staticForm.shippingAddressDto.postalCode = this.staticForm.billingAddressDto.postalCode;
      this.staticForm.shippingAddressDto.city = this.staticForm.billingAddressDto.city;
      this.staticForm.shippingAddressDto.country = this.staticForm.billingAddressDto.country;
    },

    async onSubmit() {
      this.isButtonSubmited = true
      this.$v.staticForm.ordererDto.$touch();
      if (this.$v.staticForm.ordererDto.$anyError) {
        return;
      }
      if (this.from == null || this.to == null) {
        return;
      }
      if (this.userIds.length == 0) {
        return;
      }
      const objekti = {
        ordererDto: this.staticForm.ordererDto,
        billingAddressDto: this.staticForm.billingAddressDto,
        shippingAddressDto: this.staticForm.shippingAddressDto,
        clientId: this.clientId,
        from: this.from,
        to: this.to,
        appointmentType: this.appointmentType,
        userIds: this.userIds,
      }
      this.createAppointment({
        object: objekti,
        successCallback: () => {
          if (this.nextDayClicked) {
            this.loadMontageAppointments({
              start: moment(this.titleOfCalendar).startOf('day').format('YYYY-MM-DD'),
              end: moment(this.titleOfCalendar).add(7, 'days').format('YYYY-MM-DD'),
            })
          } else if (this.nextMonthClicked) {
            this.loadMontageAppointments({
              start: moment(this.titleOfCalendar).startOf('month').format('YYYY-MM-DD'),
              end: moment(this.titleOfCalendar).endOf('month').format('YYYY-MM-DD'),
            })
          } else if (this.nextYearClicked) {
            this.loadMontageAppointments({
              start: moment(this.titleOfCalendar).startOf('year').format('YYYY-MM-DD'),
              end: moment(this.titleOfCalendar).add(1, 'year').format('YYYY-MM-DD'),
            })
          }
          this.loadMontageAppointmentsWithoutId()
        },
      })
      this.$refs.modal.hide();
      this.resetForm()
      this.resetCustomerData()
    },
    onCancel() {
      this.$refs.modal.hide();
      this.resetCustomerData()
      this.resetForm()
    },
    resetShippingAddress() {
      this.$v.staticForm.shippingAddressDto.$reset()
    },
    resetForm() {
      this.isButtonSubmited = false
      this.description = '';
      this.from = null
      this.to = null
      this.userIds = []
    },
  },
};
</script>

<style lang="scss" scoped>

.modal-header{
  font-size: 25px !important;
}

.fixed-height-modal[data-v-094d39fb] {
    height: 100%;
    overflow-y: auto;
    padding-right: 1rem;
}
textarea.form-control {
    min-height: calc(1.5em + 0.75rem + 2px);
    width: 1090px;
}
fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
    width: 49%;
}
.price-container {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.hello{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 1%
}
.price-text {
  margin-left: 0px;
  font-size: 18px;
  // font-weight: bold;
  color: black;
  border-radius: 10px;
}
.search1 {
    padding: 0px;
  }
.black {
  position: relative;
}
.black input {
  height: 38px;
}
.dropdrop::-webkit-scrollbar {
display: none;

}
.dropdrop {
display: inline-block;
overflow: auto;
position: absolute;
background: white;
width: 227px;
z-index: 2;
box-shadow: none;
}
.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}
.search-item:last-child {
  border-bottom: none;
}
.search-item:hover {
  background: #82868c;
  color: white;
}
.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}
.is-active {
  background-color: #dedede;
}
form {
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}


.headline {
  padding: 10px 10px;
  background-color: white;
  color: black;
  font-weight: bold;
  border-radius: 8px;
}

.custom-modal__wrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px 0px;

  >div {
    flex: 1;
    margin: 0px 15px;
  }
}

.flex-form {
  display: flex;
  flex-direction: column;
}

.fixed-height-modal {
  height: 100%;
  overflow-y: auto;
  padding-right: 1rem;
}


.inside-actions {
  margin-left: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  box-shadow: none;
  padding: 2px 4px;
  border-radius: 7px;
}

.inside-actions:hover {
  background-color: #242F6E;
  color: #fff;
}

.f {
  border-color: red !important;
}


.btn-con {
  display: flex;

  button {
    border: none;
    background: #FF274F;
    color: $digit-white;
    border-radius: 0.25rem;
    padding: 3px 12px;
  }
}


@media only screen and (max-width: 1200px) {
  textarea.form-control[data-v-094d39fb] {
    min-height: calc(1.5em + 0.75rem + 2px);
    width: 745px;
  }
  .existingClient{
    width: 65% !important;
  }

  .btn-con {
    display: flex;

    button {
      border: none;
      background-color: #6c757d;
      ;
      color: $digit-white;
      border-radius: 0.25rem;
      padding: 3px 12px;
    }
  }


input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -3px;
  left: -1px;
  position: relative;
  background-color: $base-color ;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
}

</style>
