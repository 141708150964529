<template>
  <b-sidebar
    id="client-orders-view"
    header-class="headerModalToggle"
    :title="$t('OrdersByClients')"
    right
    shadow
    width="60%"
    no-close-on-esc
    @hide="onCancel"
  >
    <template #title>
      <strong style="color: #101828;">{{ `${$t('OrdersByClients')}` }}</strong>
    </template>
    <template
      v-slot:header-close
    >
      <button
        style="color: #98A2B3;padding-left: 0px; padding-right: 0px;height: 60px; "
        class="fa fa-close"
        @click="onCancelToggle"
      />
    </template>
    <div style="padding: 20px;">
      <div>
        <b-form-group>
          <b-form-select
            v-model="yearClient"
            style="width:20%;padding: 8.5px;
              color: #82868c;
              border-radius: 8px;
              border: 1px solid #e2e2e2;
              border-radius: 8px; width: 200px"
            :options="yearsArray"
          />
        </b-form-group>
      </div>
      <!-- <h4
        style="color: #262E6C; margin-top: 20px; font-weight: 400;"
      > {{ $t('OrdersByClients') }}</h4> -->
      <div
        class="tt"
        style=" padding-top: 16px; height: 84vh; overflow-y: scroll"
      >
        <table
          class="team_table"
        >
          <thead style="position: sticky; top: -17px">
            <tr>
              <th>
                {{ $t('OrderNumber') }}
              </th>
              <th>
                {{ $t('OrderType') }}
              </th>
              <th>
                {{ $t('CustomerName') }}
              </th>
              <th>
                {{ $t('Paid/Price') }}
              </th>
              <th>
                {{ $t('PhoneNumber') }}
              </th>
              <th>
                {{ $t('ShippingAddress') }}
              </th>
              <th>
                {{ $t('Created') }}
              </th>
            </tr>
          </thead>
          <tbody v-if="filteredVariants.length > 0">
            <tr
              v-for=" ( item ,index) in filteredVariants"
              :key="index"
              v-b-tooltip.hover.bottom
              class="hover-row"
              style="cursor:pointer;"
              :title="$t('ClickArticle')"
              @click="readOrderById(item.orderNumber, item.orderId)"
            >
              <td>
                {{ $t(item.orderNumber) }}
              </td>
              <td>
                {{ $t(item.orderType) }}
              </td>
              <td>
                {{ item.ordererResponseDto.firstName }} {{ item.ordererResponseDto.lastname }}
              </td>
              <td>
                {{ item.paidTotal }} / {{ item.priceTotal }}
              </td>
              <td>
                {{ item.ordererResponseDto.telephoneNumber }}
              </td>
              <td>
                {{ item.billingAddressDto.street }}
              </td>
              <td>
                {{ createdDateModified(item.created) }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="7">
                {{ $t('NoOrders') }}
              </td>
            </tr>
          </tbody>
          <thead
            v-if="showHide == true"
            style="padding-top:30px;border-bottom: 1px solid #dcdfe6; color: #606266; text-align: left"
          >
            <tr>
              <th>
                {{ $t('ArticleNumber') }}
              </th>
              <th>
                {{ $t('ProcessedBy') }}
              </th>
              <th>
                {{ $t('PickupDate') }}
              </th>
              <th>
                {{ $t('Created') }}
              </th>
            </tr>
          </thead>
          <tbody v-if="showHide == true">
            <tr
              v-for=" ( item ,index) in getArticleOrders"
              :key="index"
              class="hover-row"
              style="border-bottom: 1px solid #dcdfe6; color: #606266; cursor:pointer;"
            >
              <td>
                {{ $t(item.articleNumber) }}
              </td>
              <td>
                {{ item.processingUser }}
              </td>
              <td>
                {{ createdDateModified(item.pickUpDate) }}
              </td>
              <td>
                {{ createdDateModified(item.created) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style="padding: 0px 15px 10px 0px">
        <b-pagination
          v-if="getTotalItemsForOrders > 15"
          v-model="page"
          :total-rows="getTotalItemsForOrders"
          :per-page="pageSize"
          first-number
          last-number
          align="fill"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
        >
          <template #prev-text>
            <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
          </template>
          <template #next-text>
            <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
          </template>
        </b-pagination>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

export default {
  components: {
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['clientId'],
  data() {
    return {
      yearClient: moment().format('YYYY'),
      yearsArray: [],
      page: 1,
      pageSize: 15,
      showHide: false,
      showM: false,
      activeOrderStatus: '',
    };
  },
  computed: {
    ...mapGetters(['getArticleOrders', 'getHistoryTracking', 'getOrderTrackItems', 'getOrders', 'getTotalItemsForOrders']),
    filteredVariants() {
      // filter the users array to only show rows with the same id as the selected id
      return this.activeOrderStatus
        ? this.getOrders.filter((getOrders) => {
          return getOrders.orderNumber === this.activeOrderStatus;
        })
        : this.getOrders;
    },
  },
  watch: {
    page(value) {
      this.showHide = false
      this.activeOrderStatus = ''
      this.getOrdersOverview({
        salesType: 'Product',
        pageNumber: value,
        pageSize: this.pageSize,
        orderNumber: null,
        clientId: this.clientId,
        year: this.yearClient,
        pickUpDate: this.filterDate == '' ? null : this.filterDate,
        orderType: this.selectedOrderType == null ? null : this.selectedOrderType,
        orderItemState: this.selectedOrderState == null ? null : this.selectedOrderState,
      });
    },
    yearClient(value) {
      this.getOrdersOverview({
        salesType: 'Product',
        pageNumber: this.page,
        pageSize: this.pageSize,
        orderNumber: null,
        clientId: this.clientId,
        year: value,
        pickUpDate: this.filterDate == '' ? null : this.filterDate,
        orderType: this.selectedOrderType == null ? null : this.selectedOrderType,
        orderItemState: this.selectedOrderState == null ? null : this.selectedOrderState,
      });
    },
  },
  created() {
  },
  mounted() {
    const currentYear = new Date().getFullYear();
    for (let i = 0; i <= 5; i++) {
      this.yearsArray.push({ value: currentYear - i, text: String(currentYear - i) });
    }
  },
  methods: {
    ...mapActions(['resetMaterialVariantsOfOrders', 'getOrdersOverview', 'loadOrderedMaterials', 'loadOrderItems', 'readOrderItems', 'resetReadOrderItems']),
    createdDateModified(xdate) {
      return moment(xdate).format('DD/MM/YYYY - HH:mm');
    },
    onCancelToggle() {
      this.$emit('onCancelToggle');
      this.yearClient = moment().format('YYYY')
    },
    onCancel() {
      this.showM = false;
      this.showHide = false;
      this.activeOrderStatus = ''
      this.filteredVariants = []
      this.yearClient = moment().format('YYYY')
    },
    numbersWithcomma(value) {
      const options = {
        style: 'decimal', // Other options: 'currency', 'percent', etc.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return value.toLocaleString('en-US', options);
    },
    async readOrderById(ordNId, orderIds) {
      this.showDescription = false

      // this.changeLoadingtoTrue(false)


      if (this.activeOrderStatus === ordNId && this.showHide) { // Condition to skip request
        this.activeOrderStatus = ''
        this.showHide = false
      } else {
        this.activeOrderStatus = ordNId
        this.showHide = true
        this.resetReadOrderItems()
        await this.readOrderItems({
          orderId: orderIds,
          orderItemState: this.selectedOrderState == null ? null : this.selectedOrderState,
        })
      }

      // this.changeLoadingtoTrue(true)
      this.resetMaterialVariantsOfOrders()
      this.showM = false
    },
  },
};
</script>

<style scoped lang="scss">

.team_table td {
  padding: 16px 10px;
}

#sidebar-right{
  z-index: 1001;
}
        .hover-row:hover {
          background-color: #f5f5f5; /* Set your desired background color here */
        }
      .mats-listing__wrapper {
        width: 600px !important;

        .tt {
          .vgt-table {
            th.sortable button {
              display: none !important;
            }
          }
        }

        .mats-listing {
          margin-top: 0;
        }
      }

      .button {
        margin-bottom: 15px !important;
        width: 195px;
      }

      // .team_table .tr{
      //   border-radius: 10px 10px 10px 10px !important;
      // }
      tr:last-child {
        border-bottom: none !important;
      }

      @media screen and (max-width: 1723px) {
        .mats-listing__wrapper {
          width: 400px !important;

          div {
            button {
              width: auto;
              height: 36px;
            }

            input {
              width: 160px;
            }

          }

          .table__search-input {
            padding-left: 0px;
            margin-left: 0px !important;
          }
        }

      }

      @media screen and (max-width: 1400px) {
        .mats-listing__wrapper {
          width: 100% !important;
        }
      }


      .dropdrop::-webkit-scrollbar {
        display: none;

      }

      .dropdrop {
        display: inline-block;
        overflow: auto;
        position: absolute;
        background: white;
        width: 227px;
        box-shadow: none;
      }

      input[name="search-box"] {
        display: block;
        max-width: 500px;
        width: 95%;
        padding: 0.5rem 1rem;
        padding-left: 25px;
      }

      .black {
        position: relative;
      }

      .black input {
        border: none;
        box-shadow: none;
      }

      .search-item {
        padding: 5px;
        max-width: 500px;
        width: 100%;
        font-weight: 400;
        color: #82868c;
        font-size: 1rem;
        border-bottom: 1px solid #e8e8e8;
      }

      .search-item:last-child {
        border-bottom: none;
      }

      .search-item:hover {
        background: #82868c;
        color: white;
      }

      .search-item hr {
        color: lightgray;
        border-top: none;
        margin: -1.3rem 0 0.5rem 0;
      }

      .is-active {
        background-color: #dedede;
      }
      .headerModal{
        color: red;
      }
</style>
