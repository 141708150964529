import { render, staticRenderFns } from "./MaterialsManagement.vue?vue&type=template&id=187bd4cb&scoped=true"
import script from "./MaterialsManagement.vue?vue&type=script&lang=js"
export * from "./MaterialsManagement.vue?vue&type=script&lang=js"
import style0 from "./MaterialsManagement.vue?vue&type=style&index=0&id=187bd4cb&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "187bd4cb",
  null
  
)

export default component.exports