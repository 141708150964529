<template>
  <div>
    <b-modal
      id="add-custom-order-passive-stock"
      ref="modal"
      header-class="headerModal"
      centered
      size="xl"
      :title="`${addedArticle.length != 0 || customDresses.length != 0 ? $t('AddInStock') : $t('NothingIsAdded')
      }`"
      title-class="red"
      modal-class="dd"
      hide-footer
      @close="onCancel"
    >
      <b-form
        class="fixed-height-modal"
        @reset="onCancel"
      >
        <b-form-group />
        <div
          :style="addedArticle.length > 0 ? 'border: 3px solid lightgray; border-radius: 10px; padding: 15px; margin-top: 20px' : ''"
        >
          <StandardOrderItem
            v-for="(item, idx) in addedArticle"
            :key="idx"
            :dress="item"
            :submit-clicked="isSubmitClicked"
          />
        </div>
        <b-form-group
          :label="$t('Description')"
          style="margin-top: 20px"
        >
          <b-form-textarea
            id="textarea"
            v-model="description"
            :placeholder="$t('RequestedChangesByCustomer')"
            rows="6"
            max-rows="6"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">
            {{ $t('RequiredFields') }}
          </b-form-invalid-feedback>
        </b-form-group>
        <div class="buttonsEverywhere">
          <button
            type="button"
            variant="none"
            style="margin-top: 20px;"
            class="buttonSubmit"
            @click="onSubmit()"
          >
            {{ $t('Submit') }}
          </button>
          <b-button
            type="reset"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px; margin-top: 20px;background: white;
            color: black;
            border-color: white;
            font-weight: 400;"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import {
  required, minLength, numeric, alpha, email, alphaNum,
} from 'vuelidate/lib/validators';
// import CustomOrderItem from '../../../modals/CreateOrderItemPassiveStock.vue'
import StandardOrderItem from '../../../modals/StandartOrderPassiveStock.vue'

export default {
  components: {
    // CustomOrderItem,
    StandardOrderItem,
  },
  mixins: [validationMixin],

  // eslint-disable-next-line vue/require-prop-types
  props: ['materials', 'addedArticle', 'articleNumber'],
  id: {
    type: Object,
  },
  data() {
    return {
      arrowCounter: 0,
      enterPressed: false,
      searchClients: null,
      sizzee: null,
      clientId: '00000000-0000-0000-0000-000000000000',
      noSearch: false,
      isPhoneNumberValid: null,
      config: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
      },
      orderTypeOptions: [
        { value: null, text: 'Choose a Type' },
        { value: 'InStore', text: this.$t('InStore') },
        { value: 'Online', text: 'Online' },
        { value: 'WholeSale', text: this.$t('WholeSale') },
        { value: 'Instagram', text: 'Instagram' },
        { value: 'Whatsapp', text: 'Whatsapp' },
        { value: 'Viber', text: 'Viber' },
        { value: 'Facebook', text: 'Facebook' },
      ],
      selectedOrderType: null,
      pickUpInStore: false,
      partOptions: [],
      meterOptions: [],
      salutation: ['Mr.', 'Ms.'],
      staticForm: {
        ordererDto: {
          salutation: '',
          firstName: '',
          lastName: '',
          email: '',
          gender: '',
          telephoneNumber: '',
          countryCode: '',
          taxRate: 0,
        },
        billingAddressDto: {
          street: '',
          houseNumber: '',
          doorNumber: '',
          postalCode: '',
          city: '',
          country: '',
        },
        shippingAddressDto: {
          street: '',
          houseNumber: '',
          doorNumber: '',
          postalCode: '',
          city: '',
          country: '',
        },
      },
      description: '',
      isSubmitClicked: false,
      form: [],
      forms: [],
      customDresses: [],
      error: {
        oType: '',
        duplicateError: '',
      },
      formItem: [],
      sizeOptions: [
        {
          size: 'xs',
        },
        {
          size: 's',
        },
        {
          size: 'm',
        },
        {
          size: 'l',
        },
        {
          size: 'xl',
        },
      ],
    };
  },

  validations: {
    selectedOrderType: {
      required,
    },
    staticForm: {
      ordererDto: {
        salutation: {
          required,
        },
        firstName: {
          required,
          minLength: minLength(3),
          alpha,
        },
        lastName: {
          required,
          minLength: minLength(3),
          alpha,
        },
        email: {
          email,
        },
        gender: {
          required,
        },
      },
      billingAddressDto: {
        street: {
          required,
          minLength: minLength(3),
        },
        houseNumber: {
          required,
          numeric,
        },
        doorNumber: {
          required,
          numeric,
        },
        postalCode: {
          required,
          minLength: minLength(3),
        },
        city: {
          required,
        },
        country: {
          required,
        },
      },
      shippingAddressDto: {
        street: {
          required,
          minLength: minLength(3),
        },
        houseNumber: {
          required,
          numeric,
        },
        doorNumber: {
          required,
          numeric,
        },
        postalCode: {
          required,
          alphaNum,
          minLength: minLength(3),
        },
        city: {
          required,
        },
        country: {
          required,
        },
      },
    },
  },
  computed: {
    ...mapGetters(['getClients', 'getFirstNames', 'getTotalItemsForClients', 'getClient', 'getLanguage']),
    totalPrice() {
      return this.addedArticle.reduce((total, item) => { return total + item.itemi.price }, 0) + this.customDresses.reduce((total, item) => { return total + item.price }, 0)
    },
    totalPaidPrice() {
      return this.addedArticle.reduce((total, item) => { return total + parseFloat(item.paidPrice || 0) }, 0) + this.customDresses.reduce((total, item) => { return total + item.paidPrice }, 0);
    },
  },
  watch: {
    form: {
      deep: true,
    },
    addedArticle: {
      handler(newValue) {
        console.log(newValue)
        if (this.isSubmitClicked) {
          // eslint-disable-next-line no-restricted-syntax
          for (const item of newValue) {
            if (item.pickUpDate == '') {
              console.log('hini')
              item.filledPickUpDate = false
            } else {
              item.filledPickUpDate = true
            }
          }
        }
        this.fillForms()
      },
      deep: true,
    },
    pickUpInStore(value) {
      if (value == true) {
        this.staticForm.shippingAddressDto.street = '';
        this.staticForm.shippingAddressDto.houseNumber = '';
        this.staticForm.shippingAddressDto.doorNumber = '';
        this.staticForm.shippingAddressDto.postalCode = '';
        this.staticForm.shippingAddressDto.city = '';
        this.staticForm.shippingAddressDto.country = '';
      }
    },
    selectedOrderType(value) {
      if (value !== null) {
        this.error.oType = ''
      }
    },
    measureItem() {
      this.form.measurementType = this.measureItem;
    },
    searchClients(value) {
      if (value == null) {
        this.removeLists()
      } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
        return;
      } else {
        this.firstNames(value);
        this.noSearch = true
      }
    },
  },
  mounted() {
    // this.loadMeasermentsById()
    // console.log('eeee', this.getMeasermentsById)
    // this.loadMeasermentsById()
  },

  methods: {
    ...mapActions([
      'resetCustomOrders', 'editClient', 'getClientById', 'getClientsPagination', 'getClientById', 'firstNames', 'resetSearchClients', 'loadMeasermentsById',
    ]),
    getFormattedNumber(n) {
      if (n.isValid == true) {
        this.isPhoneNumberValid = true
        this.staticForm.ordererDto.telephoneNumber = n.nationalNumber
        this.staticForm.ordererDto.countryCode = n.countryCallingCode
      } else {
        this.staticForm.ordererDto.countryCode = ''
        this.isPhoneNumberValid = false
        return;
      }
      console.log()
    },
    // measureMethod() {
    //   this.loadMeasermentsById();
    // },
    uncheckedValueIsResponsible(clickedEmployee) {
      this.customDresses.forEach((dress1) => {
        if (dress1 !== clickedEmployee) {
          dress1.defaultBodyMeasurements = false;
        }
      });
    },
    test(value) {
      // console.log('1', value)
      this.$emit('test', value)
    },
    async searchByOn(value) {
      this.noSearch = false
      const clientData = await this.getClientById({
        clientId: value,
        successCallback: () => {
        },
      })
      this.searchClients = `${clientData.firstName} ${clientData.lastName}`
      this.clientId = value
      this.staticForm.ordererDto.salutation = clientData.salutation
      this.staticForm.ordererDto.firstName = clientData.firstName
      this.staticForm.ordererDto.lastName = clientData.lastName
      this.staticForm.ordererDto.gender = clientData.gender
      this.staticForm.ordererDto.email = clientData.email
      this.staticForm.ordererDto.taxRate = clientData.taxRate
      this.staticForm.ordererDto.telephoneNumber = clientData.telephoneNumber
      this.staticForm.billingAddressDto.street = clientData.billingAddressDto.street
      this.staticForm.billingAddressDto.houseNumber = clientData.billingAddressDto.houseNumber
      this.staticForm.billingAddressDto.doorNumber = clientData.billingAddressDto.doorNumber
      this.staticForm.billingAddressDto.postalCode = clientData.billingAddressDto.postalCode
      this.staticForm.billingAddressDto.city = clientData.billingAddressDto.city
      this.staticForm.billingAddressDto.country = clientData.billingAddressDto.country
      this.staticForm.shippingAddressDto.street = clientData.shippingAddressDto.street
      this.staticForm.shippingAddressDto.houseNumber = clientData.shippingAddressDto.houseNumber
      this.staticForm.shippingAddressDto.doorNumber = clientData.shippingAddressDto.doorNumber
      this.staticForm.shippingAddressDto.postalCode = clientData.shippingAddressDto.postalCode
      this.staticForm.shippingAddressDto.city = clientData.shippingAddressDto.city
      this.staticForm.shippingAddressDto.country = clientData.shippingAddressDto.country
    },
    removeLists() {
      this.noSearch = false;
      this.resetSearchClients();
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFirstNames.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },

    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredActiveUsers = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getFirstNames[this.arrowCounter].clientId)
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    fillForms() {
      this.forms = []
      for (let i = 0; i < this.addedArticle.length; i++) {
        this.forms.push(this.addedArticle[i].materials.productSingleMaterialVariantDtos)
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.staticForm.ordererDto[name];
      return $dirty ? !$error : null;
    },
    validateState2(name) {
      const { $dirty, $error } = this.$v.staticForm.billingAddressDto[name];
      return $dirty ? !$error : null;
    },
    validateState3(name) {
      const { $dirty, $error } = this.$v.staticForm.shippingAddressDto[name];
      return $dirty ? !$error : null;
    },
    validateState4(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },

    removeCustomDress(idx) {
      this.customDresses.splice(idx, 1)
      this.$emit('decrementCounter')
    },
    resetPickUpInStore() {
      this.pickUpInStore = false
    },
    duplicateBillingAddress() {
      this.staticForm.shippingAddressDto.street = this.staticForm.billingAddressDto.street;
      this.staticForm.shippingAddressDto.houseNumber = this.staticForm.billingAddressDto.houseNumber;
      this.staticForm.shippingAddressDto.doorNumber = this.staticForm.billingAddressDto.doorNumber;
      this.staticForm.shippingAddressDto.postalCode = this.staticForm.billingAddressDto.postalCode;
      this.staticForm.shippingAddressDto.city = this.staticForm.billingAddressDto.city;
      this.staticForm.shippingAddressDto.country = this.staticForm.billingAddressDto.country;
    },
    addNewRow(index) {
      if (this.forms[index].length > 0) {
        this.forms[index].push({
          materialVariantId: '',
          measurementType: 'Meter',
          name: '',
          length: 0,
          quantity: 0,
        });
      }
    },
    addNewCustomDress() {
      this.customDresses.push({
        paidPrice: 0,
        price: 0,
        probeDates: [{
          probeName: 'Fitting Date',
          dateOfProbe: '',
          notes: null,
        }],
        title: `Custom Dress ${this.customDresses.length + 1}`,
        formItem: [],
        pickUpDate: '',
        filledPickUpDate: true,
        standardSize: '',
        eArticle: false,
        existingSizes: false,
        showFrontBody: true,
        sizesDto: {
          thorax: '',
          aroundBust: '',
          aroundUnderBust: '',
          waist: '',
          aroundHips: '',
          aroundLeg: '',
          aroundKnee: '',
          ankle: '',
          breastReduction: '',
          interBust: '',
          frontBody: '',
          chestDistance: '',
          length1: '',
          length2: '',
          length3: '',
          length4: '',
          aroundSleeve: '',
          elbow: '',
          wrist: '',
          aroundNeck: '',
          backBody: '',
          shoulders: '',
          back: '',
          declineOfTheHips: '',
          sleeveLength: '',
        },
        duplicateMV: false,
      })
      this.$emit('incrementCounter')
    },

    onSubmit() {
      this.isSubmitClicked = true
      // this.$v.staticForm.billingAddressDto.$touch();
      // this.$v.staticForm.ordererDto.$touch();
      // if (this.pickUpInStore == false) {
      //   this.$v.staticForm.shippingAddressDto.$touch();
      // }
      // if (this.pickUpInStore == false) {
      //   if (
      //     this.$v.staticForm.billingAddressDto.$anyError
      //   || this.$v.staticForm.ordererDto.$anyError
      //   || this.$v.staticForm.shippingAddressDto.$anyError
      //   || this.selectedOrderType == null
      //   || this.isPhoneNumberValid == false
      //   || this.isPhoneNumberValid == null
      //   ) {
      //     if (this.selectedOrderType == null) {
      //       this.error.oType = 'This_is_a_required_field'
      //     }
      //     if (this.isPhoneNumberValid == null) {
      //       this.isPhoneNumberValid = false
      //     }
      //     return;
      //   }
      // }
      // if (this.pickUpInStore == true) {
      //   if (
      //     this.$v.staticForm.billingAddressDto.$anyError
      //   || this.$v.staticForm.ordererDto.$anyError
      //   || this.selectedOrderType == null
      //   || this.isPhoneNumberValid == false
      //   || this.isPhoneNumberValid == null
      //   ) {
      //     if (this.selectedOrderType == null) {
      //       this.error.oType = 'This_is_a_required_field'
      //     }
      //     if (this.isPhoneNumberValid == null) {
      //       this.isPhoneNumberValid = false
      //     }
      //     return;
      //   }
      // }
      if (this.forms.length == 0 && this.customDresses.length == 0) return;
      let materialVariants = []
      const object2 = []
      // standard dresses
      // eslint-disable-next-line no-restricted-syntax
      for (let i = 0; i < this.forms.length; i++) {
        materialVariants = []
        // eslint-disable-next-line no-restricted-syntax
        for (const item of this.forms[i]) {
          materialVariants.push({
            name: item.name, measurementType: item.measurementType, materialVariantId: item.materialVariantId, length: item.length, quantity: item.quantity,
          })
        }

        // if (this.addedArticle.length > 0) {
        //   if (this.addedArticle[i].pickUpDate == '') {
        //     this.addedArticle[i].filledPickUpDate = false;
        //     console.log('hini')
        //   }
        // }
        this.sizzee = this.addedArticle[i].itemi.size
        object2.push({
          twentyPrinciples: null,
          // size: this.addedArticle[i].itemi.size,
          articleNumber: this.addedArticle[i].itemi.articleNumber,
          // pickUpDate: this.addedArticle[i].pickUpDate,
          // probeDates: this.addedArticle[i].probeDates,
          materialVariantDtos: materialVariants,
          // paidAmount: this.addedArticle[i].paidPrice,
          // price: this.addedArticle[i].itemi.price,
          // currency: this.addedArticle[i].itemi.currency,
        })
      }
      // end standard dresses

      // start custom  dresses
      for (let i = 0; i < this.customDresses.length; i++) {
        materialVariants = []
        // eslint-disable-next-line no-restricted-syntax
        for (const item of this.customDresses[i].formItem) {
          if (typeof item.name === 'object') {
            materialVariants.push({
              name: item.name.materialVariantName, measurementType: item.measurementType, materialVariantId: item.name.materialVariantId, length: item.length, quantity: item.quantity,
            })
          } else {
            materialVariants.push({
              name: item.name, measurementType: item.measurementType, materialVariantId: item.materialVariantId, length: item.length, quantity: item.quantity,
            })
          }
        }
        if (this.customDresses.length > 0) {
          if (this.customDresses[i].pickUpDate == '') {
            this.customDresses[i].filledPickUpDate = false;
          }
        }

        if (this.customDresses[i].existingSizes == true) {
          this.customDresses[i].sizesDto = null
        } else {
          this.customDresses[i].standardSize = null
        }
        object2.push({
          bodyMeasurements: this.customDresses[i].sizesDto,
          size: this.customDresses[i].standardSize,
          articleNumber: this.customDresses[i].title,
          // pickUpDate: this.customDresses[i].pickUpDate,
          materialVariantDtos: materialVariants,
          // probeDates: this.customDresses[i].probeDates,
          // paidAmount: this.customDresses[i].paidPrice,
          // price: this.customDresses[i].price,
          // currency: this.customDresses[i].currency,
        })
      }
      // end custom dresses
      if (this.customDresses.length > 0) {
        for (let i = 0; i < this.customDresses.length; i++) {
          if (this.customDresses[i].duplicateMV == true || this.customDresses[i].pickUpDate == '') {
            return;
          }
        }
      }

      if (this.addedArticle.length > 0) {
        for (let i = 0; i < this.addedArticle.length; i++) {
          if (this.addedArticle[i].pickUpDate == '') {
            return;
          }
        }
      }

      this.$emit('createOrder', {
        // ordererDto: this.staticForm.ordererDto,
        // billingAddressDto: this.staticForm.billingAddressDto,
        // pickUpInStore: this.pickUpInStore,
        // orderType: this.selectedOrderType,
        articleNumber: this.articleNumber,
        // shippingAddressDto: this.staticForm.shippingAddressDto,
        size: this.sizzee,
        productInStockMaterialVariantRequestDtos: object2,
        message: this.description,
      });
      this.$refs.modal.hide();
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      setTimeout(() => {
        this.resetForm();
      }, 10);
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      setTimeout(() => {
        this.resetForm();
        this.materials = [];
      }, 10);
    },
    resetShippingAddress() {
      // if (this.pickUpInStore == true) {
      // this.$v.staticForm.shippingAddressDto.$anyError = true;
      //   this.error.oType = ''
      this.$v.staticForm.shippingAddressDto.$reset()
      // }
      // this.$refs.modal.hide();
    },
    resetForm() {
      // Reset our form values
      this.addedArticle = []
      this.customDresses = []
      this.forms = []
      this.searchClients = null
      this.clientId = '00000000-0000-0000-0000-000000000000'
      this.error.oType = ''
      this.resetCustomerData();
      this.resetCustomOrders();
      this.isSubmitClicked = false;
      this.description = '';
    },
    resetCustomerData() {
      this.staticForm.ordererDto.salutation = '';
      this.staticForm.ordererDto.firstName = '';
      this.staticForm.ordererDto.lastName = '';
      this.staticForm.ordererDto.email = '';
      this.staticForm.ordererDto.gender = '';
      this.staticForm.ordererDto.telephoneNumber = '';

      this.staticForm.billingAddressDto.street = '';
      this.staticForm.billingAddressDto.houseNumber = '';
      this.staticForm.billingAddressDto.doorNumber = '';
      this.staticForm.billingAddressDto.postalCode = '';
      this.staticForm.billingAddressDto.city = '';
      this.staticForm.billingAddressDto.country = '';

      this.staticForm.shippingAddressDto.street = '';
      this.staticForm.shippingAddressDto.houseNumber = '';
      this.staticForm.shippingAddressDto.doorNumber = '';
      this.staticForm.shippingAddressDto.postalCode = '';
      this.staticForm.shippingAddressDto.city = '';
      this.staticForm.shippingAddressDto.country = '';

      this.pickUpInStore = false;
      this.selectedOrderType = null;
      this.isPhoneNumberValid = null;
      this.searchClients = null
      this.clientId = '00000000-0000-0000-0000-000000000000'
    },

  },
};
</script>

<style lang="scss" scoped>
.price-container {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.price-text {
  margin-left: 0px;
  font-size: 18px;
  // font-weight: bold;
  color: black;
  border-radius: 10px;
}
.search1 {
    padding: 0px;
  }
.black {
  position: relative;
}
.black input {
  border: none;
  box-shadow: none;;
  height: 36px;

}
.dropdrop::-webkit-scrollbar {
display: none;

}
.dropdrop {
display: inline-block;
overflow: auto;
position: absolute;
background: white;
width: 227px;
z-index: 2;
box-shadow: none;
}
.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}
.search-item:last-child {
  border-bottom: none;
}
.search-item:hover {
  background: #82868c;
  color: white;
}
.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}
.is-active {
  background-color: #dedede;
}
form {
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}


.headline {
  padding: 5px 10px;
  background-color: $base-color;
  color: $digit-white;
  border-radius: 8px;
}

.custom-modal__wrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px 0px;

  >div {
    flex: 1;
    margin: 0px 15px;
  }
}

.flex-form {
  display: flex;
  flex-direction: row;
}

.fixed-height-modal {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  padding-right: 1rem;
}

.customDress-container {
  border: 3px solid lightgray;
  border-radius: 10px;
  padding: 15px;
  margin-top: 20px;
}

.customDress-container:last-child {
  margin-bottom: 20px;
}

.inside-actions {
  margin-left: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  box-shadow: none;
  padding: 2px 4px;
  border-radius: 7px;
}

.inside-actions:hover {
  background-color: #242F6E;
  color: #fff;
}

.f {
  border-color: red !important;
}
</style>
