<template>
  <div>
    <b-modal
      id="modal-add-size"
      ref="modal"
      header-class="headerModal"
      centered
      size="lg"
      :title="`${$t('SubmitYourSize')} ${materials.name} ${$t('WithColor')} ${colorData.colorName}`"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <div style="display: flex; flex-direction: row;gap:5px;flex-wrap: wrap;">
          <b-form-group
            id="input-select-1"
            :label="`${$t('Selectdresssize')}:`"
            label-for="select-1"
            style="width: 48%;display: flex;flex-direction: column; justify-content: flex-end;"
          >
            <vue-select
              v-model="sizes"
              :options="getProductSize"
              :reduce="e => e.name"
              :multiple="true"
              required
              :placeholder="$t('SelectSize')"
              label="name"
            />
            <p
              v-if="$v.sizes.$error"
              style="color: red"
            >
              {{ $t('SelectSizeOption') }}
            </p>
          </b-form-group>
          <b-form-group
            :label="`${$t('Code')}:`"
            style="width: 38%;"
          >
            <b-input
              v-model="code"
              :placeholder="$t('Code')"
              @input="callCode(code)"
            />
            <p v-if="getIsCodePersisted == true" style="color:red;margin:0px;"> {{ $t('Enter unique code') }}</p>
          </b-form-group>
          <b-form-group
            id="input-select-1"
            :label="`${$t('Price')}:`"
            label-for="select-1"
            style="width: 18%;"
          >
            <b-form-input
              v-model.number="price"
              required
              :placeholder="$t('Price')"
              type="number"
              label="size"
            />
          </b-form-group>
          <b-form-group
            id="input-select-1"
            :label="`${$t('PurchasePrice')}:`"
            label-for="select-1"
            style="width: 18%;"
          >
            <b-form-input
              v-model.number="purchasePrice"
              required
              :placeholder="$t('Price')"
              type="number"
              label="size"
            />
          </b-form-group>
          <b-form-group
            id="input-select-1"
            :label="`${$t('Currency')}:`"
            label-for="select-1"
            style="margin-left: 2px; width: 12%;"
          >
            <vue-select
              v-model="currency"
              :options="currencies"
              required
              label="currency"
            />
          </b-form-group>
        </div>
        <b-form-group />
        <div
          class="modal-card__wrapper"
          style="align-items: center"
        >
          <div
            v-for="(formItem, k) in form"
            :key="k"
            class="modal-card"
          >
            <b-form-group
              id="input-group-3"
              :label="`${$t('SelectMaterialType')}:`"
            >
              <i
                class="modal-card__remove"
                @click="removeMaterialItem(formItem.id)"
              ><b-icon-trash /></i>
              <div class="flex radio-form">
                <div style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 10px;">
                  <div
                    class="flex aic"
                    @click="resetMeter(formItem)"
                  >
                    <input
                      :id="`one${k}`"
                      v-model="formItem.measurementType"
                      type="radio"
                      value="Meter"
                      required
                    >
                    <label :for="`one${k}`">{{ $t('Meter') }}</label>
                  </div>
                  <div
                    class="flex aic"
                    @click="resetPackage(formItem)"
                  >
                    <input
                      :id="`one${k}`"
                      v-model="formItem.measurementType"
                      type="radio"
                      value="Package"
                      required
                    >
                    <label :for="`one${k}`">{{ $t('Package') }}</label>
                  </div>
                  <div
                    class="flex aic"
                    @click="resetSet(formItem)"
                  >
                    <input
                      :id="`one${k}`"
                      v-model="formItem.measurementType"
                      type="radio"
                      value="Set"
                      required
                    >
                    <label :for="`one${k}`">{{ $t('Set') }}</label>
                  </div>
                  <div
                    class="flex aic"
                    @click="resetPair(formItem)"
                  >
                    <input
                      :id="`one${k}`"
                      v-model="formItem.measurementType"
                      type="radio"
                      value="Pair"
                      required
                    >
                    <label :for="`one${k}`">{{ $t('Pair') }}</label>
                  </div>
                  <div
                    class="flex aic"
                    @click="resetVerse(formItem)"
                  >
                    <input
                      :id="`one${k}`"
                      v-model="formItem.measurementType"
                      type="radio"
                      value="Verse"
                      required
                    >
                    <label :for="`one${k}`">{{ $t('BeadedStrand') }}</label>
                  </div>
                  <div
                    class="flex aic"
                    @click="resetPart(formItem)"
                  >
                    <input
                      :id="`two${k}`"
                      v-model="formItem.measurementType"
                      type="radio"
                      value="Parts"
                      required
                    >
                    <label :for="`two${k}`">{{ $t('Part') }}</label>
                  </div>
                </div>
              </div>
            </b-form-group>

            <div class="search-toggle">

              <p>{{ $t('SearchBy') }}</p>
              <div>
                <p
                  :class="{ active2: toggleSearch === 'MaterialName' }"
                  @click="showMaterialName(), toggleSearch = 'MaterialName'"
                >
                  {{ $t('Name') }}
                </p>
                <p
                  :class="{ active2: toggleSearch === 'Description' }"
                  @click="showDescription(), toggleSearch = 'Description'"
                >
                  {{ $t('Description') }}
                </p>
              </div>
            </div>

            <b-form-group
              id="input-select-1"
              label-for="select-1"
            >
              <vue-select
                v-if="formItem.measurementType === 'Meter' && toggleSearch === 'MaterialName'"
                v-model="formItem.materialVariantId"
                :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByMeter : getMaterialVariantColorNameLike"
                :placeholder="$t('SelectMaterialVariant')"
                label="materialVariantName"
                required
                @search="onSearchM"
                @input="currentStock(formItem.materialVariantId.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center"
                >
                  <button
                    type="button"
                    :style="pageM == 1 ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #242f6e; color: white'"
                    :disabled="pageM == 1"
                    @click="prevMeter"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariant != '' ? pageM == getTotalItemsPages : pageM == getTotalPagesForMVM "
                    @click="nextMeter"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="formItem.measurementType === 'Meter' && toggleSearch === 'Description'"
                v-model="formItem.materialVariantId"
                :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByMeter : getFilterVariantsByCodeLike"
                :placeholder="$t('Description')"
                label="materialVariantName"
                required
                @search="onSearchMDesc"
                @input="currentStock(formItem.materialVariantId.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center"
                >
                  <button
                    type="button"
                    :style="pageM == 1 ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #242f6e; color: white'"
                    :disabled="pageM == 1"
                    @click="prevMeter"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariant != '' ? pageM == getTotalItemsPages : pageM == getTotalPagesForMVM "
                    @click="nextMeter"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="formItem.measurementType === 'Package' && toggleSearch === 'MaterialName'"
                v-model="formItem.materialVariantId"
                :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByPackage : getMaterialVariantColorNameLike"
                :placeholder="$t('SelectMaterialVariant')"
                label="materialVariantName"
                required
                @search="onSearchPack"
                @input="currentStock(formItem.materialVariantId.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center;"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pagePack == 1"
                    @click="prevPackage"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantPackage != '' ? pagePack == getTotalItemsPages : pagePack == getTotalPagesForMVM "
                    @click="nextPackage"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="formItem.measurementType === 'Package' && toggleSearch === 'Description'"
                v-model="formItem.materialVariantId"
                :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByPackage : getFilterVariantsByCodeLike"
                :placeholder="$t('Description')"
                label="materialVariantName"
                required
                @search="onSearchPackDesc"
                @input="currentStock(formItem.materialVariantId.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center;"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pagePack == 1"
                    @click="prevPackage"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantPackage != '' ? pagePack == getTotalItemsPages : pagePack == getTotalPagesForMVM "
                    @click="nextPackage"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="formItem.measurementType === 'Set' && toggleSearch === 'MaterialName'"
                v-model="formItem.materialVariantId"
                :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVBySet : getMaterialVariantColorNameLike"
                :placeholder="$t('SelectMaterialVariant')"
                label="materialVariantName"
                required
                @search="onSearchSet"
                @input="currentStock(formItem.materialVariantId.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center;"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pageSet == 1"
                    @click="prevSet"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantSet != '' ? pageSet == getTotalItemsPages : pageSet == getTotalPagesForMVM "
                    @click="nextSet"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="formItem.measurementType === 'Set' && toggleSearch === 'Description'"
                v-model="formItem.materialVariantId"
                :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVBySet : getFilterVariantsByCodeLike"
                :placeholder="$t('Description')"
                label="materialVariantName"
                required
                @search="onSearchSetDesc"
                @input="currentStock(formItem.materialVariantId.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center;"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pageSet == 1"
                    @click="prevSet"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantSet != '' ? pageSet == getTotalItemsPages : pageSet == getTotalPagesForMVM "
                    @click="nextSet"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="formItem.measurementType === 'Pair' && toggleSearch === 'MaterialName'"
                v-model="formItem.materialVariantId"
                :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByPair : getMaterialVariantColorNameLike"
                :placeholder="$t('SelectMaterialVariant')"
                label="materialVariantName"
                required
                @search="onSearchPair"
                @input="currentStock(formItem.materialVariantId.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center;"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pagePair == 1"
                    @click="prevPair"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantPair != '' ? pagePair == getTotalItemsPages : pagePair == getTotalPagesForMVM "
                    @click="nextPair"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="formItem.measurementType === 'Pair' && toggleSearch === 'Description'"
                v-model="formItem.materialVariantId"
                :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByPair : getFilterVariantsByCodeLike"
                :placeholder="$t('Description')"
                label="materialVariantName"
                required
                @search="onSearchPairDesc"
                @input="currentStock(formItem.materialVariantId.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center;"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pagePair == 1"
                    @click="prevPair"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantPair != '' ? pagePair == getTotalItemsPages : pagePair == getTotalPagesForMVM "
                    @click="nextPair"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="formItem.measurementType === 'Verse' && toggleSearch === 'MaterialName'"
                v-model="formItem.materialVariantId"
                :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByVerse : getMaterialVariantColorNameLike"
                :placeholder="$t('SelectMaterialVariant')"
                label="materialVariantName"
                required
                @search="onSearchVerse"
                @input="currentStock(formItem.materialVariantId.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center;"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pageVerse == 1"
                    @click="prevVerse"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantVerse != '' ? pageVerse == getTotalItemsPages : pageVerse == getTotalPagesForMVM "
                    @click="nextVerse"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>

              <vue-select
                v-else-if="formItem.measurementType === 'Verse' && toggleSearch === 'Description'"
                v-model="formItem.materialVariantId"
                :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByVerse : getFilterVariantsByCodeLike"
                :placeholder="$t('Description')"
                label="materialVariantName"
                required
                @search="onSearchVerseDesc"
                @input="currentStock(formItem.materialVariantId.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center;"
                >
                  <button
                    type="button"
                    :style="
                      pageM == 1
                        ? 'width: 50%; border: none;'
                        : 'width: 50%; border: none; background-color: #242F6E; color: white'
                    "
                    :disabled="pageVerse == 1"
                    @click="prevVerse"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantVerse != '' ? pageVerse == getTotalItemsPages : pageVerse == getTotalPagesForMVM "
                    @click="nextVerse"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>

              <vue-select
                v-else-if="formItem.measurementType === 'Parts' && toggleSearch === 'MaterialName'"
                v-model="formItem.materialVariantId"
                :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByPart : getMaterialVariantColorNameLike"
                :placeholder="$t('SelectMaterialVariant')"
                label="materialVariantName"
                style="-ms-word-break: break-all; word-break: break-all; word-break: break-word;"
                required
                @search="onSearchP"
                @input="currentStock(formItem.materialVariantId.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center"
                >
                  <button
                    type="button"
                    :style="pageP == 1 ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #0b0033; color: white'"
                    :disabled="pageP == 1"
                    @click="prevPart"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    type="button"
                    :style="getButtonStylePart"
                    :disabled="selectedMaterialVariantPart != '' ? pageP == getTotalItemsPages : pageP == getTotalPagesForMVP "
                    @click="nextPart"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="formItem.measurementType === 'Parts' && toggleSearch === 'Description'"
                v-model="formItem.materialVariantId"
                :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByPart : getFilterVariantsByCodeLike"
                :placeholder="$t('Description')"
                label="materialVariantName"
                style="-ms-word-break: break-all; word-break: break-all; word-break: break-word;"
                required
                @search="onSearchPDesc"
                @input="currentStock(formItem.materialVariantId.materialVariantId)"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center"
                >
                  <button
                    type="button"
                    :style="pageP == 1 ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #0b0033; color: white'"
                    :disabled="pageP == 1"
                    @click="prevPart"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    type="button"
                    :style="getButtonStylePart"
                    :disabled="selectedMaterialVariantPart != '' ? pageP == getTotalItemsPages : pageP == getTotalPagesForMVP "
                    @click="nextPart"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <p
                v-if="errors.materialVariantError"
                style="color: red"
              >
                {{ $t('SelectMaterialVariant') }}
              </p>
            </b-form-group>
            <b-form-group
              v-if="formItem.materialVariantId"
              :label="`${$t('Price')}:`"
              style="width: 100%;"
            >
              <b-input
                v-model="formItem.materialVariantId.pricePerMeter"
                type="number"
                disabled
              />
            </b-form-group>
            <b-form-group
              v-if="formItem.measurementType === 'Meter'"
              :label="`${$t('Meter')}:`"
            >
              <b-input
                v-model="formItem.length"
                type="number"
                required
              />
              <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                {{ formItem.materialVariantId ? `${'Price total'}: ${totalPriceCalc(formItem.materialVariantId ? formItem.materialVariantId.pricePerMeter : null, formItem.length)}€` : null }}
              </p>
            </b-form-group>
            <b-form-group
              v-else-if="formItem.measurementType === 'Package'"
              :label="`${$t('Meter')}:`"
            >
              <b-input
                v-model="formItem.length"
                type="number"
                required
              />
              <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                {{ formItem.materialVariantId ? `${'Price total'}: ${totalPriceCalc(formItem.materialVariantId ? formItem.materialVariantId.pricePerMeter : null, formItem.length)}€` : null }}
              </p>
            </b-form-group>
            <b-form-group
              v-else-if="formItem.measurementType === 'Set'"
              :label="`${$t('Meter')}:`"
            >
              <b-input
                v-model="formItem.length"
                type="number"
                required
              />
              <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                {{ formItem.materialVariantId ? `${'Price total'}: ${totalPriceCalc(formItem.materialVariantId ? formItem.materialVariantId.pricePerMeter : null, formItem.length)}€` : null }}
              </p>
            </b-form-group>
            <b-form-group
              v-else-if="formItem.measurementType === 'Pair'"
              :label="`${$t('Meter')}:`"
            >
              <b-input
                v-model="formItem.length"
                type="number"
                required
              />
              <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                {{ formItem.materialVariantId ? `${'Price total'}: ${totalPriceCalc(formItem.materialVariantId ? formItem.materialVariantId.pricePerMeter : null, formItem.length)}€` : null }}
              </p>
            </b-form-group>
            <b-form-group
              v-else-if="formItem.measurementType === 'Verse'"
              :label="`${$t('Meter')}:`"
            >
              <b-input
                v-model="formItem.length"
                type="number"
                required
              />
              <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                {{ formItem.materialVariantId ? `${'Price total'}: ${totalPriceCalc(formItem.materialVariantId ? formItem.materialVariantId.pricePerMeter : null, formItem.length)}€` : null }}
              </p>
            </b-form-group>
            <b-form-group
              v-else
              :label="`${$t('Part')}:`"
            >
              <b-input
                v-model="formItem.quantity"
                type="number"
                required
              />
              <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
                {{ formItem.materialVariantId ? `${'Price total'}: ${totalPriceCalc(formItem.materialVariantId ? formItem.materialVariantId.pricePerMeter : null, formItem.quantity)}€` : null }}
              </p>
            </b-form-group>
            <b-form-group
              v-if="formItem.materialVariantId ? formItem.materialVariantId.materialVariantId === clickedMaterialVariantId : false"
              :label="`${$t('Stock')}:`"
              style="width: 100%;"
            >
              <b-form-input
                v-model="getSavedStock.stockValue"
                type="number"
                disabled
              />
            </b-form-group>

          </div>
          <b-button
            type="button"
            style="margin: 5px 0; background: #FF274F;"
            size="sm"
            @click="addNewRow"
          >
            <p style="margin: 0">
              + {{ $t('AddMaterial') }}
            </p>
          </b-button>
        </div>
        <b-form-group />
        <p
          v-if="errors.duplicateError"
          style="color: red;"
        >
          {{ $t('DuplicateMaterial') }}
        </p>
        <b-form-group />
        <p
          v-if="totalCost != 0.00"
          style="font-weight: bold;"
        >
          {{ $t('TotalCost') }}: {{ totalCost }}€
        </p>
        <div class="buttonsEverywhere">
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            style="margin-left: 5px; background: #FF274F !important;"
            :disabled="getIsCodePersisted == true"

            @click="onSubmit"
          >
            {{ $t('Submit') }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="background: white; color: black; border-color: white;font-weight: 400;"

            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate';
import {
  required,
} from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],

  // eslint-disable-next-line vue/require-prop-types
  props: ['colorData', 'materials', 'materialEditItem', 'measureItem'],
  data() {
    return {
      toggleSearch: 'MaterialName',
      clickedMaterialVariantId: null,
      selectedMaterialVariantPackage: '',
      selectedMaterialVariant: '',
      selectedMaterialVariantPart: '',
      selectedMaterialVariantSet: '',
      selectedMaterialVariantPair: '',
      selectedMaterialVariantVerse: '',
      partOptions: [],
      meterOptions: [],
      isUndefined: false,
      price: 0,
      purchasePrice: 0,
      code: null,
      totalItems: '',
      sizeOptions: [
        {
          size: 'ALL',
        },
        {
          size: 'xs',
        },
        {
          size: 's',
        },
        {
          size: 'm',
        },
        {
          size: 'l',
        },
        {
          size: 'xl',
        },
      ],
      currency: '€',
      currencies: ['€', '$', 'CHF'],
      errors: {
        duplicateError: false,
      },
      options: [],
      selected: null,
      sizes: [],
      pageM: 1,
      pageP: 1,
      pagePack: 1,
      pageSet: 1,
      pagePair: 1,
      pageVerse: 1,
      pageSize: 15,
      form: [
        {
          id: `s${Math.floor(Math.random() * 12000)}`,
          materialVariantId: '',
          measurementType: 'Meter',
          name: '',
          length: 0,
          quantity: 0,
        },
      ],
    }
  },
  validations: {
    sizes: {
      required,
    },
    sizeOptions: {
      required,
    },
    // form: {
    //   0: {
    //     materialVariantId: {
    //       required,
    //     },
    //   },
    // },
  },
  computed: {
    ...mapGetters([
      'getProductSize',
      'getSizeTypes',
      'getCurrentPageForMVM',
      'getTotalPagesForMVM',
      'getCurrentPageForMVP',
      'getTotalPagesForMVP',
      'getFilteredMV',
      'getMaterialsVByMeter',
      'getMaterialsVByPart',
      'getMaterialVariantColorNameLike',
      'getMaterialVaraintsToAddMainDressCategoryColorNameLike',
      'getTotalItemsPages',
      'getMaterialsVByPackage',
      'getMaterialsVBySet',
      'getMaterialsVByVerse',
      'getIsCodePersisted',
      'getMaterialsVByPair',
      'getSavedStock',
      'getFilterVariantsByCodeLike',
    ]),
    getButtonStyle() {
      if (this.selectedMaterialVariant !== '') {
        return this.pageM == this.getTotalItemsPages
          ? { width: '50%', border: 'none' }
          : {
            width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
          };
      }
      return this.pageM == this.getTotalPagesForMVM
        ? { width: '50%', border: 'none' }
        : {
          width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
        };
    },
    getButtonStylePart() {
      if (this.selectedMaterialVariantPart !== '') {
        return this.pageM == this.getTotalItemsPages
          ? { width: '50%', border: 'none' }
          : {
            width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
          };
      }
      return this.pageM == this.getTotalPagesForMVP
        ? { width: '50%', border: 'none' }
        : {
          width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
        };
    },
    totalCost() {
    // Initialize a variable to store the total cost
      let total = 0;

      // Iterate through each item in dress.formItem
      // eslint-disable-next-line no-restricted-syntax
      for (const item of this.form) {
        if (item.materialVariantId) {
        // Check if the item has a name (material variant selected)
          if (item.measurementType === 'Meter') {
          // For items measured in meters, calculate the total cost
            total += item.materialVariantId.pricePerMeter * item.length;
          } else if (item.measurementType === 'Package') {
          // For items measured in meters, calculate the total cost
            total += item.materialVariantId.pricePerMeter * item.length;
          } else if (item.measurementType === 'Set') {
          // For items measured in meters, calculate the total cost
            total += item.materialVariantId.pricePerMeter * item.length;
          } else if (item.measurementType === 'Pair') {
          // For items measured in meters, calculate the total cost
            total += item.materialVariantId.pricePerMeter * item.length;
          } else if (item.measurementType === 'Verse') {
          // For items measured in meters, calculate the total cost
            total += item.materialVariantId.pricePerMeter * item.length;
          } else {
          // For items measured in parts, calculate the total cost
            total += item.materialVariantId.pricePerMeter * item.quantity;
          }
        }
      }

      // Return the total cost with 2 decimal places and in the correct format
      return `${total.toFixed(2)}`;
    },
  },
  watch: {
    form: {
      handler(newValue) {
        console.log('lala', this.form)
        // eslint-disable-next-line no-restricted-syntax
        for (const item of this.form) {
          if (item.materialVariantId.materialVariantId == undefined) {
            this.isUndefined = true
            return;
            // eslint-disable-next-line no-else-return
          } else {
            this.isUndefined = false
          }
        }
        const uniqueValues = new Set(newValue.map((v) => { return v.materialVariantId.materialVariantId }))
        if (uniqueValues.sizes < newValue.length) {
          this.errors.duplicateError = true
        } else {
          this.errors.duplicateError = false
        }
      },
      deep: true,
    },
    measureItem() {
      this.form.measurementType = this.measureItem
    },
    materialEditItem() {
      this.fillFormEdit()
    },
  },
  mounted() {
    this.loadSizeTypes();
    this.productSizes()
    // this.getMVbyMeter(this.pageM, this.pageSize);
    // this.getMVbyPart(this.pageP, this.pageSize);
  },
  methods: {
    ...mapActions(['productSizes', 'resetCodePersisted', 'currentStockByVariant', 'resetAllFiltered',
      'getMaterialVariantsToMDCPagination', 'loadSizeTypes',
      'loadMaterialVaraintsCategoryNameLike', 'loadCodePersisted', 'testResetMV', 'filterVariantByColor', 'filterVariantByCode']),
    // validateState(length) {
    //   const { $dirty, $error } = this.$v.form[length];
    //   return $dirty ? !$error : null;
    // },
    callCode(value) {
      clearTimeout(this.debounceTimeout);

      this.debounceTimeout = setTimeout(() => {
        this.loadCodePersisted({
          code: value,
        });
      }, 300);
    },
    showMaterialName() {
      this.toggleSearch = 'MaterialName'
    },
    showDescription() {
      this.toggleSearch = 'Description'
    },
    totalPriceCalc(priceWithDiscount, length) {
      return (priceWithDiscount * length).toFixed(2);
    },
    currentStock(value) {
      this.clickedMaterialVariantId = value
      this.currentStockByVariant(value)
    },
    addNewRow() {
      this.form.push({
        id: `s${Math.floor(Math.random() * 12000)}`,
        materialVariantId: '',
        name: '',
        measurementType: 'Meter',
        length: 0,
        quantity: 0,
      })
    },
    resetMeter(value) {
      value.materialVariantId = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Meter',
      })
    },
    resetPart(value) {
      value.materialVariantId = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Part',
      })
    },
    resetPackage(value) {
      value.materialVariantId = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Package',
      })
    },
    resetSet(value) {
      value.materialVariantId = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Set',
      })
    },
    resetPair(value) {
      value.materialVariantId = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Pair',
      })
    },
    resetVerse(value) {
      value.materialVariantId = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Verse',
      })
    },

    removeMaterialItem(id) {
      this.form = this.form.filter((item) => { return item.id !== id })
    },
    async getMVbyMeter(page, pageS) {
      // const { data } =
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Meter',
      });
    },
    async getMVbyPackage(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Package',
      });
    },
    async getMVbySet(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Set',
      });
    },
    async getMVbyVerse(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Verse',
      });
    },
    async getMVbyPair(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Pair',
      });
    },
    async getMVbyPart(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Part',
      });
    },

    onSubmit() {
      this.$v.sizes.$touch();
      if (this.$v.sizes.$anyError) {
        return;
      }

      const materialVariants = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const item of this.form) {
        if (!item.materialVariantId || !item.materialVariantId.materialVariantId) {
          this.errors.materialVariantError = true;
          return;
        }

        materialVariants.push({
          name: item.materialVariantId.materialVariantName,
          measurementType: item.measurementType,
          materialVariantId: item.materialVariantId.materialVariantId,
          length: item.length,
          quantity: item.quantity,
        });
      }

      if (this.errors.duplicateError) {
        return;
      }

      // Clear materialVariantError before emitting the data
      this.errors.materialVariantError = false;

      this.$emit('variantMaterialData', {
        sizes: this.sizes,
        price: this.price,
        purchasePrice: this.purchasePrice,
        code: this.code,
        currency: this.currency,
        materialVariantDtos: materialVariants,
      });

      this.$refs.modal.hide();
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
      this.$nextTick(() => {
        this.$v.$reset();
      });
      setTimeout(() => {
        this.resetForm();
      }, 10);
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      setTimeout(() => {
        this.resetForm()
      }, 10)
    },
    resetForm() {
      this.resetCodePersisted()
      this.errors.materialVariantError = false
      // Reset our form values
      this.sizes = ''
      this.price = 0
      this.purchasePrice = 0
      this.currency = '€'
      this.code = null
      this.form = [{
        materialVariantId: '',
        measurementType: 'Meter',
        name: '',
        length: 0,
        quantity: 0,
      }]
    },
    nextMeter() {
      if (this.pageM < this.getTotalPagesForMVM && this.selectedMaterialVariant == '') {
        this.pageM += 1;
        this.getMVbyMeter(this.pageM, this.pageSize);
      } else if (this.pageM < this.getTotalPagesForMVM && this.selectedMaterialVariant != '') {
        this.pageM += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariant,
          measurementType: 'Meter',
          pageNumber: this.pageM,
          pageSize: 15,
        });
      }
    },
    prevMeter() {
      if (this.pageM > 1 && this.selectedMaterialVariant == '') {
        this.pageM -= 1;
        this.getMVbyMeter(this.pageM, this.pageSize);
      } else if (this.pageM > 1 && this.selectedMaterialVariant != '') {
        this.pageM -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariant,
          measurementType: 'Meter',
          pageNumber: this.pageM,
          pageSize: 15,
        })
      }
    },
    nextPackage() {
      if (this.pagePackage < this.getTotalPagesForMVM && this.selectedMaterialVariantPackage == '') {
        this.pagePackage += 1;
        this.getMVbyPackage(this.pagePackage, this.pageSize);
      } else if (this.pagePackage < this.getTotalPagesForMVM && this.selectedMaterialVariantPackage != '') {
        this.pagePackage += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPackage,
          measurementType: 'Package',
          pageNumber: this.pagePackage,
          pageSize: 15,
        });
      }
    },
    prevPackage() {
      if (this.pagePackage > 1 && this.selectedMaterialVariantPackage == '') {
        this.pagePackage -= 1;
        this.getMVbyPackage(this.pagePackage, this.pageSize);
      } else if (this.pagePackage > 1 && this.selectedMaterialVariantPackage != '') {
        this.pagePackage -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPackage,
          measurementType: 'Package',
          pageNumber: this.pagePackage,
          pageSize: 15,
        });
      }
    },
    nextSet() {
      if (this.pageSet < this.getTotalPagesForMVM && this.selectedMaterialVariantSet == '') {
        this.pageSet += 1;
        this.getMVbySet(this.pageSet, this.pageSize);
      } else if (this.pageSet < this.getTotalPagesForMVM && this.selectedMaterialVariantSet != '') {
        this.pageSet += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantSet,
          measurementType: 'Set',
          pageNumber: this.pageSet,
          pageSize: 15,
        });
      }
    },
    prevSet() {
      if (this.pageSet > 1 && this.selectedMaterialVariantSet == '') {
        this.pageSet -= 1;
        this.getMVbySet(this.pageSet, this.pageSize);
      } else if (this.pageSet > 1 && this.selectedMaterialVariantSet != '') {
        this.pageSet -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantSet,
          measurementType: 'Set',
          pageNumber: this.pageSet,
          pageSize: 15,
        });
      }
    },
    nextVerse() {
      if (this.pageVerse < this.getTotalPagesForMVM && this.selectedMaterialVariantVerse == '') {
        this.pageVerse += 1;
        this.getMVbyVerse(this.pageVerse, this.pageSize);
      } else if (this.pageVerse < this.getTotalPagesForMVM && this.selectedMaterialVariantVerse != '') {
        this.pageVerse += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantVerse,
          measurementType: 'Verse',
          pageNumber: this.pageVerse,
          pageSize: 15,
        });
      }
    },
    prevVerse() {
      if (this.pageVerse > 1 && this.selectedMaterialVariantVerse == '') {
        this.pageVerse -= 1;
        this.getMVbyVerse(this.pageVerse, this.pageSize);
      } else if (this.pageVerse > 1 && this.selectedMaterialVariantVerse != '') {
        this.pageVerse -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantVerse,
          measurementType: 'Verse',
          pageNumber: this.pageVerse,
          pageSize: 15,
        });
      }
    },
    nextPair() {
      if (this.pagePair < this.getTotalPagesForMVM && this.selectedMaterialVariantPair == '') {
        this.pagePair += 1;
        this.getMVbyPair(this.pagePair, this.pageSize);
      } else if (this.pagePair < this.getTotalPagesForMVM && this.selectedMaterialVariantPair != '') {
        this.pagePair += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPair,
          measurementType: 'Pair',
          pageNumber: this.pagePair,
          pageSize: 15,
        });
      }
    },
    prevPair() {
      if (this.pagePair > 1 && this.selectedMaterialVariantPair == '') {
        this.pagePair -= 1;
        this.getMVbyPair(this.pagePair, this.pageSize);
      } else if (this.pagePair > 1 && this.selectedMaterialVariantPair != '') {
        this.pagePair -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPair,
          measurementType: 'Pair',
          pageNumber: this.pagePair,
          pageSize: 15,
        });
      }
    },
    nextPart() {
      if (this.pageP < this.getTotalPagesForMVP && this.selectedMaterialVariant == '') {
        this.pageP += 1;
        this.getMVbyPart(this.pageP, this.pageSize);
      } else if (this.pageP < this.getTotalPagesForMVP && this.selectedMaterialVariant != '') {
        this.pageP += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariant,
          measurementType: 'Part',
          pageNumber: this.pageP,
          pageSize: 15,
        });
      }
    },
    prevPart() {
      if (this.pageP > 1 && this.selectedMaterialVariant == '') {
        this.pageP -= 1;
        this.getMVbyPart(this.pageP, this.pageSize);
      } else if (this.pageP > 1 && this.selectedMaterialVariant != '') {
        this.pageP -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariant,
          measurementType: 'Part',
          pageNumber: this.pageP,
          pageSize: 15,
        });
      }
    },
    async onSearchM(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyMeter(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariant = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariant = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Meter',
            pageNumber: this.pageM,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchMDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyMeter(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariant = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariant = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Meter',
            pageNumber: this.pageM,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchPack(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPackage(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPackage = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantPackage = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Package',
            pageNumber: this.pagePack,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchPackDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPackage(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPackage = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantPackage = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Package',
            pageNumber: this.pagePack,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchSet(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVBySet(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantSet = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantSet = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Set',
            pageNumber: this.pageSet,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchSetDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVBySet(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantSet = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantSet = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Set',
            pageNumber: this.pageSet,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchPair(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByPair(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPair = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantPair = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Pair',
            pageNumber: this.pagePair,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchPairDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByPair(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPair = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantPair = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Pair',
            pageNumber: this.pagePair,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchVerse(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByVerse(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantVerse = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantVerse = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Verse',
            pageNumber: this.pageVerse,
            pageSize: 15,
          });
        }
      }, 500);
    },

    async onSearchVerseDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByVerse(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantVerse = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantVerse = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Verse',
            pageNumber: this.pageVerse,
            pageSize: 15,
          })
        }
      }, 500);
    },

    async onSearchP(query) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPart(this.getCurrentPageForMVP, this.pageSize);
          this.selectedMaterialVariantPart = ''
          this.pageP = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantPart = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Part',
            pageNumber: this.pageP,
            pageSize: 15,
          });
        }
      }, 500);
    },

    async onSearchPDesc(query) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPart(this.getCurrentPageForMVP, this.pageSize);
          this.selectedMaterialVariantPart = ''
          this.pageP = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantPart = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Part',
            pageNumber: this.pageP,
            pageSize: 15,
          })
        }
      }, 500);
    },
  },
}
</script>

<style lang="scss" scoped>
.active2 {
  font-weight: bold;
  color: $base-color;
}
.search-toggle {
  margin: 5px 0px;
}
  form {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .flex-form {
    display: flex;
    flex-direction: column;
  }
 input[type='radio']:checked:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -3px;
        left: -1px;
        position: relative;
        background-color: $base-color ;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
    }
</style>
