<template>
  <div
    class="location"
    style="border: 1px solid lightgray; margin-top: 47px"
  >
    <div style="width: 100%;">
      <table
        class="team_table"
        style="width: 100%; box-shadow: none; font-size: 13px;cursor:auto"
      >
        <thead>
          <tr>
            <th>
              {{ $t('Location') }}
            </th>
            <th>
              {{ $t('Amount') }}
            </th>
            <th>
              {{ $t('AddToOrder') }}
            </th>
          </tr>
        </thead>
        <tbody v-if="getTotalStockGroupByLocation.length > 0">
          <tr
            v-for="(article, index) in getTotalStockGroupByLocation"
            :key="index"
          >
            <td>{{ $t(article.locationName) }}</td>
            <td>{{ $t(article.quantity) }}</td>
            <td
              v-b-modal.add-quantity
              @click="callQuantity(article)"
            >
              <button
                class="button"
              >
                {{ $t('AddToOrder') }}
              </button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td
              colspan="5"
            >
              {{ $t('NoLocationFound') }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <AddQuantityOrder
      :article-name="articleName"
      :location-values="locationValues"
      @addQuantityToOrder="addToOrder"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import AddQuantityOrder from './modals/AddQuantityOrder.vue'

export default {
  components: {
    AddQuantityOrder,
  },
  props: ['articleName', 'articleList'],
  data() {
    return {
      location: null,
      locationValues: null,
    }
  },
  computed: {
    ...mapGetters(['getTotalStockGroupByLocation']),
  },
  watch: {
  },
  methods: {
    ...mapActions([]),
    callQuantity(value) {
      this.locationValues = value
    },
    addToOrder(value) {
      const object = {
        ...this.locationValues,
        ...this.articleName,
        quantity: value,
        discount: 0,
        currency: '$',
        pickedUpDate: moment().format('YYYY-MM-DD'),
        typeOfArticle: 'Stock article',
        valueWithVAT: this.articleName.sellPrice * value,
        priceWithVAT: this.articleName.sellPrice,
        priceWithoutVAT: parseFloat((this.articleName.sellPrice / (1 + 18 / 100)).toFixed(2)),
        selectedVAT: 18,
        validationPriceWithoutVAT: false,
        validationamount: false,
        validationPriceWithVAT: false,
        validationValueWithVAT: false,
        discountedPrice: 0,
        discountType: '€',
      }
      this.$emit('addToOrder', object)
    },
  },
}
</script>

<style scoped>
.team_table td {
  padding: 10px 9px !important;
}
.location {
  width: 50%;
}
.green {
  color: green;
}

.red {
  color: red;
}

.orange {
  color: orange;
}
@media screen and (max-width: 1200px) {
  .location {
    width: 100%;
  }
}
</style>
