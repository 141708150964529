<template>
  <div class="main">
    <b-tabs
      v-model="tabIndex"
      style="width: 100%"
    >
      <b-tab>
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("ProductsInStock") }}</strong>
        </template>
        <div style="display: flex;gap:10px;padding-top: 20px">
          <ProductsInStock
            :article-list="articleList"
            @articleNumber="theArticleNumber"
            @resetAllArticles="resetAllArticless"
          />
          <ProductsInStockLocations
            :articleList="articleList"
            :article-name="articleName"
            :article-number="articleNumber"
            @addToOrder="addToOrders"
          />
        </div>

      </b-tab>
      <b-tab @click="inStock">
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("Recycle") }}</strong>
        </template>
        <div style="display: flex;gap:10px;padding-top: 20px">
          <ProductsInRecycle />
        </div>

      </b-tab>
    </b-tabs>

  </div>
</template>

<script>
import ProductsInStock from '@/components/productsInStock/ProductsInStock.vue'
import ProductsInStockLocations from '@/components/productsInStock/ProductsInStockLocations.vue'
import ProductsInRecycle from '@/components/productsInStock/ProductsInRecycle.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    ProductsInStock,
    ProductsInStockLocations,
    ProductsInRecycle,
  },
  data() {
    return {
      articleList: [],
      tabIndex: null,
      articleName: null,
    }
  },
  methods: {
    ...mapActions(['loadProductInStockList', 'resetTotalStockGroupedByLocation', 'sellableProducts']),
    theArticleNumber(value) {
      this.articleName = value
    },
    inStock() {
      this.loadProductInStockList({
        articleNumber: null,
        state: 'Recycle',
        pageNumber: 1,
        pageSize: 15,
      })
    },
    addToOrders(value) {
      const existingArticleIndex = this.articleList.findIndex(
        (item) => { return item.articleNumber === value.articleNumber && item.locationId === value.locationId },
      );
      if (existingArticleIndex !== -1) {
        this.articleList[existingArticleIndex].quantity += value.quantity;
      } else {
        this.articleList.push({
          ...value,
        });
      }
    },
    resetAllArticless() {
      this.articleList = []
    },
  },
}

</script>

<style>
.main {
  display: flex;
  gap: 10px;
}
</style>
