<template>
  <div>
    <b-modal
      id="add-user"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('SubmitNewEmployee')"
      hide-footer
      size="lg"
      @close="onCancel"
    >
      <b-tabs
        v-model="tabIndex"
        card
      >
        <b-tab
          active
        >
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("UserInformation") }}</strong>
          </template>
          <b-form>
            <b-form-group
              id="input-group-1"
              :label="`${$t('Salutation')}:`"
              label-for="input-1"
            >
              <vue-select
                v-model="form.salutation"
                required
                :options="salutation"
                :placeholder="$t('SelectSalutation')"
              />
              <span
                v-show="formSubmitted && !$v.form.salutation.required"
                style="font-size: 0.875em;
            color: #dc3545;"
              >{{ $t('SelectSalutation') }}</span>
            </b-form-group>
            <b-form-group
              id="input-group-9"
              :label="`${$t('Role')}:`"
              label-for="input-9"
            >
              <vue-select
                v-model="$v.form.role.$model"
                :options="filteredOptions"
                :reduce="(e) => e.value"
                label="text"
                :placeholder="$t('SelectRole')"
                aria-describedby="input-1-live-feedback"
                class="vue-select-container"
              />
              <span
                v-show="formSubmitted && !$v.form.role.required"
                style="font-size: 0.875em; color: #dc3545;"
              >{{ $t('SelectRole') }}</span>
            </b-form-group>
            <b-form-group
              id="input-group-9"
              :label="`${$t('DateofBirth')}:`"
              label-for="input-9"
            >
              <date-picker
                v-model="selectedDate"
                value-type="format"
                class="form-control"
                format="DD-MM-YYYY"
                type="date"
                :placeholder="$t('SelectD')"
                style="width: 100%;"
                :lang="lang"
              />
            </b-form-group>
            <b-form-group
              id="input-group-2"
              :label="`${$t('Gender')}:`"
              label-for="input-2"
            >
              <vue-select
                v-model="$v.form.gender.$model"
                required
                :options="genderOptions"
                :reduce="(e) => e.value"
                label="text"
                :placeholder="$t('SelectGender')"
                :state="validateState('gender')"
                aria-describedby="input-1-live-feedback"
              />
              <span
                v-show="formSubmitted && !$v.form.gender.required"
                style="font-size: 0.875em;
              color: #dc3545;"
              >{{ $t('SelectGender') }}</span>
            </b-form-group>

            <b-form-group
              id="input-group-1"
              :label="`${$t('FirstName')}:`"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="$v.form.firstName.$model"
                :state="validateState('firstName')"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-1"
              :label="`${$t('LastName')}:`"
              label-for="input-1"
            >
              <b-form-input
                id="input-2"
                v-model="$v.form.lastName.$model"
                :state="validateState('lastName')"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-9"
              :label="`${$t('Email')}:`"
              label-for="input-9"
            >
              <b-form-input
                id="input-3"
                v-model="$v.form.email.$model"
                :state="validateState('email')"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('This_is_a_required_field_and_must_be_email_format') }}</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              id="input-group-9"
              :label="`${$t('PhoneNumber')}:`"
              label-for="input-9"
            >
              <!-- <b-form-input
                id="input-9"
                v-model="$v.form.phoneNumber.$model"
                type="number"
                step="any"
                :state="validateState('phoneNumber')"
                aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback
                id="input-1-live-feedback"
                >{{ $t('This_is_a_required_field_and_must_contains_numbers') }}</b-form-invalid-feedback> -->
              <vue-phone-number-input
                v-model="form.phoneNumber"
                default-country-code="XK"
                :error="
                  isPhoneNumberValid == null ? false : isPhoneNumberValid == false ? true : false
                "
                :preferred-countries="['AL', 'CH', 'DE']"
                @update="getFormattedNumber"
              />
            </b-form-group>
          </b-form>
        </b-tab>
        <b-tab
          :disabled="isPhoneNumberValid == false || isPhoneNumberValid == null || selectedDate == null"
        >
          <template #title>
            <strong
              style="font-weight: 500; color: black"
            >{{ $t("Address") }}</strong>
          </template>
          <b-form>
            <b-form-group
              id="input-group-3"
              :label="`${$t('Street')}:`"
              label-for="input-3"
            >
              <b-form-input
                id="input-6"
                v-model="$v.form.street.$model"
                :state="validateState('street')"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              id="input-group-6"
              :label="`${$t('Door/HouseNumber')}:`"
              label-for="input-6"
            >
              <b-form-input
                id="input-7"
                v-model="$v.form.doorNumber.$model"
                :state="validateState('doorNumber')"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('This_is_a_required_field_and_must_contains_numbers') }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-6"
              :label="`${$t('PostalCode')}:`"
              label-for="input-6"
            >
              <b-form-input
                id="input-8"
                v-model="$v.form.postalCode.$model"
                :state="validateState('postalCode')"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-5"
              :label="`${$t('City')}:`"
              label-for="input-5"
            >
              <b-form-input
                id="input-9"
                v-model="$v.form.city.$model"
                :state="validateState('city')"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-7"
              :label="`${$t('Country')}:`"
              label-for="input-7"
            >
              <b-form-input
                id="input-10"
                v-model="$v.form.country.$model"
                :state="validateState('country')"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-form>
        </b-tab>
        <b-tab
          v-if="client != 'NotaBrillant'"
          :disabled="form.country == '' || form.country == null"
        >

          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("EmployeeDocuments") }}</strong>
          </template>
          <b-form-group
            id="input-group-6"
            style="font-weight: bold"
            class="mb-0"
          />
          <pre />
          <div
            v-if="client !== 'NotaBrillant'"
            style="display: flex; flex-direction: column;"
          >
            <div style="display: flex; gap: 10px;">
              <AddFile
                ref="addFile1"
                :folder="'UserDocuments'"
                :label-text="$t('UploadSketch')"
                :file-data-type="'Passport'"
                :file-name="form.newDocumentRequestDto"
              />
              <AddFile
                ref="addFile2"
                :folder="'UserDocuments'"
                :label-text="$t('UploadContract')"
                :file-data-type="'ResidencePermit'"
                :file-name="form.newDocumentRequestDto"
              />
            </div>
            <div style="width: 49%;">
              <AddFile
                ref="addFile3"
                :folder="'UserDocuments'"
                :label-text="$t('UploadCV')"
                :file-data-type="'SocialNumber'"
                :file-name="form.newDocumentRequestDto"
              />
            </div>
          </div>
          <pre />
        </b-tab>

        <b-tab
          :disabled="form.country == '' || form.country == null"
        >

          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("EmergencyContact") }}</strong>
          </template>
          <b-form>
            <b-form-group
              id="input-group-11"
              :label="`${$t('FirstName')}:`"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="form.emergencyContactDto.firstName"
              />
            </b-form-group>

            <b-form-group
              id="input-group-12"
              :label="`${$t('LastName')}:`"
              label-for="input-12"
            >
              <b-form-input
                id="input-2"
                v-model="form.emergencyContactDto.lastName"
              />
            </b-form-group>

            <b-form-group
              id="input-group-13"
              :label="`${$t('Relation')}:`"
              label-for="input-13"
            >
              <b-form-input
                id="input-2"
                v-model="form.emergencyContactDto.relationship"
              />
            </b-form-group>

            <b-form-group
              id="input-group-14"
              :label="`${$t('PhoneNumber')}:`"
              label-for="input-14"
            >
              <vue-phone-number-input
                v-model="form.emergencyContactDto.phoneNumber"
                default-country-code="XK"
                :error="
                  isPhoneNumberValidEmergency == null ? false : isPhoneNumberValidEmergency == false ? true : false
                "
                :preferred-countries="['AL', 'CH', 'DE']"
                @update="getFormattedNumberEmergency"
              />
            </b-form-group>
          </b-form>
        </b-tab>

        <div class="buttonsEverywhere">
          <b-button
            v-if="(client === 'NotaBrillant' && tabIndex == 2) || (client !== 'NotaBrillant' && tabIndex == 3)"
            type="button"
            variant="none"
            class="buttonSubmit"
            style="margin-left: 15px;background: #FF274F !important;"
            :disabled="isPhoneNumberValid == false || isPhoneNumberValid == null"
            @click="onSubmitAddUser"
          >
            {{ $t('Submit') }}
          </b-button>
          <b-button
            v-else
            class="buttonSubmit"
            :disabled="isPhoneNumberValid == false || isPhoneNumberValid == null || selectedDate == null || (tabIndex == 1 && !isStreetAndCountryFilled)"
            style="margin-left: 15px;"
            @click="tabIndex++"
          >
            {{ $t("Next") }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px; ; background: white; color: black; border-color: white;font-weight: 400;"
            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>

      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
// import flatPickr from 'vue-flatpickr-component';
import { validationMixin } from 'vuelidate';
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import 'vue2-datepicker/index.css';
import {
  required, minLength, alpha, minValue, numeric, alphaNum, email, maxLength,
} from 'vuelidate/lib/validators';

import { mapGetters, mapActions } from 'vuex';
import AWS from 'aws-sdk';
import { AWSurl, client } from '../../../domainConfig';
import AddFile from '../../../modals/AddFile.vue'

// Custom validator to check if the name consists of one or two names
const oneOrTwoNames = (value) => {
  // Split the value by spaces and check if there are one or two parts
  const names = value.trim().split(/\s+/);
  return names.length === 1 || names.length === 2;
};

// Custom validator to allow special characters like ë, ü, etc.
const alphaWithSpecialChars = (value) => {
  // Define a regex pattern to allow letters and special characters
  const regex = /^[A-Za-zÀ-ÖØ-öø-ÿ]+([ '-][A-Za-zÀ-ÖØ-öø-ÿ]+)*$/;
  return regex.test(value);
};

export default {
  components: {
    DatePicker,
    AddFile,
  },
  mixins: [validationMixin],
  data() {
    return {
      client: client.clientName,
      perqindja: 0,
      tatimi: 0,
      tabIndex: 0,
      bucketName: AWSurl.bucketName,
      fileDataPassport: [],
      fileDataAufenthaltsbewilligung: [],
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
      fileDataAHV: [],
      showFileError: false,
      showFileError2: false,
      showFileError3: false,
      selectedDate: null,
      isPhoneNumberValid: null,
      isPhoneNumberValidEmergency: null,
      formSubmitted: false,
      config: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        weekStart: 1,
      },
      roleOptions: ['CEO', 'Sewer', 'TeamLeader', 'Admin', 'Manager', 'PatternMaker', 'Trainee', 'HandSewer'],
      form: {
        firstName: '',
        lastName: '',
        salutation: '',
        gender: '',
        city: '',
        dateOfBirth: '',
        countryCode: '',
        phoneNumber: '',
        postalCode: '',
        doorNumber: '',
        street: '',
        salary: 0,
        country: '',
        email: '',
        role: '',
        socialNumber: '',
        newDocumentRequestDto: [],
        emergencyContactDto: {
          firstName: '',
          lastName: '',
          relationship: '',
          phoneNumber: '',
          countryCode: '',
        },
      },
      gender: null,
      genderOptions: [
        { value: 'Male', text: this.$t('Male') },
        { value: 'Female', text: this.$t('Female') },
      ],
      salutation: ['Mr.', 'Ms.'],
    };
  },
  validations: {
    form: {
      firstName: {
        required,
        minLength: minLength(3),
        alphaWithSpecialChars,
        oneOrTwoNames,
      },
      lastName: {
        required,
        minLength: minLength(3),
        alphaWithSpecialChars,
        oneOrTwoNames,
      },
      salutation: {
        required,
        minLength: minLength(3),
      },
      gender: {
        required,
      },
      city: {
        required,
        minLength: minLength(3),
        alpha,
      },
      dateOfBirth: {
        required,
      },
      postalCode: {
        required,
        minLength: minLength(3),
        alphaNum,
      },
      doorNumber: {
        required,
        minLength: minValue(1),
        numeric,
      },
      street: {
        required,
        minLength: minLength(3),
      },
      country: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      role: {
        required,
      },
      // firstNameEmergency: {
      //   alphaWithSpecialChars,
      //   oneOrTwoNames,
      // },
      // lastNameEmergency: {
      //   alphaWithSpecialChars,
      //   oneOrTwoNames,
      // },
      // relation: {
      //   alphaWithSpecialChars,
      //   oneOrTwoNames,
      // },
    },
    myFiles: {
      // required,
      maxLength: maxLength(1),
    },
  },
  computed: {
    ...mapGetters(['getUserRole', 'getLanguage']),
    // getSal() {
    //   return this.$store.state.login.salutation;
    // },
    isStreetAndCountryFilled() {
      return this.form.city && this.form.country && this.form.postalCode;
    },
    filteredOptions() {
      const options = this.getUserRole.map((role) => {
        return {
          value: role,
          text: this.$t(role),
        }
      });
      return options;
    },
    brutoValue() {
      this.calculateTatimi();

      return this.form.salary - this.perqindja - this.tatimi;
    },
  },
  watch: {
    selectedDate(value) {
      const time = moment(value, 'DD-MM-YYYY').format('YYYY-MM-DD')
      this.form.dateOfBirth = time
    },
  },
  mounted() {
    if (this.getUserRole.length == 0) {
      this.getUsersRole()
    }
    // console.log('tttee', this.getUserRole)
    // this.form.userResponseDto.resetPassword = false
  },
  created() {
    AWS.config.update({
      accessKeyId: AWSurl.accessKeyId,
      secretAccessKey: AWSurl.secretAccessKey,
      region: AWSurl.region,
    });

    this.s3 = new AWS.S3();
  },
  methods: {
    ...mapActions(['getUsersRole']),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    calculateTatimi() {
      this.perqindja = this.form.salary * 0.05;

      if (this.form.salary <= 84) {
        this.tatimi = 0;
      } else if (this.form.salary > 84 && this.form.salary <= 262.5) {
        const konstanta1 = this.form.salary - this.perqindja - 80;
        this.tatimi = konstanta1 * 0.04;
      } else if (this.form.salary > 262.5 && this.form.salary <= 472.5) {
        const konstanta1 = this.form.salary - this.perqindja - 250;
        const tatimi1 = 170 * 0.04;
        const tatimi2 = konstanta1 * 0.08;
        this.tatimi = tatimi1 + tatimi2;
      } else if (this.form.salary > 472.5) {
        const tatimi1 = 170 * 0.04;
        const tatimi2 = 200 * 0.08;
        const konstanta1 = this.form.salary - this.perqindja - 450;
        const tatimi3 = konstanta1 * 0.1;
        this.tatimi = tatimi1 + tatimi2 + tatimi3;
      }
    },
    getFormattedNumber(n) {
      if (n.isValid == true) {
        this.isPhoneNumberValid = true;
        this.form.phoneNumber = n.nationalNumber;
        this.form.countryCode = n.countryCode;
      } else {
        this.form.countryCode = '';
        this.isPhoneNumberValid = false;
        return;
      }
      console.log();
    },
    getFormattedNumberEmergency(n) {
      if (n.isValid == true) {
        this.isPhoneNumberValidEmergency = true;
        this.form.emergencyContactDto.phoneNumber = n.nationalNumber;
        this.form.emergencyContactDto.countryCode = n.countryCode;
      } else {
        this.form.emergencyContactDto.countryCode = '';
        this.isPhoneNumberValidEmergency = false;
        return;
      }
      console.log();
    },
    onChange(event) {
      const files = event.target.files;
      if (files.length <= 3) {
        this.fileData = [];
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileContent = new FileReader();
          fileContent.readAsArrayBuffer(file);

          const promise = new Promise((resolve) => {
            fileContent.onload = (e) => {
              const fileData = {
                fileContent: Buffer.from(e.target.result),
                filetype: file.type.split('/')[1],
                fileName: file.name.split('.')[0],
              }
              resolve(fileData);
            };
          });
          promise.then((fileData) => {
            this.fileData.push(fileData);
          });
          console.log(this.fileData, 'fasfa');
        }
      } else {
        console.error('You can only select a maximum of 3 files.')
      }
    },
    async onSubmitAddUser() {
      try {
        if (this.client !== 'NotaBrillant') {
          await this.$refs.addFile1.uploadFile();
          await this.$refs.addFile2.uploadFile();
          await this.$refs.addFile3.uploadFile();
        }
        this.formSubmitted = true;
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
        this.$emit('click', this.form)
        this.$refs.modal.hide()
        this.onReset()
        setTimeout(() => { this.$v.$reset() }, 0)
        this.$nextTick(() => { this.$v.$reset() })
      // this.isSubmitClicked = true;
      } catch (error) {
        console.error('An error occurred during file upload:', error);
      } finally {
        this.loading = false; // Disable the button
        this.fileName = [];
      }
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.onReset();
    },
    onReset() {
      // Reset our form values
      this.form.firstName = '';
      this.form.lastName = '';
      this.form.salutation = '';
      this.form.gender = '';
      this.form.city = '';
      this.form.street = '';
      this.form.dateOfBirth = '';
      this.form.postalCode = '';
      this.form.doorNumber = '';
      this.form.country = '';
      this.form.phoneNumber = '';
      this.form.email = '';
      this.form.role = '';
      this.form.countryCode = '';
      this.form.salary = 0;
      this.formSubmitted = false
      this.selectedDate = null
      this.isPhoneNumberValid = false
      this.fileData = []
      this.form.newDocumentRequestDto = []
      this.form.emergencyContactDto.firstName = ''
      this.form.emergencyContactDto.lastName = ''
      this.form.emergencyContactDto.relationship = ''
      this.form.emergencyContactDto.phoneNumber = ''
      this.form.emergencyContactDto.countryCode = null
      // Trick to reset/clear native browser form validation state
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  align-items: flex-end;
}
.vue-select-container {
  min-width: 30px; /* Adjust the width according to your needs */
  word-break: break-all;
}

.vue-dropdown-menu {
  max-height: 200px; /* Adjust the maximum height of the dropdown menu */
  word-break: break-all;
}

.vue-dropdown-item {
  white-space: nowrap; /* Prevent long names from wrapping */
  min-width: 300px; /* Adjust the width according to your needs */
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis; /* Show ellipsis (...) for long names */
}
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
</style>
