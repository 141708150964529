<template>
  <div>
    <b-sidebar
      id="order-tracking-buy"
      ref="modal"
      header-class="headerModalToggle"
      right
      shadow
      size="l"
      width="60%"
      :title="$t('OrdersHistory')"
      no-close-on-esc
      @close="onCancelToggle"
    >
      <template #title>
        <strong style="color: #101828;">{{ $t('OrdersHistory') }}</strong>
      </template>
      <template
        v-slot:header-close
      >
        <button
          style="color: #98A2B3;padding-left: 0px; padding-right: 0px;height: 60px;"
          class="fa fa-close"
          @click="onCancelToggle"
        />
      </template>
      <div
        class="tt"
        style=" padding: 16px"
      >
        <h3>{{ $t('Articles') }}</h3>
        <table
          class="team_table"
        >
          <thead>
            <tr>
              <th>{{ $t('ArticleNumber') }}</th>
              <th>{{ $t('Quantity') }}</th>
              <th>{{ $t('PriceNoVat') }}</th>
              <th>{{ $t('Vat') }}</th>
              <th>{{ $t('PriceWithVat') }}</th>
              <th>{{ $t('AmountWithVat') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(material, index) in selectedMaterials"
              :key="index"
            >
              <td>{{ material.articleNumber }}</td>
              <td>
                {{ material.quantity }}
              </td>
              <td>
                {{ material.priceWithoutVAT }}€
              </td>
              <td>
                {{ material.selectedVAT ? material.selectedVAT : 0 }}%
              </td>
              <td>
                {{ material.priceWithVAT }}€
              </td>
              <td>
                {{ material.valueWithVAT ? material.valueWithVAT : 0 }}€
              </td>
            </tr>
          </tbody>
        </table>
        <div style="display:flex;flex-direction: row;gap:10px;;margin-top: 15px">
          <b-form-group
            :label="$t('deliveryNumber')"
            style="width:100%"
          >
            <b-form-input
              v-model="selectedArticlesList.deliveryNumber"
              disabled
            />
          </b-form-group>
          <b-form-group
            :label="$t('receiverLocationName')"
            style="width:100%"
          >
            <b-form-input
              v-model="selectedArticlesList.receiverLocationName"
              disabled
            />
          </b-form-group>
          <b-form-group
            :label="$t('SupplierName')"
            style="width:100%"
          >
            <b-form-input
              v-model="selectedArticlesList.supplierName"
              disabled
            />
          </b-form-group>
        </div>
        <div style="display:flex;flex-direction: row;gap:10px;margin-top: 10px">
          <b-form-group
            :label="$t('InvoiceNumber')"
            style="width:100%"
          >
            <b-form-input
              v-model="selectedArticlesList.invoiceNumber"
              disabled
            />
          </b-form-group>
          <b-form-group
            :label="$t('TotalPrice')"
            style="width:100%"
          >
            <b-form-input
              v-model="selectedArticlesList.totalPrice"
              disabled
            />
          </b-form-group>
          <b-form-group
            :label="$t('TotalPaid')"
            style="width:100%"
          >
            <b-form-input
              v-model="selectedArticlesList.totalPaid"
              disabled
            />
          </b-form-group>
        </div>
        <div style="display:flex;flex-direction: row;gap:10px;margin-top: 10px">
          <b-form-group
            :label="$t('AccountName')"
            style="width:100%"
          >
            <b-form-input
              v-model="selectedArticlesList.accountName"
              disabled
            />
          </b-form-group>
          <b-form-group
            :label="$t('invoiceDate')"
            style="width:100%"
          >
            <date-picker
              v-model="selectedArticlesList.invoiceDate"
              disabled
              format="YYYY-MM-DD"
              value-type="format"
              :placeholder="$t('SelectD')"
            style="width:100%"
            />
          </b-form-group>
          <b-form-group
            :label="$t('created')"
            style="width:100%"
          >
            <date-picker
              v-model="selectedArticlesList.created"
              disabled
              format="YYYY-MM-DD"
              value-type="format"
              :placeholder="$t('SelectD')"
            style="width:100%"
            />
          </b-form-group>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      vatOptions: [
        { text: '18%', value: 18 },
        { text: '8%', value: 8 },
        { text: '0%', value: 0 },
      ],
    };
  },
  computed: {
    // getTrackingByOrderArticle
    ...mapGetters(['getTrackingByOrderArticle']),
    selectedMaterials() {
      return this.getTrackingByOrderArticle?.deliveryProductInStockDtos?.map((value) => {
        const priceWithoutVAT = value.quantity > 0 ? (value.totalPrice / value.quantity) / (1 + value.taxRate / 100) : 0;
        const priceWithVAT = value.quantity > 0 ? value.totalPrice / value.quantity : 0;
        const formatPrice = (price) => {
          return price % 1 === 0 ? price : price.toFixed(2);
        };
        return {
          articleNumber: value.articleNumber,
          inventoryTrackingId: value.inventoryTrackingId,
          productInStockId: value.productInStockId,
          valueWithVAT: value.totalPrice,
          quantity: value.quantity,
          priceWithVAT: formatPrice(priceWithVAT),
          priceWithoutVAT: formatPrice(priceWithoutVAT),
          selectedVAT: value.taxRate,
        }
      })
    },
    selectedArticlesList() {
      const deliveryManagementResponseDto = this.getTrackingByOrderArticle?.deliveryManagementResponseDto;

      if (deliveryManagementResponseDto) {
        return {
          accountName: deliveryManagementResponseDto.accountName,
          deliveryNumber: deliveryManagementResponseDto.deliveryNumber,
          created: deliveryManagementResponseDto.created,
          invoiceDate: deliveryManagementResponseDto.invoiceDate,
          invoiceNumber: deliveryManagementResponseDto.invoiceNumber,
          receiverLocationName: deliveryManagementResponseDto.receiverLocationName,
          supplierName: deliveryManagementResponseDto.supplierName,
          totalPaid: deliveryManagementResponseDto.totalPaid,
          totalPrice: deliveryManagementResponseDto.totalPrice,
        };
      }

      return {};
    },
  },
  watch: {

  },
  created() {
  },
  methods: {
    ...mapActions(['loadOrderedMaterials']),
    validateMaterials() {
      this.selectedMaterials.forEach((material) => {
        if (material.valueWithVAT <= 0 || material.valueWithVAT === null || material.valueWithVAT === '') {
          material.validationValueWithVAT = true;
        } else {
          material.validationValueWithVAT = false;
        }

        if (material.amount <= 0 || material.amount === null || material.amount === '') {
          material.validationamount = true;
        } else {
          material.validationamount = false;
        }

        if (material.priceWithoutVAT <= 0 || material.priceWithoutVAT === null || material.priceWithoutVAT === '') {
          material.validationPriceWithoutVAT = true;
        } else {
          material.validationPriceWithoutVAT = false;
        }

        if (material.priceWithVAT <= 0 || material.priceWithVAT === null || material.priceWithVAT === '') {
          material.validationPriceWithVAT = true;
        } else {
          material.validationPriceWithVAT = false;
        }
      });
    },
    calculateFromAmount(material) {
      if (material.amount > 0) {
        material.priceWithVAT = parseFloat((material.priceWithoutVAT * (1 + material.selectedVAT / 100)).toFixed(2));
        material.valueWithVAT = parseFloat((material.priceWithVAT * material.amount).toFixed(2));
      }
      this.validateMaterials()
    },

    calculateFromPriceWithVAT(material) {
      if (material.amount > 0) {
        material.priceWithoutVAT = parseFloat((material.priceWithVAT / (1 + material.selectedVAT / 100)).toFixed(2));
        material.valueWithVAT = parseFloat((material.priceWithVAT * material.amount).toFixed(2));
      } else {
        material.priceWithoutVAT = parseFloat((material.priceWithVAT / (1 + material.selectedVAT / 100)).toFixed(2));
      }
      this.validateMaterials()
    },
    calculateFromSelectedVAT(material) {
      if (material.amount > 0) {
        material.priceWithVAT = parseFloat((material.priceWithoutVAT * (1 + material.selectedVAT / 100)).toFixed(2));
        material.valueWithVAT = parseFloat((material.priceWithVAT * material.amount).toFixed(2));
      } else if (material.priceWithoutVAT > 0) {
        material.priceWithVAT = parseFloat((material.priceWithoutVAT * (1 + material.selectedVAT / 100)).toFixed(2));
      }

      if (material.priceWithVAT > 0) {
        material.priceWithoutVAT = parseFloat((material.priceWithVAT / (1 + material.selectedVAT / 100)).toFixed(2));
      }
      this.validateMaterials()
    },
    calculateFromPriceWithoutVAT(material) {
      if (material.amount > 0) {
        material.priceWithVAT = parseFloat((material.priceWithoutVAT * (1 + material.selectedVAT / 100)).toFixed(2));
        material.valueWithVAT = parseFloat((material.priceWithVAT * material.amount).toFixed(2));
      } else {
        material.priceWithVAT = parseFloat((material.priceWithoutVAT * (1 + material.selectedVAT / 100)).toFixed(2));
      }
      this.validateMaterials()
    },
    calculateFromValueWithVAT(material) {
      if (material.amount > 0) {
        material.priceWithVAT = parseFloat((material.valueWithVAT / material.amount).toFixed(2));
        material.priceWithoutVAT = parseFloat((material.priceWithVAT / (1 + material.selectedVAT / 100)).toFixed(2));
      }
      this.validateMaterials()
    },
    createdDateModified(xdate) {
      return moment(xdate).format('DD/MM/YYYY - HH:mm');
    },
    numbersWithcomma(value) {
      const options = {
        style: 'decimal', // Other options: 'currency', 'percent', etc.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return value.toLocaleString('en-US', options);
    },
    onCancelToggle() {
      this.$emit('onCancelToggle');
      this.$refs.modal.hide();
    },
  },
};
</script>

  <style scoped lang="scss">

  .team_table td {
    padding: 16px 9px !important;
  }

  .team_table tr {
    border-bottom: 1px solid #dcdfe6;
    color: #606266;
    cursor: pointer;
  }

  .team_table thead {
    border-bottom: 1px solid #dcdfe6;
    color: #606266;
    text-align: left;
  }
  .hover-row:hover {
      background-color: rgba(255, 39, 79, 0.1);
  }
    .mats-listing__wrapper {
      width: 600px !important;

      .tt {
        .vgt-table {
          th.sortable button {
            display: none !important;
          }
        }
      }

      .mats-listing {
        margin-top: 0;
      }
    }

    .button {
      margin-bottom: 15px !important;
      width: 195px;
    }

    .team_table {
      font-size: 12px;
      border-radius: 8px;
      width: 100%;
      box-shadow: none;
    }

    // .team_table .tr{
    //   border-radius: 10px 10px 10px 10px !important;
    // }
    tr:last-child {
      border-bottom: none !important;
    }

    @media screen and (max-width: 1723px) {
      .mats-listing__wrapper {
        width: 400px !important;

        div {
          button {
            width: auto;
            height: 36px;
          }

          input {
            width: 160px;
          }

        }

        .table__search-input {
          padding-left: 0px;
          margin-left: 0px !important;
        }
      }

    }

    @media screen and (max-width: 1400px) {
      .mats-listing__wrapper {
        width: 100% !important;
      }
    }


    .dropdrop::-webkit-scrollbar {
      display: none;

    }

    .dropdrop {
      display: inline-block;
      overflow: auto;
      position: absolute;
      background: white;
      width: 227px;
      box-shadow: none;
    }

    input[name="search-box"] {
      display: block;
      max-width: 500px;
      width: 95%;
      padding: 0.5rem 1rem;
      padding-left: 25px;
    }

    .black {
      position: relative;
    }

    .black input {
      border: none;
      box-shadow: none;
    }

    .search-item {
      padding: 5px;
      max-width: 500px;
      width: 100%;
      font-weight: 400;
      color: #82868c;
      font-size: 1rem;
      border-bottom: 1px solid #e8e8e8;
    }

    .search-item:last-child {
      border-bottom: none;
    }

    .search-item:hover {
      background: #82868c;
      color: white;
    }

    .search-item hr {
      color: lightgray;
      border-top: none;
      margin: -1.3rem 0 0.5rem 0;
    }

    .is-active {
      background-color: #dedede;
    }
      </style>
