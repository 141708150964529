<template>
  <div

    @click="removeLists"
  >
    <div
      class="table__search-input"
      style="
        margin-top: 13px;
        margin-left: 56px;
        padding: 150px 15px 15px 190px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
      "
    >
      <div>
        <b-form-select
          v-model="selectedOrderType"
          class="custom-select1"
          style="
            padding: 8.5px;
            color: #82868c;
            border-radius: 8px;
            border: none;
            box-shadow: none;
          "
          dropup
          :options="options"
        />
        <b-form-select
          v-model="selectedOrderState"
          class="custom-select2"
          style="
            padding: 8.5px;
            color: #82868c;
            border-radius: 8px;
            margin-left: 30px;
            border: none;
            box-shadow: none;
          "
          dropup
          :options="
            getLoggedInUser.role == 'Deliverer'
              ? stateOptionsDeliverer
              : getLoggedInUser.role == 'TeamLeader'
                ? stateOptionsTeamLeader
                : stateOptions
          "
        />
      </div>
      <div style="display: flex">
        <div class="search-toggle">

          <p>Search by</p>
          <div>
            <p
              :class="{ active2: toggleSarch }"
              @click="showOrderNumber(), toggleSarch=true"
            >
              {{ $t('OrderN') }}
            </p>
            <p
              :class="{ active2: !toggleSarch }"
              @click="showCustomerName(), toggleSarch=false"
            >
              {{ $t('CustomerName') }}
            </p>
          </div>
        </div>
        <div v-if="show == true">
          <div>
            <div class="black">
              <b-icon-search
                v-if="searchON == ''"
                class="search1"
                style="margin-top: -6px; left: 427px; top: 86%"
              />
              <input
                v-model="searchON"
                type="text"
                name="search-box"
                placeholder="Order Number"
                :style="getFilteredOrdersbyON.length > 0 && noSearch ? 'border-radius: 8px 8px 0px 0px' : ''"
              >
            </div>
            <div
              v-if="getFilteredOrdersbyON.length > 0 && noSearch"
              class="dropdrop"
              style="
              display: inline-block;
              overflow: auto;
              position: absolute;
              background: white;
              width: 229px;
              box-shadow: none;
            "
              :style="getFilteredOrdersbyON.length > 7 ? 'height: 210px' : 'height: auto'"
            >
              <div
                v-for="(result, index) in getFilteredOrdersbyON"
                :key="index"
                style="cursor: pointer"
                class="search-item"
                @click="searchByOn(result); noSearch = false"
              >
                <p style="margin: 0">
                  {{ result }}
                </p>
              </div>
              <div
                v-if="getFilteredOrdersbyON.length == 0"
                class="search-item"
              >
                <p>{{ $t('NoResult') }}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="show == false">
          <b-icon-search
            v-if="searchCN == ''"
            class="search2"
            style="margin-top: -6px; left: 427px; top: 86%"
          />
          <div>
            <input
              v-model="searchCN"
              type="text"
              name="search-box"
              placeholder="Customer Name"
              :style="getFilteredOrdersbyCN.length > 0 && noSearchTwo ? 'border-radius: 8px 8px 0px 0px' : ''"
            >
            <div
              v-if="getFilteredOrdersbyCN.length > 0 && noSearchTwo"
              class="dropdrop"
              style="
              display: inline-block;
              overflow: auto;
              position: absolute;
              background: white;
              width: 229px;
              box-shadow: none;
            "
              :style="getFilteredOrdersbyCN.length > 7 ? 'height: 210px' : 'height: auto'"
            >
              <div
                v-for="(result, index) in getFilteredOrdersbyCN"
                :key="index"
                style="cursor: pointer"
                class="search-item"
                @click="searchByCn(result.customerName ); noSearchTwo = false"
              >
                <p style="margin: 0">
                  {{ result.customerName }}
                </p>
              </div>
              <div
                v-if="getFilteredOrdersbyCN.length == 0"
                class="search-item"
              >
                <p>{{ $t('NoResult') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section
      class="main"
      style="padding: 0 15px 15px 245px"
    >

      <div style="width: 100%; border-top: 1px solid gray">

        <ul
          v-if="getOrders.length > 0"
          class="ul-order"
        >
          <li
            v-for="item in getOrders"
            :key="item.orderNumber"
          >
            <div
              class="order-card"
              style="justify-content: space-between"
              :style="activeOrderStatus == item.orderNumber ? 'border-bottom: 1px solid grey' : ''"
              @click="readOrderById(item.orderNumber, item.orderId)"
            >
              <p style="width: 10%">
                <span style="font-weight: 600"> Order Number:</span> <br>{{ item.orderNumber }}
              </p>
              <p style="width: 10%">
                <span style="font-weight: 600">Order Type:</span> <br>
                {{ item.orderType }}
              </p>
              <p style="width: 15%">
                <span style="font-weight: 600">Customer Name:</span> <br>
                {{ item.ordererResponseDto.firstName }} {{ item.ordererResponseDto.lastname }}
              </p>
              <p style="width: 32.5%">
                <span style="font-weight: 600">Billing Address:</span> <br>
                <span
                  v-for="i in item.billingAddressDto"
                  :key="i.id"
                  style="margin-right: 10px"
                >{{
                  i
                }}</span>
              </p>
              <p
                v-if="item.pickUpInStore == false"
                style="width: 32.5%"
              >
                <span style="font-weight: 600">Shipping Address:</span> <br>
                <span
                  v-for="i in item.shippingAddressDto"
                  :key="i.id"
                  style="margin-right: 10px"
                >{{
                  i
                }}</span>
              </p>
              <p
                v-else
                style="width: 32.5%"
              >
                <span style="font-weight: 600">Get In Store:</span> <br>
                <span style="margin-right: 10px"> {{ modifyDate(item.pickUpDate) }} </span>
              </p>
              <p style="text-align: left">
                <span style="font-weight: 600">Items: </span>{{ item.itemCounter }}
              </p>
            </div>
            <ul>
              <li
                v-for="itemz in getArticleOrders"
                v-show="item.orderNumber === activeOrderStatus"
                :key="itemz.created"
              >
                <p style="display: inline; margin-right: 20px; cursor: pointer">
                  <span style="font-weight: 600">Article Name:</span> {{ itemz.articleNumber }}
                </p>
                <p style="display: inline; margin-right: 30px">
                  <span style="font-weight: 600">Assigned Team:</span>
                  {{
                    itemz.teamName !== "I am not a valid Team Name, untill then return me as dummy"
                      ? itemz.teamName
                      : "No Team is assigned yet!"
                  }}
                </p>
                <button
                  class="btn btn-outline-primary"
                  type="button"
                  @click="loadM(itemz.articleNumber, item.orderNumber, itemz.orderItemId)"
                >
                  {{ showM == true && das == itemz.orderItemId ? "Hide" : "Show" }} {{ $t('MaterialVariants') }}
                </button>
                <div>
                  <div style="margin-right: 10px; margin-top: 20px">
                    <p style="font-weight: 400; display: inline">
                      Item State:
                    </p>
                    <div style="display: inline-flex; flex-wrap: wrap">
                      <div
                        v-for="(stage, idx) in itemz.stateDtos"
                        :key="idx"
                        :class="{
                          hide: stage.stateName !== 'ReadyToDeliver' && stage.stateName !== 'Delivered',
                          failed: itemz.stateDtos[9].stateProcessed == true,
                          active:
                            stage.stateProcessed === true &&
                            itemz.stateDtos[9].stateProcessed == false,
                          activee: stage.stateProcessed === true && idx != itemz.state - 1,
                        }"
                        class="stage-card"
                        :disabled="stage.stateName !== 'Delivered'"
                        :style="stage.stateName == 'Delivered' ? 'cursor: pointer' : ''"
                        @click="changeS(stage.stateName, item.orderNumber, itemz.stateDtos[itemz.state - 1].stateName, itemz.articleNumber)"
                      >
                        {{
                          stage.stateName == "QualityCheckNotPassed"
                            ? `${stage.stateName}: ${itemz.qualityCheckerCounter}`
                            : stage.stateName
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="showM && das == itemz.orderItemId">
                  <div style="margin-right: 10px; margin-top: 20px">
                    <p style="font-weight: 600">
                      {{ $t('MaterialVariants') }}
                    </p>
                    <div
                      style="
                        display: inline-flex;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        width: 100%;
                      "
                    >
                      <div
                        v-for="material in getMaterialVariantsOfOrder"
                        :key="material.materialVariantId"
                        class="material-info"
                        style="
                          display: flex;
                          flex-direction: column;
                          justify-content: space-between;
                          margin-bottom: 10px;
                          border: none;
                          box-shadow: none;
                          padding: 12px;
                          color: #212529;
                          border-radius: 8px;
                          width: 19%;
                          margin-right: 10px;
                        "
                      >
                        <p>{{ $t('Name') }} {{ material.name }}</p>
                        <p>{{ $t('MeasurementType') }} {{ material.measurementType }}</p>
                        <p>
                          {{
                            material.length == 0
                              ? `Quantity: ${material.quantity === 1 ? material.quantity+" part" : material.quantity > 0 ? material.quantity+" parts" : ""}`
                              : `Length: ${material.length === 1 ? material.length+" meter" : material.length > 0 ? material.length+" meters" : ""}`
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </li>
        </ul>
        <div
          v-else
          style="text-align: left; font-size: 20px; box-shadow: none; padding: 6px 0"
        >
          <p style="margin: 0">
            {{ $t('NoOrderWasFound') }}!
          </p>
        </div>
      </div>
    </section>
    <div style="padding: 0px 15px 10px 0px">
      <b-pagination
        v-model="page"
        :total-rows="getTotalItemsForOrders"
        :per-page="pageSize"
        first-number
        last-number
        align="fill"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
      >
        <template #prev-text>
          <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
        </template>
        <template #next-text>
          <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
        </template>
      </b-pagination>
    </div>
    <ChangeStatusModal
      :c-status="comingStatus"
      :q-failed="changeModal"
      :order-failed="qualityFailed"
      :n-state="nState"
      @changeOrderStatus="changeStage"
      @qualityChFailed="changeStage"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import ChangeStatusModal from '@/components/products/modals/ChangeStatusModal.vue';


export default {
  name: 'Home',
  components: { ChangeStatusModal },
  // eslint-disable-next-line vue/require-prop-types
  props: ['state'],
  data() {
    return {
      toggleSarch: true,
      show: true,
      showM: false,
      searchCN: '',
      onSearchTwo: false,
      searchON: '',
      noSearch: true,
      orderNumber: '',
      customerName: '',
      countS: 0,
      rows: [],
      activeId: '',
      orderStatus: '',
      activeOrderStatus: '',
      das: '',
      orders: [],
      page: 1,
      pageSize: 15,
      selectedOrderType: null,
      oldStatus: '',
      changeModal: false,
      comingStatus: {
        orderNumber: {
          type: String,
        },
        articleNumber: {
          type: String,
        },
        newState: {
          type: String,
        },
      },
      options: [
        { value: null, text: 'All' },
        { value: 'InStore', text: 'InStore' },
        { value: 'Online', text: 'Online' },
      ],
      selectedOrderState: null,
      stateOptions: [
        { value: null, text: 'All' },
        { value: 'Created', text: 'Created' },
        { value: 'Assigned', text: 'Assigned' },
        { value: 'Confirmed', text: 'Confirmed' },
        { value: 'InProduction', text: 'InProduction' },
        { value: 'InSewing', text: 'InSewing' },
        { value: 'QualityPassed', text: 'QualityPassed' },
        { value: 'ReadyToDeliver', text: 'ReadyToDeliver' },
        { value: 'Delivered', text: 'Delivered' },
        { value: 'StockNotAvailable', text: 'StockNotAvailable' },
        { value: 'QualityCheckNotPassed', text: 'QualityCheckNotPassed' },
      ],
      stateOptionsDeliverer: [
        { value: 'ReadyToDeliver', text: 'ReadyToDeliver' },
        { value: 'Delivered', text: 'Delivered' },
      ],
      stateOptionsTeamLeader: [
        { value: 'Assigned', text: 'Assigned' },
        { value: 'Confirmed', text: 'Confirmed' },
        { value: 'InProduction', text: 'InProduction' },
        { value: 'InSewing', text: 'InSewing' },
        { value: 'QualityPassed', text: 'QualityPassed' },
        { value: 'ReadyToDeliver', text: 'ReadyToDeliver' },
        { value: 'Delivered', text: 'Delivered' },
        { value: 'StockNotAvailable', text: 'StockNotAvailable' },
        { value: 'QualityCheckNotPassed', text: 'QualityCheckNotPassed' },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'getOrders',
      'getTotalItemsForOrders',
      'getArticleOrders',
      'getMaterialVariantsOfOrder',
      'getLoggedInUser',
      'getFilteredOrdersbyON',
      'getFilteredOrdersbyCN',
      'getIsLoading',
    ]),
  },
  watch: {
    page(value) {
      this.getOrdersOverview({
        pageNumber: value,
        pageSize: this.pageSize,
        orderNumber: this.searchON,
        customerName: this.searchCN,
        orderType: this.selectedOrderType == null ? 'ALL' : this.selectedOrderType,
        orderState: this.selectedOrderState == null ? 'ALL' : this.selectedOrderState,
      });
      // this.$router
      //   .replace({ name: "Orders Overview", query: { page: this.page, pageSize: this.pageSize } })
      //   .catch(() => {});
    },
    searchON(value) {
      if (value == '') {
        this.fshij();
        this.getOrdersOverview({
          pageNumber: this.page,
          pageSize: this.pageSize,
          orderNumber: value,
          customerName: this.searchCN,
          orderType: this.selectedOrderType == null ? 'ALL' : this.selectedOrderType,
          orderState: this.selectedOrderState == null ? 'ALL' : this.selectedOrderState,
        })
      }
      this.searchByOrderNumber(value);
      this.noSearch = true
    },
    searchCN(value) {
      if (value == '') {
        this.fshijCN();
        this.getOrdersOverview({
          pageNumber: this.page,
          pageSize: this.pageSize,
          orderNumber: this.searchON,
          customerName: value,
          orderType: this.selectedOrderType == null ? 'ALL' : this.selectedOrderType,
          orderState: this.selectedOrderState == null ? 'ALL' : this.selectedOrderState,
        })
      }
      this.searchByCustomerName(value);
      this.noSearchTwo = true
    },
    // filters: {
    //   // eslint-disable-next-line func-names
    //   handler() {
    //     this.$router
    //       .replace({ name: "Orders Overview", query: { page: this.page, pageSize: this.pageSize } })
    //       .catch(() => {});
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    selectedOrderType(value) {
      if (value == null) {
        // eslint-disable-next-line no-useless-return
        this.getOrdersOverview({
          pageNumber: this.page,
          pageSize: this.pageSize,
          orderNumber: this.searchON,
          customerName: this.searchCN,
          orderType: 'ALL',
          orderState: this.selectedOrderState == null ? 'ALL' : this.selectedOrderState,
        });
        // eslint-disable-next-line no-else-return
      } else {
        this.getOrdersOverview({
          pageNumber: this.page,
          pageSize: this.pageSize,
          orderNumber: this.searchON,
          customerName: this.searchCN,
          orderType: value,
          orderState: this.selectedOrderState == null ? 'ALL' : this.selectedOrderState,
        });
      }
    },
    selectedOrderState(value) {
      if (value == null) {
        // eslint-disable-next-line no-useless-return
        this.$router.push({ params: { state: 'all' } });
        this.state = 'all';

        this.getOrdersOverview({
          pageNumber: this.page,
          pageSize: this.pageSize,
          orderNumber: this.searchON,
          customerName: this.searchCN,
          orderType: this.selectedOrderType == null ? 'ALL' : this.selectedOrderType,
          orderState: 'ALL',
        });
        // eslint-disable-next-line no-else-return
      } else {
        this.state = value;
        this.$router.push({ params: { state: value } });
        this.getOrdersOverview({
          pageNumber: this.page,
          pageSize: this.pageSize,
          orderNumber: this.searchON,
          customerName: this.searchCN,
          orderType: this.selectedOrderType == null ? 'ALL' : this.selectedOrderType,
          orderState: value,
        });
      }
    },
  },
  mounted() {
    this.selectedOrderState = this.state;
    if (this.state == 'all') {
      this.selectedOrderState = null;
      this.fetch()
      // When state and orderType becoms optional delete row 550,551,553,554,555
    } else {
      this.fetchh()
    }
  },
  methods: {
    ...mapActions([
      'getOrdersOverview',
      'readOrderItems',
      'materialVariantsByOrderNAndArticleN',
      'resetMaterialVariantsOfOrders',
      'searchByOrderNumber',
      'fshij',
      'searchByCustomerName',
      'fshijCN',
      'changeLoadingtoTrue',
      'updateOrderStatus',
      'getOrderItemsManagementBy',
    ]),
    async fetch() {
      try {
        // this.changeLoadingtoTrue(false)


        await this.getOrdersOverview({
          pageNumber: this.page,
          pageSize: this.pageSize,
          orderNumber: this.searchON,
          customerName: this.searchCN,
          orderType: this.selectedOrderType == null ? 'ALL' : this.selectedOrderType,
          orderState: this.selectedOrderState == null ? 'ALL' : this.selectedOrderState,
        })
          .then((res) => {
            console.log(res);
            // this.changeLoadingtoTrue(true)
          })
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
    },
    async fetchh() {
      try {
        await this.getOrdersOverview({
          pageNumber: this.page,
          pageSize: this.pageSize,
          orderNumber: this.searchON,
          customerName: this.searchCN,
          orderType: this.selectedOrderType == null ? 'ALL' : this.selectedOrderType,
          orderState: this.selectedOrderState == null ? 'ALL' : this.selectedOrderState,
        })
          .then((res) => {
            console.log(res);
            // this.changeLoadingtoTrue(true)
          })
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
    },
    changeS(item, oNumber, oStatus, articleNumber) {
      if ((oStatus == 'ReadyToDeliver' && item == 'Delivered' && this.getLoggedInUser.role == 'Deliverer')
      ) {
        this.$bvModal.show('modal-change-status')
        this.comingStatus.newState = item
        this.comingStatus.orderNumber = oNumber
        this.oldStatus = oStatus
        this.comingStatus.articleNumber = articleNumber
        this.changeModal = false
      }
    },
    async changeStage(item) {
      if ((this.oldStatus == 'ReadyToDeliver' && item.newState == 'Delivered' && this.getLoggedInUser.role == 'Deliverer')
      ) {
        await this.updateOrderStatus({
          object: item,
          successCallback: () => {
            // this.getOrderItemsManagementBy({
            //   orderNr: item.orderNumber,
            //   id: this.activeId,
            // });
            this.getOrdersOverview({
              pageNumber: this.page,
              pageSize: this.pageSize,
              orderNumber: this.searchON,
              customerName: this.searchCN,
              orderType: this.selectedOrderType == null ? 'ALL' : this.selectedOrderType,
              orderState: this.selectedOrderState == null ? 'ALL' : this.selectedOrderState,
            })
          },
        });
      }
    },
    async readOrderById(ordNId, orderIds) {
      await this.readOrderItems({
        orderId: orderIds,
        orderItemState: this.selectedOrderState == null ? 'ALL' : this.selectedOrderState,
      });
      this.activeOrderStatus = ordNId;
      this.resetMaterialVariantsOfOrders();
      this.showM = false;
    },
    showOrderNumber() {
      this.toggleSarch = true;
      this.show = true
      this.searchCN = '';
    //   this.getSuppliersCompanyNameLikeORContactNameLike({
    //     companyName: this.searchBySupplierName,
    //     contactName: '',
    //   });
    // },
    },
    showCustomerName() {
      this.toggleSarch = false;
      this.show = false
      this.searchON = '';
      // this.getSuppliersCompanyNameLikeORContactNameLike({
      //   companyName: this.searchBySupplierName,
      //   contactName: '',
      // });
    },
    searchByOn(value) {
      this.searchON = value
      this.noSearch = false
      this.getOrdersOverview({
        pageNumber: this.page,
        pageSize: this.pageSize,
        orderNumber: this.searchON,
        customerName: this.searchCN,
        orderType: this.selectedOrderType == null ? 'ALL' : this.selectedOrderType,
        orderState: this.selectedOrderState == null ? 'ALL' : this.selectedOrderState,
      })
    },
    searchByCn(value) {
      this.searchCN = value
      this.noSearchTwo = false
      this.getOrdersOverview({
        pageNumber: this.page,
        pageSize: this.pageSize,
        orderNumber: this.searchON,
        customerName: this.searchCN,
        orderType: this.selectedOrderType == null ? 'ALL' : this.selectedOrderType,
        orderState: this.selectedOrderState == null ? 'ALL' : this.selectedOrderState,
      })
    },
    loadM(articleN, orderN, orderItemUnik) {
      this.materialVariantsByOrderNAndArticleN({
        orderNumber: orderN,
        articleNumber: articleN,
      });
      this.showM = !this.showM;

      if (this.das === orderItemUnik) {
        this.das = '';
        this.showM = false;
      } else {
        this.das = orderItemUnik;
        this.showM = true;
      }
    },
    modifyDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    removeLists() {
      this.noSearch = false;
      this.noSearchTwo = false;
      this.fshijCN();
      this.fshij();
    },
  },
};
</script>

<style scoped lang="scss">
.dropdrop::-webkit-scrollbar {
display: none;

}
.material-info:last-child {
  margin-right: 0 !important;
}
input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}
.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}
.search-item:last-child {
  border-bottom: none;
}
.search-item:hover {
  background: #82868c;
  color: white;
}
.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}
.pm-li {
  list-style: none;
  background: #ffffff;
  overflow-y: auto;
  border-radius: 8px;
  border: 1px solid transparent;
  box-shadow: none;
  padding: 12px 10px;
  margin-top: 20px;
}
.pm-li h5 {
  margin-bottom: 0;
}
.ul-order {
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
}
.ul-order li {
  background: #ffffff;
  overflow-y: auto;

  border-radius: 8px;
  border: 1px solid transparent;
  box-shadow: none;
  padding: 6px 2px 6px 10px;
  margin-top: 15px;
}
.ul-order ul {
  padding-left: 5px;
}

.order-card {
  cursor: pointer;
  display: flex;
}

.order-card p {
  margin-right: 20px;
}
.stage-card {
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid transparent;
  box-shadow: none;
  padding: 10px;
  margin-bottom: 15px;
  margin-right: 10px;
}
.stage-card.active {
  background-color: #b6ffb6;
}
.stage-card.activee {
  background-color: grey;
  opacity: 0.7;
  color: #b6ffb6;
}
.active2{
  color: $base-color;
  font-weight: bold;
}
.stage-card.failed {
  background-color: rgb(209, 53, 53);
  opacity: 0.7;
  color: white;
}
.stage-card.hide {
  display: none;
}

@media screen and (max-width: 1300px) {
  .input2 {
    width: 160px !important;
    margin-left: 0px;
    font-size: smaller;
    margin-left: 4px !important;
    padding-left: 0px;
    text-align: end;
  }
  .input1 {
    width: 150px !important;
    margin-left: 0px;
    font-size: smaller;
  }
  .search1 {
    padding: 0px;
  }
  .custom-select1 {
    width: 80px !important;
    font-size: small;
  }
  .custom-select2 {
    width: 100px !important;
    font-size: small;
    padding-left: 0px;
    margin-left: 4px !important;
  }
  p {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
