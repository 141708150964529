<template>
  <div>
    <b-modal
      id="create-appointment"
      ref="modal"
      header-class="headerModal"
      centered
      size="xl"
      :title="$t('CreateAppointment')"

      title-class="red"
      modal-class="dd"
      hide-footer
      @close="onCancel"
    >
      <b-form
        class="fixed-height-modal"
        @reset="onCancel"
      >
        <div
          class="existingClient"
          style="display:flex; flex-direction:row; width: 46%; justify-content: space-between;"
        >
          <b-button
            v-b-toggle.collapse-345
            class="m-1"
            variant="light"
          >
            <b-icon-arrows-expand />
          </b-button>
          <b-form-group
            style="width: 80%; padding-bottom: 10px;"
            :label="$t('SelectExistingClient')"
          >
            <div>
              <div class="black">
                <b-icon-search
                  v-if="searchClients == null"
                  class="search1"
                  style=" position: absolute; left: 5px; top: 10px"
                />
                <b-icon-x-circle
                  v-else-if="clientId != '00000000-0000-0000-0000-000000000000'"
                  class="search1"
                  style="position: absolute; margin-top: -6px; left: 200px; top: 17px; cursor: pointer"
                  @click="searchClients = null ,client= false, resetCustomerData()"
                />
                <input
                  v-model="searchClients"
                  autocomplete="off"
                  type="text"
                  name="search-box"
                  :placeholder="$t('ClientName')"
                  :style="getFirstNames.length > 0 && noSearch ? 'border-radius: 8px 8px 8px 8px' : 'border-radius: 8px 8px 8px 8px; padding-left: 25px;'"
                  @keyup="inputChanged"
                  @keydown.down="onArrowDown"
                  @keydown.up="onArrowUp"
                >
              </div>
              <div
                v-if="getFirstNames.length > 0 && noSearch"
                ref="scrollContainer"
                class="dropdrop"
                :style="getFirstNames.length > 7 ? 'height: 210px' : 'height: auto'"
              >
                <div
                  v-for="(result, index) in getFirstNames"
                  :key="index"
                  ref="options"
                  style="cursor: pointer"
                  class="search-item"
                  :class="{ 'is-active': index === arrowCounter }"
                  @click="searchByOn(result.clientId); noSearch = false"
                >
                  <p style="margin: 0">
                    {{ result.nameToDisplay }}
                  </p>
                </div>
              </div>
            </div>
          </b-form-group>
          <b-button
            v-if="client == false && clientId == '00000000-0000-0000-0000-000000000000'"
            type="button"
            variant="none"
            class="buttonSubmit"
            style="width: 290px; height: 40px; margin-top: 27px;"
            @click="addNewClient"
          >
            {{ $t(`Addclient`) }}
          </b-button>
        </div>

        <section
          v-if="client == true || clientId != '00000000-0000-0000-0000-000000000000'"
          class="custom-modal__wrapper"
          style="border-width: 3px"
        >
          <b-tabs>
            <b-tab
              :title="$t('PersonalInfo')"
              active
            >
              <template #title>
                <strong style="font-weight: 500; color: black">{{ $t("PersonalInfo") }}</strong>
              </template>
              <b-collapse
                id="collapse-345"
                visible
              >
                <div
                  class="hello"
                  style="padding-top: 20px"
                >
                  <b-form-group :label="$t('Salutation')">
                    <vue-select
                      v-model="$v.staticForm.ordererDto.salutation.$model"
                      :options="salutation"
                      required
                      :placeholder="$t('SelectSalutation')"
                      :state="validateState('salutation')"
                      aria-describedby="input-1-live-feedback"
                    />
                    <p
                      v-if="staticForm.ordererDto.salutation == null && isButtonSubmited == true"
                      style="color: red"
                    >
                      {{ $t('This_is_a_required_field') }}
                    </p>
                  </b-form-group>

                  <b-form-group :label="`${$t('FirstName')}`">
                    <b-form-input
                      v-model="$v.staticForm.ordererDto.firstName.$model"
                      :state="validateState('firstName')"
                      placeholder="e.g. Michael"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('LastName')}`">
                    <b-form-input
                      v-model="$v.staticForm.ordererDto.lastName.$model"
                      :state="validateState('lastName')"
                      placeholder="e.g. Goodman"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </div>

                <div class="hello">

                  <b-form-group :label="`${$t('Gender')}`">
                    <vue-select
                      v-model="$v.staticForm.ordererDto.gender.$model"
                      :options="[$t('Male'), $t('Female')]"
                      :placeholder="$t('SelectGender')"
                      required
                      :state="validateState('gender')"
                      aria-describedby="input-5-live-feedback"
                    />
                    <p
                      v-if="staticForm.ordererDto.gender == null && isButtonSubmited == true"
                      style="color: red"
                    >
                      {{ $t('This_is_a_required_field') }}
                    </p>
                  </b-form-group>

                  <b-form-group :label="`${$t('Email')}`">
                    <b-form-input
                      v-model="$v.staticForm.ordererDto.email.$model"
                      :state="validateState('email')"
                      placeholder="e.g. example@digitbusiness.ch"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{
                        $t('Must_be_email_format')
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('PhoneNumber')}`">
                    <vue-phone-number-input
                      v-model="staticForm.ordererDto.telephoneNumber"
                      :default-country-code="staticForm.ordererDto.countryCode"
                      :preferred-countries="['CH', 'DE', 'AL']"
                      :error="isPhoneNumberValid == null ? false : isPhoneNumberValid == false ? true : false"
                      @update="getFormattedNumber"
                    />
                  </b-form-group>
                </div>
              </b-collapse>
            </b-tab>
            <b-tab
              :title="$t('BillingAddress')"
            >
              <template #title>
                <strong style="font-weight: 500; color: black">{{ $t("BillingAddress") }}</strong>
              </template>
              <b-collapse
                id="collapse-345"
                visible
              >
                <div
                  class="hello"
                  style="padding-top: 20px"
                >
                  <b-form-group :label="`${$t('Street')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.street"
                      placeholder="e.g. Rexhep Maja"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('HouseNumber')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.houseNumber"
                      type="number"
                      step="any"
                      min="0"
                      placeholder="e.g. 30"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback"> {{
                      $t('This_is_a_required_field_and_must_contains_numbers')
                    }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('DoorNumber')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.doorNumber"
                      type="number"
                      placeholder="e.g. 50"
                      step="any"
                      min="0"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_contains_numbers')
                    }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="hello">

                  <b-form-group :label="`${$t('PostalCode')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.postalCode"
                      min="0"
                      placeholder="e.g. 10000"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('City')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.city"
                      placeholder="e.g. Prishtinë"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group :label="`${$t('Country')}`">
                    <b-form-input
                      v-model="staticForm.billingAddressDto.country"
                      placeholder="e.g. Kosovë"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </b-collapse>
            </b-tab>
            <b-tab
              :title="$t('ShippingAddress')"
            >
              <template #title>
                <strong style="font-weight: 500; color: black">{{ $t("ShippingAddress") }}</strong>
              </template>
              <b-collapse
                id="collapse-345"
                visible
              >
                <div>
                  <div
                    class="hello"
                    style="padding-top: 20px"
                  >
                    <b-form-group :label="`${$t('Street')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.street"
                        :disabled="pickUpInStore"
                        placeholder="e.g. Rexhep Maja"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback>{{
                        $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                      }}</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group :label="`${$t('HouseNumber')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.houseNumber"
                        :disabled="pickUpInStore"
                        placeholder="e.g. 30"
                        type="number"
                        step="any"
                        min="0"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_contains_numbers')
                      }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group :label="`${$t('DoorNumber')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.doorNumber"
                        :disabled="pickUpInStore"
                        type="number"
                        placeholder="e.g. 50"
                        step="any"
                        min="0"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_contains_numbers')
                      }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <div class="hello">

                    <b-form-group :label="`${$t('PostalCode')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.postalCode"
                        :disabled="pickUpInStore"
                        placeholder="e.g. 10000"
                        min="0"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                      }}</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group :label="`${$t('City')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.city"
                        :disabled="pickUpInStore"
                        placeholder="e.g. Prishtinë"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                      }}</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group :label="`${$t('Country')}`">
                      <b-form-input
                        v-model="staticForm.shippingAddressDto.country"
                        :disabled="pickUpInStore"
                        placeholder="e.g. Kosovë"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">{{
                        $t('This_is_a_required_field_and_must_be_at_least_3_letters')
                      }}</b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <span
                    class="duplicate-material"
                    @click="duplicateBillingAddress(), resetPickUpInStore()"
                  >
                    {{ $t('SameAsBillingAddress') }}
                  </span>
                </div>
                <p>
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="pickUpInStore"
                    name="checkbox-1"
                    :value="true"
                    :unchecked-value="false"
                    @input="resetShippingAddress()"
                  >
                    {{ $t('GetInStore') }}
                  </b-form-checkbox>
                </p>
              </b-collapse>
            </b-tab>
          </b-tabs>
        </section>
        <section
          class="custom-modal__wrapper"
          style="border-width: 3px;margin-top: 15px"
        >
          <div style="display:flex;gap: 15px;flex-wrap: wrap">
            <div>
              <label>{{ $t('From') }}</label>
              <div style="display: flex;flex-direction: column;">
                <date-picker
                  v-model="from "
                  type="datetime"
                  :placeholder="$t('SelectD')"
                />
                <p
                  v-if="from == null && isButtonSubmited"
                  style="color: red;padding-top: 5px;
                    margin-bottom: 0px;"
                >
                  {{ $t('SelectD') }}
                </p>
              </div>
            </div>
            <div
              style="display: flex;gap:10px;"
            >
              <div>
                <label style="padding-left: 25px;;">{{ $t('DurationHour') }}</label>
                <div style="display: flex;flex-direction: row;align-items:center; gap: 8px;">
                  <b-icon-dash-circle
                    v-if="from != null"
                    style="cursor:pointer"
                    @click="substractHour"
                  />
                  <b-form-input
                    v-model.number="durationTimeHour"
                    type="number"
                    :disabled="from == null"
                    :placeholder="$t('Hour')"
                  />
                  <b-icon-plus-circle
                    v-if="from != null"
                    style="cursor:pointer"
                    :disabled="from == null"
                    @click="addHour"
                  />
                </div>
              </div>
              <div>
                <label style="padding-left: 25px;;">{{ $t('DurationMinutes') }}</label>
                <div style="display: flex;flex-direction: row;align-items:center; gap: 8px;">
                  <b-icon-dash-circle
                    v-if="from != null"
                    style="cursor:pointer"
                    @click="substractMinutes"
                  />
                  <b-form-input
                    v-model.number="durationTimeMinutes"
                    type="number"
                    :disabled="from == null"
                    :placeholder="$t('Minutes')"
                  />
                  <b-icon-plus-circle
                    v-if="from != null"
                    style="cursor:pointer"
                    @click="addMinutes"
                  />
                </div>
              </div>
            </div>
            <div>
              <label>{{ $t('To') }}</label>
              <div style="display: flex;flex-direction: column;">
                <date-picker
                  v-model="to"
                  type="datetime"
                  :placeholder="$t('SelectD')"
                />
                <p
                  v-if="to == null && isButtonSubmited"
                  style="color: red;padding-top: 5px;
                    margin-bottom: 0px;"
                >
                  {{ $t('SelectD') }}
                </p>
              </div>
            </div>
            <b-form-group
              v-if="(getLoggedInUser.role !== 'SalesPerson' && getLoggedInUser.role !== 'OnlineSales') || filiale.storeId == null"
              :label="$t('Filiale')"
            >
              <vue-select
                v-model="filiale"
                :options="filteredStores"
                label="storeName"
                :clearable="false"
                :placeholder="$t('SelectFiliale')"
              />
              <p
                v-if="filiale.storeId == null"
                style="color:red;"
              >
                Please select a filiale
              </p>
              <p
                v-else-if="getLoggedInUser.storeId == filiale.storeId"
                style="color:red;"
              >
                {{ getLoggedInUser.firstName }} {{ getLoggedInUser.lastName }} is in this store
              </p>
            </b-form-group>
            <b-form-group
              :label="$t('Users')"
            >
              <vue-select
                v-model="userIds"
                :options="formatedUsers"
                :multiple="true"
                label="fullName"
                :reduce="(e) => e.userId"
                :placeholder="$t('Select')"
              />
            </b-form-group>
          </div>
        </section>

        <div class="buttonsEverywhere">
          <button
            type="button"
            variant="none"
            style="margin-top: 20px;"
            class="buttonSubmit"
            :disabled="loading"
            @click="onSubmit()"
          >
            <div
              v-if="loading"
              class="spinner-border spinner-border-sm"
            />
            {{ $t('Submit') }}
          </button>
          <b-button
            type="reset"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px; margin-top: 20px; background: white; color: black; border-color: white;font-weight: 400;"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import {
  required, minLength, email,
} from 'vuelidate/lib/validators';

import AWS from 'aws-sdk';
import moment from 'moment'
import DatePicker from 'vue2-datepicker';
import { AWSurl, client } from '../domainConfig';
import 'vue2-datepicker/index.css';

export default {
  components: {
    DatePicker,
  },
  mixins: [validationMixin],
  // eslint-disable-next-line vue/require-prop-types
  props: ['nextYearClicked', 'nextMonthClicked', 'nextDayClicked', 'titleOfCalendar'],
  id: {
    type: Object,
  },
  data() {
    return {
      filiale: { storeId: null, storeName: 'No filiale', storeGLN: null },
      oldFiliale: { storeId: null, storeName: 'No filiale', storeGLN: null },
      newUsers: [],
      durationTimeMinutes: 0,
      durationTimeHour: 0,
      appointmentType: 'Measurement',
      userIds: [],
      to: null,
      from: null,
      isButtonSubmited: false,
      clienti: client.clientName,
      client: false,
      loading: false,
      arrowCounter: 0,
      enterPressed: false,
      searchClients: null,
      clientId: '00000000-0000-0000-0000-000000000000',
      noSearch: false,
      isPhoneNumberValid: null,
      config: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
      },
      pickUpInStore: false,
      salutation: ['Mr.', 'Ms.'],
      staticForm: {
        ordererDto: {
          salutation: null,
          firstName: '',
          lastName: '',
          email: '',
          gender: null,
          telephoneNumber: '',
          countryCode: '',
        },
        billingAddressDto: {
          street: '',
          houseNumber: '',
          doorNumber: '',
          postalCode: '',
          city: '',
          country: '',
        },
        shippingAddressDto: {
          street: '',
          houseNumber: '',
          doorNumber: '',
          postalCode: '',
          city: '',
          country: '',
        },
      },
      description: '',

    };
  },

  validations: {
    staticForm: {
      ordererDto: {
        salutation: {
          required,
        },
        firstName: {
          required,
          minLength: minLength(3),
        },
        lastName: {
          required,
          minLength: minLength(3),
        },
        email: {
          email,
        },
        gender: {
          required,
        },
      },
    },
  },
  computed: {
    ...mapGetters(['getClients',
      'getOrderTypes',
      'getMeasermentsById',
      'getFirstNames',
      'getTotalItemsForClients',
      'getClient',
      'getLanguage',
      'getAccountDetails',
      'getAllStoresDropdown',
      'getRegularEmployeesById',
      'getUsersMultipleRoles',
      'getLoggedInUser',
      'getUsersActiveInactive']),
    filteredStores() {
      const filteredStores = this.getAllStoresDropdown.filter((store) => { return store.storeName !== 'all' && store.storeName !== 'unassigned' });

      const allOption = { storeId: null, storeName: 'No filiale', storeGLN: null };
      const unassigned = { storeId: '00000000-0000-0000-0000-000000000000', storeName: 'Unassigned', storeGLN: null };
      return [allOption, unassigned, ...filteredStores];
    },
    formatedUsers() {
      const regularEmployees = this.getRegularEmployeesById.map((item) => {
        return {
          fullName: `${item.name}`,
          userId: item.userId,
        }
      });
      const uniqueNewUsers = this.newUsers.filter((newItem) => { return !regularEmployees.some((existingItem) => { return existingItem.userId === newItem.userId }) });
      return [...regularEmployees, ...uniqueNewUsers];
    },
  },
  watch: {
    'getLoggedInUser.storeId': function () {
      this.updateFiliale();
    },

    from(value) {
      if (value == null) {
        this.durationTimeHour = 0;
        this.durationTimeMinutes = 0;
        this.to = null;
      } else {
        this.calculateDuration();
      }
    },
    to(value) {
      if (value) {
        this.calculateDuration();
      }
    },
    durationTimeHour(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value < 0) {
          this.durationTimeHour = 0;
        } else if (value === '') {
          this.durationTimeHour = 0;
        } else {
          const fullHours = Math.floor(value);
          const fractionalPart = value - fullHours;

          this.durationTimeHour = fullHours;
          this.durationTimeMinutes += Math.round(fractionalPart * 60);
        }
        this.calculateMontageEnd();
      }, 0);
    },
    durationTimeMinutes(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value < 0) {
          this.durationTimeMinutes = 0;
        }
        if (value == '') {
          this.durationTimeMinutes = 0;
        }
        if (value >= 60) {
          const totalHours = Math.floor(value / 60);
          this.durationTimeHour += totalHours;

          const fractionalPart = (value / 60) - totalHours;
          const remainingMinutes = Math.round(fractionalPart * 60);

          this.durationTimeMinutes = remainingMinutes;
        }
        if (value < 0 && this.durationTimeHour > 0) {
          this.durationTimeHour -= 1;
          this.durationTimeMinutes = 45;
        }
        this.calculateMontageEnd();
      }, 0);
    },
    pickUpInStore(value) {
      if (value == true) {
        this.staticForm.shippingAddressDto.street = '';
        this.staticForm.shippingAddressDto.houseNumber = '';
        this.staticForm.shippingAddressDto.doorNumber = '';
        this.staticForm.shippingAddressDto.postalCode = '';
        this.staticForm.shippingAddressDto.city = '';
        this.staticForm.shippingAddressDto.country = '';
      }
    },
    searchClients(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == null) {
          this.clearCustomMeasurements();
          this.removeLists();
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.firstNames(value);
          this.noSearch = true
        }
      }, 500);
    },
    filiale(value) {
      this.loadRegularEmployeesId({ storeId: value.storeId })
      this.userIds = []
    },
  },
  created() {
    AWS.config.update({
      accessKeyId: AWSurl.accessKeyId,
      secretAccessKey: AWSurl.secretAccessKey,
      region: AWSurl.region,
    });

    this.s3 = new AWS.S3();
  },
  mounted() {
    this.loadAllStoresByDropdown({
      amountOfRecords: 100,
    })
      .then(() => {
        this.updateFiliale()
      })
      .catch((error) => {
        console.error('Error in loading stores or updating Filiale:', error)
      })
  },

  methods: {
    ...mapActions([
      'usersByMultipleRoles', 'loadRegularEmployeesId', 'loadAllStoresByDropdown', 'loadMontageAppointments', 'createAppointment', 'resetCustomOrders', 'clearCustomMeasurements', 'loadOrderTypes', 'editClient', 'getClientById', 'getClientsPagination', 'getClientById', 'firstNames', 'resetSearchClients', 'loadMeasermentsById',
    ]),
    updateFiliale() {
      const storeId = this.getLoggedInUser.storeId?.toUpperCase();

      if (storeId) {
        const store = this.filteredStores.find((stores) => { return stores.storeId === storeId });
        if (store) {
          this.filiale = {
            storeId: store.storeId,
            storeName: store.storeName,
            storeGLN: store.storeGLN,
          };
          this.oldFiliale = {
            storeId: store.storeId,
            storeName: store.storeName,
            storeGLN: store.storeGLN,
          };
        } else {
          this.filiale = { storeId: null, storeName: 'No Filiale', storeGLN: null };
          this.oldFiliale = { storeId: null, storeName: 'No Filiale', storeGLN: null };
        }
      } else {
        this.filiale = { storeId: null, storeName: 'No Filiale', storeGLN: null };
        this.oldFiliale = { storeId: null, storeName: 'No Filiale', storeGLN: null };
      }
    },

    calculateDuration() {
      if (this.from && this.to) {
        const fromDate = new Date(this.from);
        const toDate = new Date(this.to);

        const diff = toDate - fromDate;

        const durationHours = Math.floor(diff / (1000 * 60 * 60));
        const durationMinutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

        this.durationTimeHour = durationHours;
        this.durationTimeMinutes = durationMinutes;
      }
    },

    calculateMontageEnd() {
      if (this.from && this.durationTimeHour != null && this.durationTimeMinutes != null) {
        const startDate = new Date(this.from);

        startDate.setHours(startDate.getHours() + this.durationTimeHour);
        startDate.setMinutes(startDate.getMinutes() + this.durationTimeMinutes);

        startDate.setSeconds(0);

        this.to = startDate;

        console.log('Montage End:', this.to);
      }
    },

    addHour() {
      this.durationTimeHour++;
      this.calculateMontageEnd();
    },

    substractHour() {
      this.durationTimeHour--;
      this.calculateMontageEnd();
    },

    addMinutes() {
      this.durationTimeMinutes += 15;
      this.calculateMontageEnd();
    },

    substractMinutes() {
      this.durationTimeMinutes -= 15;
      this.calculateMontageEnd();
    },
    addNewClient() {
      this.client = true;
    },
    getFormattedNumber(n) {
      if (n.isValid == true) {
        this.isPhoneNumberValid = true
        this.staticForm.ordererDto.telephoneNumber = n.nationalNumber
        this.staticForm.ordererDto.countryCode = n.countryCode
      } else {
        this.staticForm.ordererDto.countryCode = ''
        this.isPhoneNumberValid = false
        return;
      }
      console.log()
    },
    async searchByOn(value) {
      this.noSearch = false
      const clientData = await this.getClientById({
        clientId: value,
        successCallback: () => {
        },
      })
      this.searchClients = `${clientData.firstName} ${clientData.lastName}`
      this.clientId = value
      this.staticForm.ordererDto.salutation = clientData.salutation
      this.staticForm.ordererDto.firstName = clientData.firstName
      this.staticForm.ordererDto.lastName = clientData.lastName
      this.staticForm.ordererDto.gender = clientData.gender
      this.staticForm.ordererDto.email = clientData.email
      this.staticForm.ordererDto.telephoneNumber = clientData.telephoneNumber
      this.staticForm.ordererDto.countryCode = clientData.countryCode
      this.staticForm.ordererDto.taxRate = clientData.taxRate
      this.staticForm.billingAddressDto.street = clientData.billingAddressDto.street
      this.staticForm.billingAddressDto.houseNumber = clientData.billingAddressDto.houseNumber
      this.staticForm.billingAddressDto.doorNumber = clientData.billingAddressDto.doorNumber
      this.staticForm.billingAddressDto.postalCode = clientData.billingAddressDto.postalCode
      this.staticForm.billingAddressDto.city = clientData.billingAddressDto.city
      this.staticForm.billingAddressDto.country = clientData.billingAddressDto.country
      this.staticForm.shippingAddressDto.street = clientData.shippingAddressDto.street
      this.staticForm.shippingAddressDto.houseNumber = clientData.shippingAddressDto.houseNumber
      this.staticForm.shippingAddressDto.doorNumber = clientData.shippingAddressDto.doorNumber
      this.staticForm.shippingAddressDto.postalCode = clientData.shippingAddressDto.postalCode
      this.staticForm.shippingAddressDto.city = clientData.shippingAddressDto.city
      this.staticForm.shippingAddressDto.country = clientData.shippingAddressDto.country
    },
    removeLists() {
      this.noSearch = false;
      this.resetSearchClients();
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFirstNames.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },

    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredActiveUsers = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getFirstNames[this.arrowCounter].clientId)
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.staticForm.ordererDto[name];
      return $dirty ? !$error : null;
    },
    resetPickUpInStore() {
      this.pickUpInStore = false
    },
    duplicateBillingAddress() {
      this.staticForm.shippingAddressDto.street = this.staticForm.billingAddressDto.street;
      this.staticForm.shippingAddressDto.houseNumber = this.staticForm.billingAddressDto.houseNumber;
      this.staticForm.shippingAddressDto.doorNumber = this.staticForm.billingAddressDto.doorNumber;
      this.staticForm.shippingAddressDto.postalCode = this.staticForm.billingAddressDto.postalCode;
      this.staticForm.shippingAddressDto.city = this.staticForm.billingAddressDto.city;
      this.staticForm.shippingAddressDto.country = this.staticForm.billingAddressDto.country;
    },

    async onSubmit() {
      this.isButtonSubmited = true
      this.$v.staticForm.ordererDto.$touch();
      if (this.$v.staticForm.ordererDto.$anyError) {
        return;
      }
      if (this.from == null || this.to == null) {
        return;
      }
      const objekti = {
        ordererDto: this.staticForm.ordererDto,
        billingAddressDto: this.staticForm.billingAddressDto,
        shippingAddressDto: this.staticForm.shippingAddressDto,
        clientId: this.clientId,
        from: this.from,
        to: this.to,
        appointmentType: this.appointmentType,
        userIds: this.userIds,
        storeId: this.filiale.storeId,
      }
      this.createAppointment({
        object: objekti,
        successCallback: () => {
          if (this.nextDayClicked) {
            this.loadMontageAppointments({
              start: moment(this.titleOfCalendar).startOf('day').format('YYYY-MM-DD'),
              end: moment(this.titleOfCalendar).add(7, 'days').format('YYYY-MM-DD'),
            })
          } else if (this.nextMonthClicked) {
            this.loadMontageAppointments({
              start: moment(this.titleOfCalendar).startOf('month').format('YYYY-MM-DD'),
              end: moment(this.titleOfCalendar).endOf('month').format('YYYY-MM-DD'),
            })
          } else if (this.nextYearClicked) {
            this.loadMontageAppointments({
              start: moment(this.titleOfCalendar).startOf('year').format('YYYY-MM-DD'),
              end: moment(this.titleOfCalendar).add(1, 'year').format('YYYY-MM-DD'),
            })
          }
        },
      })
      this.$refs.modal.hide();
      this.resetForm()
      this.resetCustomerData()
    },
    onCancel() {
      this.$refs.modal.hide();
      this.resetCustomerData()
      this.resetForm()
    },
    resetShippingAddress() {
      this.$v.staticForm.shippingAddressDto.$reset()
    },
    resetForm() {
      this.filiale = this.oldFiliale
      this.isButtonSubmited = false
      this.createHouseItemRequestDtos = []
      this.orderNumber = '';
      this.client = false;
      this.searchClients = null
      this.clientId = '00000000-0000-0000-0000-000000000000'
      this.resetCustomerData();
      this.isButtonSubmited = false;
      this.description = '';
      this.clearCustomMeasurements()
      this.from = null
      this.to = null
      this.durationTimeHour = 0
      this.durationTimeMinutes = 0
      this.userIds = []
    },
    resetCustomerData() {
      this.staticForm.ordererDto.salutation = null;
      this.staticForm.ordererDto.firstName = '';
      this.staticForm.ordererDto.lastName = '';
      this.staticForm.ordererDto.email = '';
      this.staticForm.ordererDto.gender = null;
      this.staticForm.ordererDto.telephoneNumber = '';

      this.staticForm.billingAddressDto.street = '';
      this.staticForm.billingAddressDto.houseNumber = '';
      this.staticForm.billingAddressDto.doorNumber = '';
      this.staticForm.billingAddressDto.postalCode = '';
      this.staticForm.billingAddressDto.city = '';
      this.staticForm.billingAddressDto.country = '';

      this.staticForm.shippingAddressDto.street = '';
      this.staticForm.shippingAddressDto.houseNumber = '';
      this.staticForm.shippingAddressDto.doorNumber = '';
      this.staticForm.shippingAddressDto.postalCode = '';
      this.staticForm.shippingAddressDto.city = '';
      this.staticForm.shippingAddressDto.country = '';

      this.pickUpInStore = false;
      this.isPhoneNumberValid = null;
      this.searchClients = null
      this.clientId = '00000000-0000-0000-0000-000000000000'
    },

  },
};
</script>

<style lang="scss" scoped>

.modal-header{
  font-size: 25px !important;
}

.fixed-height-modal[data-v-094d39fb] {
    height: 100%;
    overflow-y: auto;
    padding-right: 1rem;
}
textarea.form-control {
    min-height: calc(1.5em + 0.75rem + 2px);
    width: 1090px;
}
fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
    width: 49%;
}
.price-container {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.hello{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 1%
}
.price-text {
  margin-left: 0px;
  font-size: 18px;
  // font-weight: bold;
  color: black;
  border-radius: 10px;
}
.search1 {
    padding: 0px;
  }
.black {
  position: relative;
}
.black input {
  height: 38px;
}
.dropdrop::-webkit-scrollbar {
display: none;

}
.dropdrop {
display: inline-block;
overflow: auto;
position: absolute;
background: white;
width: 227px;
z-index: 2;
box-shadow: none;
}
.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}
.search-item:last-child {
  border-bottom: none;
}
.search-item:hover {
  background: #82868c;
  color: white;
}
.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}
.is-active {
  background-color: #dedede;
}
form {
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}


.headline {
  padding: 10px 10px;
  background-color: white;
  color: black;
  font-weight: bold;
  border-radius: 8px;
}

.custom-modal__wrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px 0px;

  >div {
    flex: 1;
    margin: 0px 15px;
  }
}

.flex-form {
  display: flex;
  flex-direction: column;
}

.fixed-height-modal {
  height: 100%;
  overflow-y: auto;
  padding-right: 1rem;
}


.inside-actions {
  margin-left: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  box-shadow: none;
  padding: 2px 4px;
  border-radius: 7px;
}

.inside-actions:hover {
  background-color: #242F6E;
  color: #fff;
}

.f {
  border-color: red !important;
}


.btn-con {
  display: flex;

  button {
    border: none;
    background: #FF274F;
    color: $digit-white;
    border-radius: 0.25rem;
    padding: 3px 12px;
  }
}


@media only screen and (max-width: 1200px) {
  textarea.form-control[data-v-094d39fb] {
    min-height: calc(1.5em + 0.75rem + 2px);
    width: 745px;
  }
  .existingClient{
    width: 65% !important;
  }

  .btn-con {
    display: flex;

    button {
      border: none;
      background-color: #6c757d;
      ;
      color: $digit-white;
      border-radius: 0.25rem;
      padding: 3px 12px;
    }
  }


input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -3px;
  left: -1px;
  position: relative;
  background-color: $base-color ;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
}

</style>
